import React from "react";
import styled, {css} from "styled-components";
import {FieldArray} from "react-final-form-arrays";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import {Radios, TextField} from "mui-rff";
import {PercentageFormatOnInput} from "../../../assets/helpers/finalFormHelpers";

const Error = styled.p`
  color: #ea4335;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;

const StatusRadios = styled(Radios)`
  ${props => props.data[0]?.label === "Approved" && css`&.Mui-checked { color: Green }`}
  ${props => props.data[0]?.label === "Rejected" && css`&.Mui-checked { color: Red }`}
`;

const MultipleProjectResponseFieldAdapter = ({ errors, input: { name }, required, touched, itemOneName, itemTwoName, isProjectAdmin, userEmail, values, ...rest }) => {
    const reducer = (previousValue, currentValue) => {
        if (currentValue && currentValue.status !== "Approved")
            return parseFloat(previousValue) + 1;
        else
            return parseFloat(previousValue)
    };

    return (
        <>
            <FieldArray name={name}>
            {({ fields }) =>
                fields.map((name, index) => {
                    if (isProjectAdmin || userEmail === fields.value[index].project.projectManager.userEmail)
                        return (<Grid key={name} container spacing={2} alignItems="center">
                            <Grid item xs={3} style={{textAlign: "center"}}>
                                <FormLabel style={{fontSize: 14}}>{fields.value[index].project.projectNumber}</FormLabel>
                            </Grid>
                            <Grid item xs={9}>
                                <StatusRadios
                                    name={`${name}.${itemOneName}`}
                                    formControlProps={{margin: 'none'}}
                                    radioGroupProps={{row: true}}
                                    data={[
                                        {label: "Approved", value: "Approved"},
                                    ]}
                                />
                                <StatusRadios
                                    name={`${name}.${itemOneName}`}
                                    formControlProps={{margin: 'none'}}
                                    radioGroupProps={{row: true}}
                                    data={[
                                        {label: "Rejected", value: "Rejected"},
                                    ]}
                                />
                                {touched[`${name}.${itemOneName}`] && errors[`${name}.${itemOneName}`] &&
                                <Error>{errors[`${name}.${itemOneName}`]}</Error>}
                            </Grid>
                            <Grid item xs={3} />
                            <Grid item xs={9}>
                                <TextField
                                    label="Comment"
                                    multiline
                                    margin="none"
                                    name={`${name}.${itemTwoName}`}
                                    error={touched[`${name}.${itemTwoName}`] && !!errors[`${name}.${itemTwoName}`]}
                                />
                                {touched[`${name}.${itemTwoName}`] && errors[`${name}.${itemTwoName}`] &&
                                <Error>{errors[`${name}.${itemTwoName}`]}</Error>}
                            </Grid>
                            <Grid item xs={3} />
                            <Grid item xs={6}>
                                <TextField
                                    label="Project Manager"
                                    margin="none"
                                    name={`${name}.project.projectManager.userFullName`}
                                    value={fields.value[index].project?.projectManager?.userFullName}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="% Allocation"
                                    margin="none"
                                    name={`${name}.projectCostPercentage`}
                                    value={fields.value[index].projectCostPercentage}
                                    disabled
                                    InputProps={{
                                        inputComponent: PercentageFormatOnInput,
                                    }}
                                />
                            </Grid>
                        </Grid>)
                    else
                        return (<Grid key={name} container spacing={2} alignItems="center">
                            <Grid item xs={3} style={{textAlign: "center"}}>
                                <FormLabel style={{fontSize: 14}}>{fields.value[index].project.projectNumber}</FormLabel>
                            </Grid>
                            <Grid item xs={9}>
                                <StatusRadios
                                    name={`${name}.${itemOneName}`}
                                    formControlProps={{margin: 'none'}}
                                    radioGroupProps={{row: true}}
                                    disabled
                                    data={[
                                        {label: "Approved", value: "Approved"},
                                    ]}
                                />
                                <StatusRadios
                                    name={`${name}.${itemOneName}`}
                                    formControlProps={{margin: 'none'}}
                                    radioGroupProps={{row: true}}
                                    disabled
                                    data={[
                                        {label: "Rejected", value: "Rejected"},
                                    ]}
                                />
                                {touched[`${name}.${itemOneName}`] && errors[`${name}.${itemOneName}`] &&
                                <Error>{errors[`${name}.${itemOneName}`]}</Error>}
                            </Grid>
                            <Grid item xs={3} />
                            <Grid item xs={9}>
                                <TextField
                                    label="Comment"
                                    multiline
                                    margin="none"
                                    disabled
                                    name={`${name}.${itemTwoName}`}
                                    error={touched[`${name}.${itemTwoName}`] && !!errors[`${name}.${itemTwoName}`]}
                                />
                                {touched[`${name}.${itemTwoName}`] && errors[`${name}.${itemTwoName}`] &&
                                <Error>{errors[`${name}.${itemTwoName}`]}</Error>}
                            </Grid>
                            <Grid item xs={3} />
                            <Grid item xs={6}>
                                <TextField
                                    label="Project Manager"
                                    margin="none"
                                    name={`${name}.project.projectManager.userFullName`}
                                    value={fields.value[index].project?.projectManager?.userFullName}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="% Allocation"
                                    margin="none"
                                    name={`${name}.projectCostPercentage`}
                                    value={fields.value[index].projectCostPercentage}
                                    disabled
                                    InputProps={{
                                        inputComponent: PercentageFormatOnInput,
                                    }}
                                />
                            </Grid>
                        </Grid>)
                })
            }
        </FieldArray>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={9} style={{textAlign: "end"}}>
                <FormLabel style={{fontSize: 12}}>Amount of projects awaiting approval: </FormLabel>
            </Grid>
            <Grid item xs={3}>
                <TextField
                    name="totalActionNeeded"
                    margin="normal"
                    disabled={true}
                    value={values && values[name] && values[name].length ? values[name].reduce(reducer, 0) : "0"}>
                </TextField>
            </Grid>
        </Grid>
        {touched[name] && errors[name] && <Error>{errors[name]}</Error>}
    </>
    )
}

export default MultipleProjectResponseFieldAdapter;