import React, {useEffect, useState} from "react";
import MaterialTable from "material-table";
import styled, {css} from "styled-components";
import {Autorenew, Clear, Done, DoneAll, Info, Warning} from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
// import {getPermissionsByRole} from "../../store/permissions/actions";
import FilterAutocomplete from "../../components/Inputs/Filter/FilterAutocomplete";
import Button from "@material-ui/core/Button";
import {getFilteredData} from "../../assets/helpers/globals";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import {useInterval} from "../../hooks/useInterval";
import {getAllAuditLogs} from "../../store/audit/actions";
import * as loadingTypes from "../../store/loading/types";
import DetailsDialog from "./DetailsDialog";

const P = styled.p`
  color: #f57c00;
  border: 1px solid #ff9800;
  border-radius: 16px;
  height: 24px;
  padding-left: 4px;
  padding-right: 8px;
  white-space: nowrap;
  display: flex;
  max-width: fit-content;
  ${props => (props.status === "In Progress" || props.status === "Quote Review In Progress (Draft)" || props.status === "PO Review In Progress (Draft)")  && css`
    color: #1976d2;
    border: 1px solid #2196f3;
  `}
  ${props => (props.status === "Invoice Approved" || props.status === "Approved" || props.status === "Resolved" || props.status === "Quote Reviewed" || props.status === "PO Created" || props.status === "PO Reviewed" || props.status === "PO Submitted to Xero" || props.status === "Registered") && css`
    color: #388e3c;
    border: 1px solid #4caf50;
  `}
  ${props => (props.status === "Rejected" || props.status === "Invoice Rejected" || props.status === "Unresolved" || props.status === "Deleted") && css`
    color: #d32f2f;
    border: 1px solid #f44336;
  `}
`;

const ApprovedAllIcon = styled(DoneAll)`
	display: none;
	${props => (props.status === "PO Created" || props.status === "PO Submitted to Xero") && css`
		margin-top: -1px;
		margin-right: 2px;
		display: flex;
		color: #388e3c;
	`}
`;

const ApprovedIcon = styled(Done)`
  display: none;
  ${props => (props.status === "Invoice Approved" || props.status === "Approved" || props.status === "Resolved" || props.status === "Quote Reviewed" || props.status === "PO Reviewed" || props.status === "Registered") && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #388e3c;
  `}
`;

const RejectedIcon = styled(Warning)`
  display: none;
  ${props => (props.status === "Rejected" || props.status === "Invoice Rejected" || props.status === "Unresolved" || props.status === "Deleted") && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #d32f2f;
  `}
`;

const PendingIcon = styled(Autorenew)`
  display: none;
  ${props => (props.status === "Pending" || props.status === "Invoice Attached" || props.status === "Awaiting Approval" || props.status === "Awaiting 2nd Approval" || props.status === "Queued" || props.status === "New Quote Requested" || props.status === "PO Pending" || props.status === "Quote Awaiting Review") && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #f57c00;
  `}
`;

const InProgressIcon = styled(Info)`
  display: none;
  ${props => (props.status === "In Progress" || props.status === "Quote Review In Progress (Draft)" || props.status === "PO Review In Progress (Draft)") && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #1976d2;
  `}
`;

const FilterPaper = styled(Paper)`
  margin-bottom: 18px;
  margin-top: 14px;
`;

const FilterGrid = styled(Grid)`
  ${props => props.container && css`
    margin-left: 10px; 
    width: 98%;
  `}
  ${props => props.item && css`
    align-self: center
  `}
`;

const AuditLog = ({
                    allAuditLogs,
                    // getUserRoles,
                    getAllAuditLogs,
                    loading
                       }) => {
    const initialFilterState = {action: null, statusChange: null, timestamp: null, typeName: null, subTypeName: null, userFullName: null}
    const [filter, setFilter] = useState(initialFilterState);
    const [auditDetails, setAuditDetails] = useState({open: false, data: {}});
    // const [roles, setRoles] = useState({
    //     is_audit_log_viewer: false,
    // })

    const actions = [
        {
            icon: "visibility",
            onClick: async (_event, audit) => {
                setAuditDetails({open: true, data: audit});
            },
        },
        {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: debounce(getAllAuditLogs, 500, {
                'leading': false,
                'trailing': true
            }),
        }
    ];

    const filterData = (data, query) => {
        return data.filter((item) => {
            for (let key in query) {
                switch (key) {
                    case "typeName":
                        if (item.transactionSubType.transactionType === null || item.transactionSubType.transactionType[key] === undefined || query[key] !== item.transactionSubType.transactionType[key]) {
                            return false;
                        }
                        break;
                    case "subTypeName":
                        if (item.transactionSubType === null || item.transactionSubType[key] === undefined || query[key] !== item.transactionSubType[key]) {
                            return false;
                        }
                        break;
                    case "userFullName":
                        if (item.user === null || item.user[key] === undefined || !query[key].includes(item.user[key])) {
                            return false;
                        }
                        break;
                    default:
                        if (item[key] === undefined || !query[key].includes(item[key])) {
                            return false;
                        }
                        break;
                }
            }
            return true;
        });
    };

    useInterval(() => {
        // if (roles.is_audit_log_viewer)
        getAllAuditLogs();
    }, 300000);

    useEffect(() => {
        // (async function() {
        //     const res = await getUserRoles("audit","");
        //     setRoles({
        //         is_audit_log_viewer: res.some(name => name === "audit_log_viewer"),
        //     });
        // })();

        // if (roles.is_audit_log_viewer)
            getAllAuditLogs();
    }, [getAllAuditLogs, /*roles.is_audit_log_viewer, getUserRoles*/])

    return (
        <>
            <FilterPaper>
                <FilterGrid container spacing={2}>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, typeName: newValue})}
                            value={filter.typeName}
                            options={allAuditLogs.map(auditLog => auditLog.transactionSubType?.transactionType?.typeName)}
                            placeholder="Type"
                            noOptionsText="No Types"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, subTypeName: newValue})}
                            value={filter.subTypeName}
                            options={allAuditLogs.map(auditLog => auditLog.transactionSubType?.subTypeName)}
                            placeholder="Subtype"
                            noOptionsText="No Subtypes"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, statusChange: newValue})}
                            value={filter.statusChange}
                            options={allAuditLogs.map(auditLog => auditLog.statusChange)}
                            placeholder="Status Change"
                            noOptionsText="No Status Change"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, timestamp: newValue})}
                            value={filter.timestamp}
                            options={allAuditLogs.map(auditLog => auditLog.timestamp)}
                            placeholder="Timestamp"
                            noOptionsText="No Timestamps"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, action: newValue})}
                            value={filter.action}
                            options={allAuditLogs.map(auditLog => auditLog.action)}
                            placeholder="Action"
                            noOptionsText="No Action"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, userFullName: newValue})}
                            value={filter.userFullName}
                            options={allAuditLogs.map(auditLog => auditLog.user?.userFullName)}
                            placeholder="User"
                            noOptionsText="No Users"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <Button
                            color="secondary"
                            size="small"
                            startIcon={<Clear/>}
                            disabled={isEqual(filter, initialFilterState)}
                            onClick={() => setFilter(initialFilterState)}>
                            Clear All
                        </Button>
                    </FilterGrid>
                </FilterGrid>
            </FilterPaper>

            <MaterialTable
                columns={[
                    {
                        title: "Id",
                        field: "uniqueId",
                        type: "numeric",
                        align: "left"
                    },
                    {
                        title: `Item Id`,
                        field: "transactionId",
                        type: "numeric",
                        align: "left"
                    },
                    {
                        title: "Type",
                        field: "transactionSubType.transactionType.typeName",
                        type: "string"
                    },
                    {
                        title: "Subtype",
                        field: "transactionSubType.subTypeName",
                        type: "string"
                    },
                    {
                        title: "Timestamp",
                        field: "timestamp",
                        type: "datetime",
                        defaultSort: "desc",
                        customSort: (a, b) => {
                            let d1 = new Date(a.timestamp);
                            let d2 = new Date(b.timestamp);

                            return d1.getTime() - d2.getTime();
                        }
                    },
                    {
                        title: "User",
                        field: "user.userFullName",
                        type: "string"
                    },
                    {
                        title: "Action Performed",
                        field: "action",
                        type: "string"
                    },
                    {
                        title: "Status Change",
                        field: "statusChange",
                        render: data => {
                            if (data.statusChange)
                                return <P status={data.statusChange}>
                                    <ApprovedIcon status={data.statusChange}/>
                                    <RejectedIcon status={data.statusChange}/>
                                    <PendingIcon status={data.statusChange}/>
                                    <ApprovedAllIcon status={data.statusChange}/>
                                    <InProgressIcon status={data.statusChange}/>
                                    {data.statusChange}</P>
                        },
                        type: "string"
                    },
                ]}
                actions={actions}
                isLoading={loading.type === loadingTypes.auditGetAllAuditLogs
                    // || loading.type === loadingTypes.permissionsGetPermissionsByRole
                }
                data={isEqual(filter, initialFilterState) ? allAuditLogs : getFilteredData(filter, allAuditLogs, [], filterData)}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    searchFieldAlignment: "left",
                    showTitle: false,
                    sorting: true,
                    isLoading: true,
                    search: true,
                    filtering: false,
                    // thirdSortClick: false,
                }}
            />
            <DetailsDialog
                details={auditDetails.data}
                onClose={() => setAuditDetails({open: false, data: {}})}
                open={auditDetails.open}
            />
        </>
    );
}

const mapStateToProps = state => ({
    allAuditLogs: state.auditReducer.allAuditLogs,
    loading: state.loadingReducer.loading,
});

const mapDispatchToProps = dispatch => ({
    getAllAuditLogs: () => dispatch(getAllAuditLogs()),
    // getUserRoles: (roleType, roleSubType) => dispatch(getPermissionsByRole(roleType, roleSubType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditLog);
