import React, { Component } from 'react';

import classes from './SidebarNavigationItems.module.css';
import List from '@material-ui/core/List';
import { withRouter } from 'react-router-dom';
import allRoutes from '../../../../assets/helpers/routes';
import SidebarNavigationItem from './SidebarNavigationItem/SidebarNavigationItem';

class SidebarNavigationItems extends Component {

    state = {
        loaded : false
    };

    toggleCollapse = (stateName) => {
        this.setState((prevState) => ({
            [stateName + "_collapse"]: !prevState[stateName + "_collapse"]
        }));
    };

    generateState = (route, stateName) => {
        const collapsed = this.props.location.pathname.includes(route.path);
        this.setState({
            [stateName + "_collapse"]: collapsed,
            [stateName + "_collapse_state"] : true
        });
    }

    isStateLoaded = () => {
        let loaded = true;

        allRoutes.forEach(layoutRoutes => {
            layoutRoutes.routes.forEach(route => {
                if((route.areas.findIndex(item => item === "sidebar") === -1) && route.collapse && this.state[route.id + "_collapse_state"] !== true){
                    loaded = false;

                    route.views.forEach(view => {
                        if(!(route.areas.findIndex(item => item === "sidebar") === -1) && view.collapse && this.state["sub" + view.id + "_collapse_state"] !== true){
                            loaded = false;
                        }
                    });
                }
                
            })
        });

        return loaded;
    }

    componentDidMount(){
        allRoutes.forEach(layoutRoutes => {
            layoutRoutes.routes.forEach(route => {
                if(!(route.areas.findIndex(item => item === "sidebar") === -1) && route.collapse){
                    this.generateState(route, route.collapseSub + route.id);

                    route.views.forEach(view => {
                        if(!(route.areas.findIndex(item => item === "sidebar") === -1) && view.collapse){
                            this.generateState(view, view.collapseSub + view.id);
                        }
                    });
                }
            })
        });
    }

    render() {
        return (
            this.isStateLoaded() && <List component="nav" className={classes.List}>

                {allRoutes.map((layoutRoutes) => {
                    return layoutRoutes.routes.map((route) => {
                        if(route.areas.findIndex(item => item === "sidebar") === -1){
                            return null;
                        }
                        else {
                           return <SidebarNavigationItem
                                    key={route.id} 
                                    route={route}
                                    toggleCollapse={(stateName) => this.toggleCollapse(stateName)}
                                    {...this.state} />;
                        }
                    })
        
                })
                }
            </List>
        );
    }
};

export default withRouter(SidebarNavigationItems);