import * as actionTypes from './actions';

const initialState = { successMessage: null, isOpen: false };

const setSuccessMessage = (action) => ({ successMessage: action.success, isOpen: true });
const hideSuccessMessage = () => initialState;

export default (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.SET_SUCCESS:
            return setSuccessMessage(action);
        case actionTypes.HIDE_SUCCESS:
            return hideSuccessMessage();
        default:
            return state;
    }
};
