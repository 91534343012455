import axiosInstance from '../../assets/helpers/axiosInstance';
import { endLoading, startLoading } from "../loading/actions";
import * as loadingTypes from "../loading/types";
import {setError} from "../error/actions";
import * as errorTypes from "../error/types";

export const SET_TIME_ZONES = "timeZones/SET_TIME_ZONES";

const setTimeZones = timeZones => ({
    type: SET_TIME_ZONES,
    timeZones,
});

export const getTimeZones = () => async dispatch => {
    dispatch(startLoading(loadingTypes.timeZonesGetTimeZones));
    try {
        const res = await axiosInstance.get(`/timezone/getAll`);
        dispatch(setTimeZones(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.timeZonesGetTimeZones, `${err.message ? err.message + " at " + errorTypes.timeZonesGetTimeZones : "Something went wrong at " + errorTypes.timeZonesGetTimeZones }`));
    } finally {
        dispatch(endLoading());
    }
};