import axiosInstance from '../../assets/helpers/axiosInstance';
import {endLoading, startLoading} from "../loading/actions";
import * as loadingTypes from "../loading/types";
import {setError} from "../error/actions";
import * as errorTypes from "../error/types";
import {setSuccessMessage} from "../success/actions";

export const SET_ALL_PROJECTS = "projects/SET_ALL_PROJECTS";
export const SET_ALL_PROJECTS_STATUSES = "projects/SET_ALL_PROJECTS_STATUSES";

const setAllProjects = projects => ({
    type: SET_ALL_PROJECTS,
    projects,
});

const setAllProjectsStatuses = projectsStatuses => ({
    type: SET_ALL_PROJECTS_STATUSES,
    projectsStatuses,
});

export const getAllProjects = (type) => async dispatch => {
    dispatch(startLoading(loadingTypes.projectsGetAllProjects));
    try {
        const res = await axiosInstance.get(`/projects/getAllProjects?type=${type}`);
        dispatch(setAllProjects(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.projectsGetAllProjects, `${err.message ? err.message + " at " + errorTypes.projectsGetAllProjects : "Something went wrong at " + errorTypes.projectsGetAllProjects }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getAllProjectsStatuses = () => async dispatch => {
    dispatch(startLoading(loadingTypes.projectsGetAllProjectsStatuses));
    try {
        const res = await axiosInstance.get("/projectsStatus/getAllProjectStatus");
        dispatch(setAllProjectsStatuses(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.projectsGetAllProjectsStatuses, `${err.message ? err.message + " at " + errorTypes.projectsGetAllProjectsStatuses : "Something went wrong at " + errorTypes.projectsGetAllProjectsStatuses }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getAllProjectsStatusesById = (id) => async dispatch => {
    dispatch(startLoading(loadingTypes.projectsGetAllProjectsStatusesById));
    try {
        const res = await axiosInstance.get(`/projectsStatus/getAllProjectStatus/${id}`);
        return res.data;
    } catch (err) {
        dispatch(setError(errorTypes.projectsGetAllProjectsStatusesById, `${err.message ? err.message + " at " + errorTypes.projectsGetAllProjectsStatusesById : "Something went wrong at " + errorTypes.projectsGetAllProjectsStatusesById }`));
    } finally {
        dispatch(endLoading());
    }
};

export const addProject = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.projectsAddProject));
    try {
        await axiosInstance.post(`/projects/addProject?id=${id}`, data)
        dispatch(setSuccessMessage("Success! Project added."));
    } catch (err) {
        dispatch(setError(errorTypes.projectsAddProject, `${err.message ? err.message + " at " + errorTypes.projectsAddProject : "Something went wrong at " + errorTypes.projectsAddProject }`));
    } finally {
        dispatch(endLoading());
    }
};

export const updateProject = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.projectsUpdateProject));
    try {
        await axiosInstance.post(`/projects/updateProject?id=${id}`, data);
        dispatch(setSuccessMessage("Success! Project updated."));
    } catch (err) {
        dispatch(setError(errorTypes.projectsUpdateProject, `${err.message ? err.message + " at " + errorTypes.projectsUpdateProject : "Something went wrong at " + errorTypes.projectsUpdateProject }`));
    } finally {
        dispatch(endLoading());
    }
};

export const deleteProject = (id) => async (dispatch, getState) => {
    dispatch(startLoading(loadingTypes.projectsDeleteProject));
    try {
        await axiosInstance.delete(`/projects/deleteProject?uniqueId=${id}`);
        dispatch(setSuccessMessage("Success! Project removed."));
    } catch (err) {
        dispatch(setError(errorTypes.projectsDeleteProject, `${err.message ? err.message + " at " + errorTypes.projectsDeleteProject : "Something went wrong at " + errorTypes.projectsDeleteProject }`));
    } finally {
        dispatch(endLoading());
    }
}

export const undoDeleteProject = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.projectsUndoDeleteProject));
    try {
        await axiosInstance.post(`/projects/undoDelete?uniqueId=${id}`, data);
        dispatch(setSuccessMessage("Success! Project restored."));
    } catch (err) {
        dispatch(setError(errorTypes.projectsUndoDeleteProject, `${err.message ? err.message + " at " + errorTypes.projectsUndoDeleteProject : "Something went wrong at " + errorTypes.projectsUndoDeleteProject }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getNewProjectNumber = () => async dispatch => {
    dispatch(startLoading(loadingTypes.projectsGetNewProjectNumber));
    try {
        return await axiosInstance.get("/projects/getNewProjectNumber");
    } catch (err) {
        dispatch(setError(errorTypes.projectsGetNewProjectNumber, `${err.message ? err.message + " at " + errorTypes.projectsGetNewProjectNumber : "Something went wrong at " + errorTypes.projectsGetNewProjectNumber }`));
    } finally {
        dispatch(endLoading());
    }
};