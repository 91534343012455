import moment from 'moment';

export const handleSelectOpenSlot = (slotInfo, resources, state) => {
    let updatedForm = { ...state };
    let updatedFormFields = [...updatedForm.fields];
    const startDateIndex = updatedFormFields.findIndex(field => field.property === 'start');
    const endDateIndex = updatedFormFields.findIndex(field => field.property === 'end');
    const resourceIndex = updatedFormFields.findIndex(field => field.property === 'resourceId');

    if (startDateIndex !== -1 && endDateIndex !== -1) {
        let updatedStartDateField = { ...updatedFormFields[startDateIndex] };
        let updatedEndDateField = { ...updatedFormFields[endDateIndex] };

        let startDateTime = moment(slotInfo.start).format();
        let endDateTime = moment(slotInfo.end).format();

        if (slotInfo.start.getTime() === slotInfo.end.getTime()) {
            endDateTime = moment(slotInfo.end).add(1, 'hours').format();
        }

        updatedStartDateField.value = startDateTime;
        updatedEndDateField.value = endDateTime;

        updatedFormFields[startDateIndex] = updatedStartDateField;
        updatedFormFields[endDateIndex] = updatedEndDateField;

        if (resourceIndex !== -1) {
            let updatedResourceField = { ...updatedFormFields[resourceIndex] };
            if (slotInfo.resourceId) {
                let resource = resources.find(resource => resource.resourceId === slotInfo.resourceId);
                let resourceOption = resource ? resource.title : '';
                updatedResourceField.value = slotInfo.resourceId;

                const updatedElementConfig = {
                    ...updatedResourceField.elementConfig,
                    inputValue: resourceOption
                };
                updatedResourceField.elementConfig = updatedElementConfig;
            }
            updatedFormFields[resourceIndex] = updatedResourceField;
        }
        updatedForm.fields = updatedFormFields;
    }

    return updatedForm;
};