import * as actionTypes from './actions';

const initialState = { logoutLoading : false, logoutError : null };

const logoutStart = (state) => ({ ...state, logoutLoading : true });
const logoutSuccess = (state) => ({ ...state, logoutError : null, logoutLoading : false });
const logoutFail = (state, action) => ({ ...state, logoutError : action.error, logoutLoading : false });

const reducer = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.LOGOUT_START: return logoutStart(state);
        case actionTypes.LOGOUT_SUCCESS: return logoutSuccess(state);
        case actionTypes.LOGOUT_FAIL: return logoutFail(state, action);
        default: return state;
    }
};


export default reducer;