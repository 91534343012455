import * as actionTypes from './actions';

const initialState = {
    permissionsByGroup: {},
    permissionsByFeature: {},
    permissionsByRole: {},
    nextApprovers: {},
};

const setPermissionsByGroup = (state, { permissionsByGroup }) => ({ ...state, permissionsByGroup });
const setPermissionsByFeature = (state, { permissionsByFeature }) => ({ ...state, permissionsByFeature });
const setPermissionsByRole = (state, { permissionsByRole }) => ({ ...state, permissionsByRole });
const setNextApprovers = (state, { nextApprovers }) => ({ ...state, nextApprovers });

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PERMISSIONS_BY_GROUP:
            return setPermissionsByGroup(state, action);
        case actionTypes.SET_PERMISSIONS_BY_FEATURE:
            return setPermissionsByFeature(state, action);
        case actionTypes.SET_PERMISSIONS_BY_ROLE:
            return setPermissionsByRole(state, action);
        case actionTypes.SET_NEXT_APPROVERS:
            return setNextApprovers(state, action);
        default:
            return state;
    }
};