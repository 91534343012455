import React, { Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import styles from './EventRecurrence.module.css';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { DatePicker } from '@material-ui/pickers';
import { getDayName, countSpecificDaysInMonth, getLongDayName, convertNumberToString, getMonthName, calendarDate } from '../../../../assets/helpers/globals';
import clsx from 'clsx';

const EventRecurrence = (props) => {

    const { open,
        recurring,
        recurringErrors,
        recurringErrorMessages,
        startDate,
        currentRecurringArray } = props;

    const getMonthOption = (type) => {
        if (type === 'dayNumber') {
            return 'Monthly on day ' + startDate().getDate();
        }
        else {
            return 'Monthly on the ' + countSpecificDaysInMonth(startDate().getFullYear(), startDate().getMonth(), startDate().getDay(), startDate().getTime()) + ' ' + getDayName(startDate().getDay());
        }
    };

    const buildLongDayString = (days) => {
        let newArray = days.split(',');
        if(newArray && newArray.length > 0){
            return newArray.map((day, index) => {
                return ' ' + getLongDayName(day);
            });
        }
        return '';
    }

    const getDetails = () => {
        // console.log(currentRecurringArray);
        let rRule = currentRecurringArray[0];
        let returnStr = '';

        let rRuleTemp = rRule.substring(rRule.indexOf(':') + 1);
        let rRuleArray = rRuleTemp.split(';');

        var freq;
        var interval;
        var count;
        var until;
        var byday;
        var bymonthday;

        rRuleArray.forEach(rule => {
            let keyValueArray = rule.split('=');
            let key = keyValueArray[0].toLowerCase();
            let value = keyValueArray[1];
            switch (key){
                case 'freq' : freq = value; break;
                case 'interval' : interval = value; break;
                case 'count' : count = value; break;
                case 'until' : until = value; break;
                case 'byday' : byday = value; break;
                case 'bymonthday' : bymonthday = value; break;
                default: break;
            };
        });

        switch (freq.toLowerCase()) {
            case 'daily' : 
                returnStr = Number.isNaN(parseInt(interval)) || parseInt(interval) === 1 ? 'Daily' : 'Every ' + parseInt(interval) + ' days';
                break;
            case 'weekly' : 
                returnStr = Number.isNaN(parseInt(interval)) || parseInt(interval) === 1 ? 'Weekly' : 'Every ' + parseInt(interval) + ' weeks';
                returnStr += byday ? ' on ' + buildLongDayString(byday) : '';
                break;
            case 'monthly' : 
                returnStr = Number.isNaN(parseInt(interval)) || parseInt(interval) === 1 ? 'Monthly' : 'Every ' + parseInt(interval) + ' months';
                returnStr += bymonthday ? ' on day ' + bymonthday : '';
                const byDayInteger = byday ? byday.substring(0,1) : 0;
                const byDayName = byday ? byday.substring(1) : '';
                returnStr += byday ? ' on the ' + convertNumberToString(parseInt(byDayInteger)) + ' ' + getLongDayName(byDayName) : '';
                break;
            case 'yearly' : 
                returnStr = Number.isNaN(parseInt(interval)) || parseInt(interval) === 1 ? 'Annually' : 'Every ' + parseInt(interval) + ' years';
                break;
            default: break;
        };

        if(until != null){
            let date = calendarDate(until);
            returnStr += ', until ' + date.getDate() + ' ' + getMonthName(date.getMonth()) + ' ' + date.getFullYear();
        }
        else if(count != null){
            let timeStr = parseInt(count) === 1 ? ' time' : ' times';
            returnStr += ', ' + count + timeStr;
        }
        
        return returnStr;
    };

    return (
        <Fragment>
            <Button variant="outlined" color="primary" onClick={props.handleOpen} >
                Repeat event
            </Button>
            { currentRecurringArray && currentRecurringArray.length > 0 ? <p className={styles.DetailsParagraph}>{getDetails()}</p> : null}
            <Dialog
                onClose={props.handleClose}
                aria-labelledby='event-recurrence-dialog-title'
                fullWidth
                maxWidth={'xs'}
                open={open}>
                <DialogTitle id='event-recurrence-dialog-title'>
                    Event Recurrence
                </DialogTitle>
                <DialogContent>
                    <Grid container
                        spacing={2}
                        direction='row'
                        alignItems='center'>
                        <Grid item xs={5} lg={4}>
                            <p className={styles.Subheadings}>Repeat every</p>
                        </Grid>
                        <Grid item xs={2} lg={3}>
                            <TextField
                                fullWidth
                                type='number'
                                name='count'
                                onChange={(e) => props.handleChange(e, false)}
                                value={recurring.count ? recurring.count : 1}
                                error={recurringErrors.count}
                                helperText={recurringErrorMessages.count}
                                inputProps={{
                                    min: 1
                                }} />
                        </Grid>
                        <Grid item xs={4} lg={5}>
                            <Select
                                name='freq'
                                value={recurring.freq ? recurring.freq : 'weekly'}
                                fullWidth
                                onChange={(e) => props.handleChange(e, false)}
                            >
                                <MenuItem value={'daily'}>{!recurring.count || recurring.count === 1 ? 'day' : 'days'}</MenuItem>
                                <MenuItem value={'weekly'}>{!recurring.count || recurring.count === 1 ? 'week' : 'weeks'}</MenuItem>
                                <MenuItem value={'monthly'}>{!recurring.count || recurring.count === 1 ? 'month' : 'months'}</MenuItem>
                                <MenuItem value={'yearly'}>{!recurring.count || recurring.count === 1 ? 'year' : 'years'}</MenuItem>
                            </Select>
                        </Grid>

                        {recurring.freq && (recurring.freq === 'weekly' || recurring.freq === 'monthly') ?
                            <Grid item xs={12}>
                                <p className={styles.Subheadings}>Repeat on</p>
                            </Grid> : null
                        }

                        {!recurring.freq || recurring.freq === 'weekly' ?

                            <Fragment>
                                <Grid item xs={12} className={styles.WeekButtonGroup}>
                                    <Button  variant='contained'
                                        className={!recurring.weekDays || recurring.weekDays.findIndex(o => o === 'SU') === -1 ? styles.DayButton : clsx(styles.DayButton, styles.ActiveDayButton)}
                                        onClick={(e) => props.handleToggleWeekRecurringDays('SU')}>
                                        <span>S</span>
                                    </Button>
                                    <Button variant='contained'
                                        className={!recurring.weekDays || recurring.weekDays.findIndex(o => o === 'MO') === -1 ? styles.DayButton : clsx(styles.DayButton, styles.ActiveDayButton)}
                                        onClick={(e) => props.handleToggleWeekRecurringDays('MO')}>
                                        <span>M</span>
                                    </Button>
                                    <Button variant='contained'
                                        className={!recurring.weekDays || recurring.weekDays.findIndex(o => o === 'TU') === -1 ? styles.DayButton : clsx(styles.DayButton, styles.ActiveDayButton)}
                                        onClick={(e) => props.handleToggleWeekRecurringDays('TU')}>
                                        <span>T</span>
                                    </Button>
                                    <Button variant='contained'
                                        className={!recurring.weekDays || recurring.weekDays.findIndex(o => o === 'WE') === -1 ? styles.DayButton : clsx(styles.DayButton, styles.ActiveDayButton)}
                                        onClick={(e) => props.handleToggleWeekRecurringDays('WE')}>
                                        <span>W</span>
                                    </Button>
                                    <Button variant='contained'
                                        className={!recurring.weekDays || recurring.weekDays.findIndex(o => o === 'TH') === -1 ? styles.DayButton : clsx(styles.DayButton, styles.ActiveDayButton)}
                                        onClick={(e) => props.handleToggleWeekRecurringDays('TH')}>
                                        <span>T</span>
                                    </Button>
                                    <Button variant='contained'
                                        className={!recurring.weekDays || recurring.weekDays.findIndex(o => o === 'FR') === -1 ? styles.DayButton : clsx(styles.DayButton, styles.ActiveDayButton)}
                                        onClick={(e) => props.handleToggleWeekRecurringDays('FR')}>
                                        <span>F</span>
                                    </Button>
                                    <Button variant='contained'
                                        className={!recurring.weekDays || recurring.weekDays.findIndex(o => o === 'SA') === -1 ? styles.DayButton : clsx(styles.DayButton, styles.ActiveDayButton)}
                                        onClick={(e) => props.handleToggleWeekRecurringDays('SA')}>
                                        <span>S</span>
                                    </Button>
                                    
                                </Grid>
                                {recurringErrors.weekDays ? 
                                    <Grid item xs={12} className={styles.WeekButtonGroup}>
                                        <span className={styles.WeekdaysError}>{recurringErrorMessages.weekDays}</span>
                                    </Grid>  : null 
                                }
                            </Fragment>
                            :
                            recurring.freq === 'monthly' ?

                                <Grid item xs={12}>
                                    <Select
                                        name='monthlyType'
                                        value={recurring.monthlyType ? recurring.monthlyType : 'dayNumber'}
                                        fullWidth
                                        onChange={(e) => props.handleChange(e, false)}
                                    >
                                        <MenuItem value={'dayNumber'}>{getMonthOption('dayNumber')}</MenuItem>
                                        <MenuItem value={'dayName'}>{getMonthOption('dayName')}</MenuItem>
                                    </Select>
                                </Grid>
                                :
                                null
                        }

                        <Grid item xs={12}>
                            <p className={styles.Subheadings}>Ends</p>
                        </Grid>

                        <Grid item xs={12}>
                            <RadioGroup
                                aria-label='ends'
                                name='ends'
                                onChange={(e) => props.handleChange(e, false)}
                                value={recurring.ends ? recurring.ends : 'never'}>
                                <Grid container
                                    spacing={2}
                                    direction='row'
                                    alignItems='center'>
                                    <Grid item xs={12}>
                                        <FormControlLabel value='never' control={<Radio />} label='Never' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel value='on' control={<Radio />} label='On' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            name='onDate'
                                            value={recurring.onDate ? recurring.onDate : new Date()}
                                            disablePast
                                            showTodayButton
                                            fullWidth
                                            onChange={(date) => props.handleChange(date, true, 'onDate')}
                                            error={recurringErrors.onDate}
                                            helperText={recurringErrorMessages.onDate}
                                            disabled={recurring.ends !== 'on'}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel value='after' control={<Radio />} label='After' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container
                                            spacing={2}
                                            direction='row'
                                            alignItems='center'>
                                            <Grid item xs={4}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    type='number'
                                                    name='afterInterval'
                                                    value={recurring.afterInterval ? recurring.afterInterval : 1}
                                                    onChange={(e) => props.handleChange(e, false)}
                                                    error={recurringErrors.afterInterval}
                                                    helperText={recurringErrorMessages.afterInterval}
                                                    disabled={recurring.ends !== 'after'}
                                                    inputProps={{
                                                        min: 0
                                                    }} />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <p>{!recurring.afterInterval || recurring.afterInterval === 1 ? 'occurrence' : 'occurrences'}</p>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={props.handleClear}
                        color='primary'
                        className={styles.ActionButton}>
                        Clear
                    </Button>
                    <Button
                        onClick={props.handleSubmit}
                        color='secondary'
                        className={styles.ActionButton}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );

};

export default EventRecurrence;