import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "../../Buttons/Button/Button";
import styles from "./InputFormDialog.module.css";
import InputForm from "../../Forms/InputForm/InputForm";

const InputFormDialog = props => {
	const { formFields, loading, onClose, onSubmit, open } = props;

	return (
		<Dialog
			className={styles.Dialog}
			fullWidth
			maxWidth={"sm"}
			onClose={onClose}
			open={open}
		>
			<DialogContent className={styles.DialogContent}>
				<InputForm formFields={formFields} />
			</DialogContent>
			<DialogActions className={styles.ActionsContainer}>
				<Button color="secondary" onClick={onClose}>
					Cancel
				</Button>
				<Button
					color="primary"
					hasLoader={true}
					loading={loading}
					onClick={onSubmit}
				>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default InputFormDialog;
