import React from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import { hideSuccessMessage } from "../../store/success/actions"

const SuccessAlert = (props) => {
    return (
        props.successMessage && <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={props.isOpen}
            autoHideDuration={5000}
            onClose={props.onHideSuccessMessage}
        >
            <Alert variant="filled" onClose={props.onHideSuccessMessage} severity="success">
                {props.successMessage}
            </Alert>
        </Snackbar>
    );
};

const mapStateToProps = (state) => {
    return {
        successMessage: state.successReducer.successMessage,
        isOpen: state.successReducer.isOpen,
    }
};

const mapDispatchToProps = dispatch => ({ onHideSuccessMessage: () => dispatch(hideSuccessMessage()) });

export default connect(mapStateToProps, mapDispatchToProps)(SuccessAlert);
