import React from "react";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {formatShortDate} from "../../../assets/helpers/globals";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Loader from "../../../components/Loader/Loader";
import * as loadingTypes from "../../../store/loading/types";
import styled, {css} from "styled-components";
import {Radios, TextField} from "mui-rff";
import Grid from "@material-ui/core/Grid";
import {Field, Form} from "react-final-form";
import FileFieldAdapter from "../../../components/Inputs/FileField/FileField";
import {Condition, focusOnError} from "../../../assets/helpers/finalFormHelpers";

const LoadingDiv = styled.div`
  position: absolute;
  bottom: 11px;
  right: 36px;
`;

const H3 = styled.h3`
  margin: 0;
`;

const ActionButton = styled(Button)`
  margin: 0 5px;
`;

const FormInput = styled.form`
  width: 100%;
`;

const FormDialogContent = styled(DialogContent)`
  padding: 10px 10px;
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const StatusRadios = styled(Radios)`
  ${props => props.data[0]?.label === "Accepted" && css`&.Mui-checked { color: Green }`}
  ${props => props.data[0]?.label === "Rejected" && css`&.Mui-checked { color: Red }`}
`;

const ResponseDialog = ({
                       details,
                       onClose,
                       open,
                       onSubmit,
                       loading,
                   }) => {
    const formFields = [
        {
            field: (
                <Grid item xs={12}>
                    <ListItemText>
                        <H3>Status</H3>
                        <StatusRadios
                            name="status"
                            formControlProps={{margin: 'none'}}
                            radioGroupProps={{row: true}}
                            data={[
                                {label: "Accepted", value: "Accepted"},
                            ]}
                        />
                        <StatusRadios
                            name="status"
                            formControlProps={{margin: 'none'}}
                            radioGroupProps={{row: true}}
                            data={[
                                {label: "Rejected", value: "Rejected"},
                            ]}
                            showError={() => false}
                        />
                    </ListItemText>
                </Grid>
            ),
        },
        {
            field: (
                <Condition when="status" is="Accepted">
                    <Grid item xs={12}>
                        <ListItemText>
                            <H3>Attachment</H3>
                            <Field
                                component={FileFieldAdapter}
                                name="attachments"
                                multiple
                                buttonLabel="UPLOAD AN INVOICE"
                                clearLabel="INVOICES"
                            />
                        </ListItemText>
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Condition when="status" is="Rejected">
                    <Grid item xs={12}>
                        <ListItemText>
                            <H3>Comment</H3>
                            <TextField
                                name="comment"
                                margin="none"
                                required
                                placeholder={"Comment"}
                                multiline
                            />
                        </ListItemText>
                    </Grid>
                </Condition>
            ),
        },
    ]

    const validate = values => {
        const errors = {}
        if (!values.status)
            errors.status = "Required"

        if (values.status === "Accepted" && (!values.attachments || !values.attachments?.length))
            errors.attachments = "Required"


        if (values.status === "Rejected" && !values.comment)
            errors.comment = "Required"

        return errors
    }

    let submit;

    const onSubmitForm = async values => {
        if (values.status === "Accepted") {
            let formData = new FormData();
            let attachArray = [];

            values.attachments.forEach(file => {
                if (file.generatedFilename)
                    attachArray.push(file.generatedFilename)
                else
                    formData.append("file", file);
            });

            if (attachArray && attachArray.length)
                formData.append("existingAttachments", attachArray);
            else
                formData.append("existingAttachments", null);
            formData.append("id", values.uniqueId);
            formData.append('responseSubmittedDate', formatShortDate(new Date()));
            formData.append('status', "Invoice Attached");
            formData.append('comment', "")

            await onSubmit(formData, true);
        }
        else
            await onSubmit({ ...values, lastResponseDate: formatShortDate(new Date()) }, false)
    }

    return <Form
        onSubmit={onSubmitForm}
        validate={validate}
        initialValues={details}
        decorators={[focusOnError]}
        render={({handleSubmit, submitting, pristine}) => {
            submit = handleSubmit
            return (
                <Dialog
                    aria-labelledby="invoice-request-respond-dialog-title"
                    onClose={onClose}
                    fullWidth
                    maxWidth={"sm"}
                    open={open}
                >
                    <CustomDialogTitle
                        id="invoice-request-respond-dialog-title"
                        onClose={onClose}
                        showTextContent
                    >
                        Respond to Invoice Request
                    </CustomDialogTitle>
                    <FormDialogContent dividers>
                        <ListItem>
                            <FormInput noValidate>
                                <Grid container alignItems="flex-start" spacing={1}>
                                    {formFields.map((item, idx) => (
                                        <React.Fragment key={idx}>
                                            {item.field}
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </FormInput>
                        </ListItem>
                    </FormDialogContent>
                    <DialogActions>
                        <ActionButton
                            onClick={onClose}
                            type="button"
                            disabled={submitting}
                            color="primary"
                        >
                            Cancel
                        </ActionButton>
                        <ActionButton
                            onClick={event => submit(event)}
                            type="submit"
                            disabled={submitting || pristine}
                            color="default"
                        >
                            Submit
                        </ActionButton>
                        <LoadingDiv>
                            <Loader
                                loading={loading.type === loadingTypes.invoicesUpdateInvoiceRequestStatus || loading.type === loadingTypes.invoicesAddInvoiceResponse}
                                 size="small"/>
                        </LoadingDiv>
                    </DialogActions>
                </Dialog>
            )
        }}
    />
}

export default ResponseDialog;