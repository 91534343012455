import * as actionTypes from './actions';

const initialState = {
    vendorsBySupplier: [],
    vendorsByCustomer: []
};

const setVendorsBySupplier = (state, { vendorsBySupplier }) => ({ ...state, vendorsBySupplier });
const setVendorsByCustomer = (state, { vendorsByCustomer }) => ({ ...state, vendorsByCustomer });

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_VENDORS_BY_SUPPLIER:
            return setVendorsBySupplier(state, action);
        case actionTypes.SET_VENDORS_BY_CUSTOMER:
            return setVendorsByCustomer(state, action);
        default:
            return state;
    }
};