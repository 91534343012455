import CustomDialogTitle from "../../../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import GetApp from "@material-ui/icons/GetApp";
import {formatAmount} from "../../../../../assets/helpers/globals";
import Dialog from "@material-ui/core/Dialog";
import styled, {css} from "styled-components";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from "@material-ui/core/List";
import * as loadingTypes from "../../../../../store/loading/types";
import Loader from "../../../../../components/Loader/Loader";


const H3 = styled.h3`
  margin: 0;
`;

const P = styled.p`
  margin: 0;
  ${props => props.blue && css`color: RoyalBlue;`}
  ${props => props.small && css`font-size: small;`}
`;

const FormDialogContent = styled(DialogContent)`
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const DownloadText = styled.p`
  margin: 15px 0 0 0;
`;

const DownloadButton = styled(Button)`
  position: absolute;
  right: 0;
  margin-top: -5px;
  background: white;
  box-shadow: none;
`;

const RequesterView = ({
                           details,
                           onClose,
                           open,
                           downloadFile,
                           loading
                   }) => (
<Dialog
    aria-labelledby="it-equipment-purchase-request-details-dialog-title"
    onClose={onClose}
    fullWidth
    maxWidth={"sm"}
    open={open}
>
    <CustomDialogTitle
        id="it-equipment-request-details-dialog-title"
        onClose={onClose}
        showTextContent
    >
        IT Equipment Purchase Details
    </CustomDialogTitle>
    <FormDialogContent dividers>
        <ListItem>
            <ListItemText>
                <H3>Attachment</H3>
                {details?.purchase?.attachments.map(file => (
                    <React.Fragment key={`DOWNLOAD_FILE_${file.uniqueId}`}>
                        <DownloadText>- {file.name}
                            <DownloadButton onClick={() => downloadFile(file.uniqueId, file.name)}>
                                <Tooltip title="Download File">
                                    <GetApp color={"primary"}/>
                                </Tooltip>
                            </DownloadButton>
                        </DownloadText>
                    </React.Fragment>
                )) || "Not Specified"}
                <Loader loading={loading.type === loadingTypes.fileDownload} center />
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Response Submitted</H3>
                <P>{details.purchase?.requestDate || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Last Response Date</H3>
                <P>{details.purchase?.lastResponseDate || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        {details?.nextApprovers?.length !== 0 ?
        <ListItem style={{padding: "16px 16px"}}>

            <ExpansionPanel style={{ boxShadow: "none",width: "99.4%"}} >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{ minHeight: "20px",height:"10px",padding:"0px"}}
                >
                    <Typography><span style={{fontWeight:"bold",fontSize:"1.17em"}}>{details?.nextApprovers?.length} Allowed Approvers</span></Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: "8px 16px 0px 16px" }}>
                    <List style={{ paddingBottom: 0, paddingTop: 14 }}>
                        { details?.nextApprovers?.map(approver => (
                            <ListItem   key={approver?.userEmail}>
                                <ListItemText primary={approver.userFullName}
                                              secondary={ approver.userEmail}
                                />
                            </ListItem>
                        )) || "None"}
                    </List>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </ListItem> : ""}
        <ListItem>
            <ListItemText>
                <H3>Supplier</H3>
                <P>{details.purchase?.vendor ? details.purchase?.vendor.vendorName : details.purchase?.vendorOther}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Required Date</H3>
                <P>{details?.requiredDate || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Purchase Description</H3>
                <P>{details.purchase?.description || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Quote Number</H3>
                <P>{details.purchase?.quoteNumber || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Total Cost ({details.purchase?.xeroTaxOption.taxOptionText})</H3>
                <P>{formatAmount(details.purchase?.totalCost, details.purchase?.currency.name) || "Not specified"}</P>
                                {details.purchase?.exchangeRate && details.purchase?.currency.name !== "ZAR" ? <P blue small>{`~ 1 ${details.purchase?.currency.name} = ${details.purchase?.exchangeRate} ZAR`}</P> : ""}
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Purchase Requester</H3>
                <P>{details.purchase?.requestor?.userFullName || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Status</H3>
                <P>{details.purchase?.status || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Comment</H3>
                <P>{details.purchase?.rejectReason || "Not specified"}</P>
            </ListItemText>
        </ListItem>
    </FormDialogContent>
</Dialog>
)

export default RequesterView;