import * as actionTypes from "./actions";

const initialState = { error: false, isOpen: false };

const setError = action => ({ error: { error: action.error, errorMessage: action.errorMessage }, isOpen: true });
const hideError = () => initialState;

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_ERROR:
			return setError(action);
		case actionTypes.HIDE_ERROR:
			return hideError();
		default:
			return state;
	}
};
