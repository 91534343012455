import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import * as xeroActions from "../../store/xero/actions";
import {getPermissionsByRole} from "../../store/permissions/actions";
import {getCurrentUser} from "../../store/user/actions";
import Loader from "../../components/Loader/Loader";
import * as loadingTypes from "../../store/error/types";
import styled from "styled-components";

const LoadingDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const Devs = ({
                  getAllLatestAccounts,
                  getUserRoles,
                  getCurrentUser,
                  populateAll,
                  loading,
                  populateTaxTypeCurrencyAddress,
                  populateTrackingOptions,
                  populateVendors,
                  user,

              }) => {
    const [roles, setRoles] = useState({
        is_developer: false,
    });

    useEffect(() => {

        (async function() {
            const res = await getUserRoles("admin","developer");
            const permissionObj = {
                is_developer: res.some(name => name === "developer"),
            }
            setRoles(permissionObj);

            // if (Object.entries(permissionObj).some(([key, value]) => key !== "is_invoice_requestor" && value === true))
            //     setFilter(f => ({...f, actions: {description: "Awaiting Your Approval", value: "approve"}}))
        })();

        if (!user.userEmail)
            getCurrentUser();
    }, [getCurrentUser, getUserRoles, user.userEmail])

    if (roles.is_developer)
        return(
        <>
        <Paper>
            <h2 style={{paddingLeft:"10px"}} >Xero Populating</h2>
            <Grid style={{paddingLeft:"10px"}}  container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                        onClick={getAllLatestAccounts}
                        size="large"
                        type="button"
                        color="secondary"
                    >
                        Populate All Accounts
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                        onClick={populateTaxTypeCurrencyAddress}
                        size="large"
                        type="button"
                        color="secondary"
                    >
                        Populate All Tax Types, Currencies and Addresses
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                        onClick={populateTrackingOptions}
                        type="button"
                        size="large"
                        color="secondary"
                    >
                        Populate All Tracking Options
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                        onClick={populateVendors}
                        type="button"
                        size="large"
                        color="secondary"
                    >
                        Populate Vendors
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                        onClick={populateAll}
                        type="button"
                        size="large"
                        color="primary"
                    >
                        Do All
                    </Button>
                </Grid>
                <LoadingDiv>
                    <Loader
                        loading={loading.type === loadingTypes.xeroPopulateAll ||
                        loading.type === loadingTypes.xeroPopulateCurrencyAddressTaxTypes ||
                        loading.type === loadingTypes.xeroPopulateTrackingOptions ||
                        loading.type === loadingTypes.xeroPopulateVendors ||
                        loading.type === loadingTypes.xeroGetAllLatestAccounts}
                    />
                </LoadingDiv>
            </Grid>
        </Paper>

        </>
    );else return("");
}
const mapStateToProps = state => ({
    user: state.userReducer.currentUser,
    loading: state.loadingReducer.loading,
});

const mapDispatchToProps = dispatch => ({
    getAllLatestAccounts: () => dispatch(xeroActions.getAllLatestAccounts()),
    getUserRoles: (roleType, roleSubType) => dispatch(getPermissionsByRole(roleType, roleSubType)),
    getCurrentUser: () => getCurrentUser()(dispatch),
    populateAll: () => dispatch(xeroActions.populateAll()),
    populateTaxTypeCurrencyAddress: () => dispatch(xeroActions.populateTaxTypeCurrencyAddress()),
    populateTrackingOptions: () => dispatch(xeroActions.populateTrackingOptions()),
    populateVendors: () => dispatch(xeroActions.populateVendors()),
})

export default connect(mapStateToProps,mapDispatchToProps)(Devs);