import * as actionTypes from "./actions";

const initialState = {
    allTaxTypes: [],
    allLatestAccounts: [],
    allAccounts: [],
    allTracking: [],
    taxTypesByLegalEntity: [],
    accountsByLegalEntity: [],
    trackingByLegalEntity: [],
};

const setAllTaxTypes = (state, { allTaxTypes }) => ({ ...state, allTaxTypes });
const setAllLatestAccounts = (state, { allLatestAccounts }) => ({ ...state, allLatestAccounts });
const setAllAccounts = (state, { allAccounts }) => ({ ...state, allAccounts });
const setAllTracking = (state, { allTracking }) => ({ ...state, allTracking });
const setTaxTypesByLegalEntity = (state, { taxTypesByLegalEntity }) => ({ ...state, taxTypesByLegalEntity });
const setAccountsByLegalEntity = (state, { accountsByLegalEntity }) => ({ ...state, accountsByLegalEntity });
const setTrackingByLegalEntity = (state, { trackingByLegalEntity }) => ({ ...state, trackingByLegalEntity });

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ALL_TAX_TYPES:
            return setAllTaxTypes(state, action);
        case actionTypes.SET_ALL_LATEST_ACCOUNTS:
            return setAllLatestAccounts(state, action);
        case actionTypes.SET_ALL_ACCOUNTS:
            return setAllAccounts(state, action);
        case actionTypes.SET_ALL_TRACKING:
            return setAllTracking(state, action);
        case actionTypes.SET_TAX_TYPES_BY_LEGAL_ENTITY:
            return setTaxTypesByLegalEntity(state, action);
        case actionTypes.SET_ACCOUNTS_BY_LEGAL_ENTITY:
            return setAccountsByLegalEntity(state, action);
        case actionTypes.SET_TRACKING_BY_LEGAL_ENTITY:
            return setTrackingByLegalEntity(state, action);
        default:
            return state;
    }
};