import React from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {TextField, KeyboardDatePicker, Autocomplete, Radios} from "mui-rff";
import styled from "styled-components";
import {Form, Field} from "react-final-form";
import {Condition, focusOnError} from "../../../../assets/helpers/finalFormHelpers";
import CustomDialogTitle from "../../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import Loader from "../../../../components/Loader/Loader";
import * as loadingTypes from "../../../../store/error/types";
import arrayMutators from 'final-form-arrays'
// import SplitButton from "../../../../components/Buttons/SplitButton/SplitButton";
import DateFnsUtils from "@date-io/date-fns";
import MultiplePurchaseOrderLineItemFieldAdapter
    from "../../../../components/Inputs/MultiplePurchaseOrderLineItemField/MultiplePurchaseOrderLineItemField";
import {sleep} from "../../../../assets/helpers/globals";

const LoadingDiv = styled.div`
  position: absolute;
  bottom: 11px;
  right: 81px;
`;

const FormDialogContent = styled(DialogContent)`
  padding: 24px 30px;
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const ActionButton = styled(Button)`
  margin: 0 5px;
`;

function NewRequestDialog  ({
                                open,
                                onClose,
                                onSubmit,
                                loading,
                                initialValues,
                                taxTypesByLegalEntity,
                                accountsByLegalEntity,
                                trackingByLegalEntity,
                                deliveryAddressPerLegalEntity,
                                getAccountsByLegalEntity,
                                getAllLatestAccounts,
                            }) {
    const formFields = (values, errors, touched) => [
        {
            field: (
                <Grid item xs={6}>
                    <TextField
                        label="Billing From"
                        name="legalEntity.entityName"
                        margin="none"
                        disabled
                    />
                </Grid>
            ),
        },
        {
            field: (
                    <Grid item xs={6}>
                        <TextField
                            label="Contact"
                            name="vendor.vendorName"
                            margin="none"
                            disabled
                        />
                    </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={6}>
                    <TextField
                        label="Currency"
                        name="currency.name"
                        margin="none"
                        disabled
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={6}>
                    <TextField
                        label="Amounts Are"
                        name="purchaseTaxOption.taxOptionText"
                        margin="none"
                        disabled
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={6}>
                    <KeyboardDatePicker
                        dateFunsUtils={DateFnsUtils}
                        label="Delivery Date"
                        name="deliveryDate"
                        margin="none"
                        format="dd-MM-yyyy"
                        placeholder={"dd-mm-yyyy"}
                        // format="EEEE, d MMMM, y"
                        showTodayButton
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={6}>
                    <TextField
                        label="Reference"
                        name="reference"
                        margin="none"
                        required
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Field
                        component={MultiplePurchaseOrderLineItemFieldAdapter}
                        name="purchaseOrderLineItems"
                        label="Line Items"
                        taxTypesByLegalEntity={taxTypesByLegalEntity}
                        accountsByLegalEntity={accountsByLegalEntity}
                        trackingByLegalEntity={trackingByLegalEntity}
                        getAllLatestAccounts={getAllLatestAccounts}
                        getAccountsByLegalEntity={getAccountsByLegalEntity}
                        legalEntityUniqueId={values.legalEntity?.uniqueId}
                        values={values}
                        errors={errors}
                        touched={touched}
                        required={true}
                        loading={loading}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Radios
                        label="Delivery Address"
                        name="isDefaultDeliveryAddress"
                        margin="none"
                        radioGroupProps={{row: true}}
                        data={[
                            {label: 'Existing Delivery Address', value: 'true'},
                            {label: 'Add One-off Address', value: 'false'},
                        ]}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Condition when="isDefaultDeliveryAddress" is="true">
                    <Grid item xs={12}>
                        <Autocomplete
                            label="Existing Delivery Address"
                            name="existingDeliveryAddress"
                            noOptionsText={"No existing delivery addresses found"}
                            options={deliveryAddressPerLegalEntity}
                            getOptionLabel={option => option ? `${option.addressType ? "(" + option.addressType + ") " : ""}${option.addressLine1 ? option.addressLine1 + ", " : ""}${option.addressLine2 ? option.addressLine2 + ", " : ""}${option.addressLine3 ? option.addressLine3 + ", " : ""}${option.addressLine4 ? option.addressLine4 + ", " : ""}${option.city ? option.city + ", " : ""}${option.region ? option.region + ", " : ""}${option.postalCode ? option.postalCode + ", " : ""}${option.country ? option.country : ""}` : ""}
                            getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                            // filterOptions={(options, params) => options.filter((option) => option !== "" &&
                            //     (option.addressType ? option.addressType.toLowerCase().includes(params.inputValue.toLowerCase()) : "" ||
                            //         option.addressLine1 ? option.addressLine1.toLowerCase().includes(params.inputValue.toLowerCase()) : "" ||
                            //         option.addressLine2 ? option.addressLine2.toLowerCase().includes(params.inputValue.toLowerCase()) : "" ||
                            //         option.addressLine3 ? option.addressLine3.toLowerCase().includes(params.inputValue.toLowerCase()) : "" ||
                            //         option.addressLine4 ? option.addressLine4.toLowerCase().includes(params.inputValue.toLowerCase()) : "" ||
                            //         option.city ? option.city.toLowerCase().includes(params.inputValue.toLowerCase()) : "" ||
                            //         option.region ? option.region.toLowerCase().includes(params.inputValue.toLowerCase()) : "" ||
                            //         option.postalCode ? option.postalCode.toLowerCase().includes(params.inputValue.toLowerCase()) : "" ||
                            //         option.country ? option.country.toLowerCase().includes(params.inputValue.toLowerCase()) : ""
                            //         // || (option.addressType + ", " + option.addressLine1 + ", " + option.addressLine2 + ", " + option.addressLine3 + ", " + option.addressLine4 + ", " + option.city + ", " + option.region + ", " + option.postalCode + ", " + option.country).toLowerCase().includes(params.inputValue.toLowerCase())
                            //     ))}
                            handleHomeEndKeys
                        />
                    </Grid>
                </Condition>
            ),
        },
        // {
        //     field: (
        //         <Condition when="isDefaultDeliveryAddress" is="true">
        //             <Grid item xs={6}>
        //                 <TextField
        //                     label="Attention To"
        //                     name="existingDeliveryAddress.attentionTo"
        //                     margin="none"
        //                     disabled
        //                 />
        //             </Grid>
        //         </Condition>
        //     ),
        // },
        {
            field: (
                <Condition when="isDefaultDeliveryAddress" is="false">
                    <Grid item xs={12}>
                        <TextField
                            label="Add one-off delivery address"
                            name="oneOffDeliveryAddress"
                            margin="none"
                            multiline
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <TextField
                        label="Delivery Instructions"
                        name="deliveryInstructions"
                        margin="none"
                        multiline
                    />
                </Grid>
            ),
        },
    ]

    const validate = values => {
        const errors = {}

        // if (values.status === "PO Pending" || values.status === "PO Reviewed") {
        if (!values.reference)
            errors.reference = 'Required'
        if (isNaN(Date.parse(values.deliveryDate)))
            errors.deliveryDate = 'Delivery date is not a valid date'

        if (!values.purchaseOrderLineItems ||
                !values.purchaseOrderLineItems.length ||
                values.purchaseOrderLineItems.indexOf(null) !== -1)
                errors.purchaseOrderLineItems = "Required"
            else {
                values.purchaseOrderLineItems.forEach((purchaseOrderLineItemsItem, purchaseOrderLineItemsItemIdx) => {
                    Object.entries(purchaseOrderLineItemsItem).forEach(([key, value]) => {
                        if (key === "description" && !value)
                            errors["purchaseOrderLineItems[" + purchaseOrderLineItemsItemIdx + "].description"] = "Required"
                        if (key === "quantity" && !value)
                            errors["purchaseOrderLineItems[" + purchaseOrderLineItemsItemIdx + "].quantity"] = "Required"
                        if (key === "quantity" && (value === "0" || value === "0.0" || value === "0.00"))
                            errors["purchaseOrderLineItems[" + purchaseOrderLineItemsItemIdx + "].quantity"] = "Value may not be 0. Please enter a valid value."
                        if (key === "unitAmount" && !value)
                            errors["purchaseOrderLineItems[" + purchaseOrderLineItemsItemIdx + "].unitAmount"] = "Required"
                        if (key === "unitAmount" && (value === "0" || value === "0.0" || value === "0.00"))
                            errors["purchaseOrderLineItems[" + purchaseOrderLineItemsItemIdx + "].unitAmount"] = "Value may not be 0. Please enter a valid value."
                        if (key === "xeroAccounts" && !value)
                            errors["purchaseOrderLineItems[" + purchaseOrderLineItemsItemIdx + "].xeroAccounts"] = "Required"
                        if (key === "xeroTaxTypes" && !value)
                            errors["purchaseOrderLineItems[" + purchaseOrderLineItemsItemIdx + "].xeroTaxTypes"] = "Required"
                        if (key === "purchaseOrderLineItemTrackings" && value.length !== 0)
                            value.forEach((valueItem, valueItemIdx) => {
                                if (!valueItem.xeroTrackingOptions)
                                    errors["purchaseOrderLineItems[" + purchaseOrderLineItemsItemIdx + "].purchaseOrderLineItemTrackings[" + valueItemIdx + "].xeroTrackingOptions"] = "Required"
                            })
                    })
                    if (!purchaseOrderLineItemsItem.hasOwnProperty('description'))
                        errors["purchaseOrderLineItems[" + purchaseOrderLineItemsItemIdx + "].description"] = "Required"
                    if (!purchaseOrderLineItemsItem.hasOwnProperty('quantity'))
                        errors["purchaseOrderLineItems[" + purchaseOrderLineItemsItemIdx + "].quantity"] = "Required"
                    if (!purchaseOrderLineItemsItem.hasOwnProperty('unitAmount'))
                        errors["purchaseOrderLineItems[" + purchaseOrderLineItemsItemIdx + "].unitAmount"] = "Required"
                })
            }
        // }

        return errors
    }

    const onSubmitForm = async values => {
        let formData = new FormData();

        // if (values.status === "PO Reviewed") {
        //     let errors = validate(values)
        //     if (!isEmptyObject(errors))
        //         return errors
        //     else {
                if (values.deliveryInstructions)
                    formData.append('deliveryInstructions', values.deliveryInstructions);
                formData.append('isDefaultDeliveryAddress', values.isDefaultDeliveryAddress);

                if (values.isDefaultDeliveryAddress === 'true') {
                    if (values.existingDeliveryAddress?.uniqueId)
                        formData.append('existingDeliveryAddress', values.existingDeliveryAddress.uniqueId);
                }
                else if (values.oneOffDeliveryAddress) {
                    formData.append('oneOffDeliveryAddress', values.oneOffDeliveryAddress);
                }

                formData.append('reference', values.reference);
                if (values.deliveryDate)
                    formData.append('deliveryDate', values.deliveryDate);
                formData.append('uniqueId', values.uniqueId);
                formData.append('status', "PO Reviewed");

                values.purchaseOrderLineItems.forEach((purchaseOrderLineItemsItem, purchaseOrderLineItemsItemIdx) => {

                    formData.append(`purchaseOrderLineItems[${purchaseOrderLineItemsItemIdx}].description`, purchaseOrderLineItemsItem.description);
                    formData.append(`purchaseOrderLineItems[${purchaseOrderLineItemsItemIdx}].quantity`, purchaseOrderLineItemsItem.quantity);
                    formData.append(`purchaseOrderLineItems[${purchaseOrderLineItemsItemIdx}].unitAmount`, purchaseOrderLineItemsItem.unitAmount);
                    formData.append(`purchaseOrderLineItems[${purchaseOrderLineItemsItemIdx}].xeroAccounts`, purchaseOrderLineItemsItem.xeroAccounts.uniqueId);
                    formData.append(`purchaseOrderLineItems[${purchaseOrderLineItemsItemIdx}].xeroTaxTypes`, purchaseOrderLineItemsItem.xeroTaxTypes.uniqueId);
                    formData.append(`purchaseOrderLineItems[${purchaseOrderLineItemsItemIdx}].uniqueId`, purchaseOrderLineItemsItem.uniqueId);

                    if (purchaseOrderLineItemsItem.purchaseOrderLineItemTrackings.length !== 0) purchaseOrderLineItemsItem.purchaseOrderLineItemTrackings.forEach((purchaseOrderLineItemTrackingItem, purchaseOrderLineItemTrackingItemIdx) => {
                        formData.append(`purchaseOrderLineItems[${purchaseOrderLineItemsItemIdx}].purchaseOrderLineItemTrackings[${purchaseOrderLineItemTrackingItemIdx}].uniqueId`, purchaseOrderLineItemTrackingItem.uniqueId);
                        formData.append(`purchaseOrderLineItems[${purchaseOrderLineItemsItemIdx}].purchaseOrderLineItemTrackings[${purchaseOrderLineItemTrackingItemIdx}].xeroTrackingOptions`, purchaseOrderLineItemTrackingItem.xeroTrackingOptions.uniqueId);
                    })
                });
        //     }
        // }
        // else {
        //     formData.append('deliveryInstructions', values.deliveryInstructions || "");
        //     formData.append('isDefaultDeliveryAddress', values.isDefaultDeliveryAddress);
        //
        //     if (values.isDefaultDeliveryAddress === 'true')
        //         formData.append('existingDeliveryAddress', values.existingDeliveryAddress?.uniqueId || "");
        //     else
        //         formData.append('oneOffDeliveryAddress', values.oneOffDeliveryAddress || "");
        //
        //     formData.append('reference', values.reference || "");
        //     formData.append('deliveryDate', values.deliveryDate || "");
        //     formData.append('uniqueId', values.uniqueId);
        //     formData.append('status', values.status);
        //
        //     values.purchaseOrderLineItems.forEach((purchaseOrderLineItemsItem, purchaseOrderLineItemsItemIdx) => {
        //
        //         formData.append(`purchaseOrderLineItems[${purchaseOrderLineItemsItemIdx}].description`, purchaseOrderLineItemsItem.description || "");
        //         formData.append(`purchaseOrderLineItems[${purchaseOrderLineItemsItemIdx}].quantity`, purchaseOrderLineItemsItem.quantity || "");
        //         formData.append(`purchaseOrderLineItems[${purchaseOrderLineItemsItemIdx}].unitAmount`, purchaseOrderLineItemsItem.unitAmount || "");
        //         formData.append(`purchaseOrderLineItems[${purchaseOrderLineItemsItemIdx}].xeroAccounts`, purchaseOrderLineItemsItem.xeroAccounts?.uniqueId || "");
        //         formData.append(`purchaseOrderLineItems[${purchaseOrderLineItemsItemIdx}].xeroTaxTypes`, purchaseOrderLineItemsItem.xeroTaxTypes?.uniqueId || "");
        //         formData.append(`purchaseOrderLineItems[${purchaseOrderLineItemsItemIdx}].uniqueId`, purchaseOrderLineItemsItem.uniqueId);
        //
        //         if (purchaseOrderLineItemsItem.purchaseOrderLineItemTrackings.length !== 0) purchaseOrderLineItemsItem.purchaseOrderLineItemTrackings.forEach((purchaseOrderLineItemTrackingItem, purchaseOrderLineItemTrackingItemIdx) => {
        //             formData.append(`purchaseOrderLineItems[${purchaseOrderLineItemsItemIdx}].purchaseOrderLineItemTrackings[${purchaseOrderLineItemTrackingItemIdx}].uniqueId`, purchaseOrderLineItemTrackingItem?.uniqueId || "");
        //             formData.append(`purchaseOrderLineItems[${purchaseOrderLineItemsItemIdx}].purchaseOrderLineItemTrackings[${purchaseOrderLineItemTrackingItemIdx}].xeroTrackingOptions`, purchaseOrderLineItemTrackingItem.xeroTrackingOptions?.uniqueId || "");
        //         });
        //     });
        // }
        //
        // for (let pair of formData.entries()) {
        //     console.log(pair[0]+ ', ' + pair[1]);
        // }

        await onSubmit(formData);
    }

    let submit;

    // const setDialogActions = async (event, selectedIndex, form) => {
    //     switch (selectedIndex) {
    //         case 0:
    //             form.change("status", "PO Reviewed")
    //             break;
    //         case 1:
    //             form.change("status", "PO Review In Progress (Draft)")
    //             break;
    //         default:
    //             break;
    //     }
    //
    //     await submit(event);
    // }

    return (
        <Form
            onSubmit={onSubmitForm}
            decorators={[focusOnError]}
            mutators={{...arrayMutators}}
            validate={validate}
            initialValues={initialValues}
            render={({handleSubmit, submitting, form, pristine, values, errors, touched}) => {
                submit = handleSubmit
                return (
                    <Dialog
                        aria-labelledby="add-purchase-order-request-dialog-title"
                        onClose={onClose}
                        fullWidth
                        maxWidth={"lg"}
                        open={open}
                    >
                        <CustomDialogTitle
                            id="add-purchase-order-request-dialog-title"
                            onClose={onClose}
                            showTextContent
                        >
                            Add Purchase Order
                        </CustomDialogTitle>
                        <FormDialogContent dividers>
                            <form onSubmit={submit} noValidate>
                                <Grid container alignItems="flex-start" spacing={4}>
                                    {formFields(values, errors, touched).map((item, idx) => (
                                        <React.Fragment key={idx}>
                                            {item.field}
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </form>
                        </FormDialogContent>
                        <DialogActions>
                            <ActionButton
                                onClick={onClose}
                                type="button"
                                disabled={submitting}
                                color="primary"
                            >
                                Cancel
                            </ActionButton>
                            <ActionButton
                                type="button"
                                onClick={form.reset}
                                color="primary"
                                disabled={submitting || pristine}
                            >
                                Reset
                            </ActionButton>
                            <ActionButton
                                onClick={event => {
                                    const promise = submit(event);
                                    promise && promise.then(async() => {
                                        await sleep(300);
                                        form.reset();
                                    })
                                    return promise;
                                }}
                                type="submit"
                                disabled={submitting}
                                color="default"
                            >
                                Review and Amend
                            </ActionButton>
                            {/*<SplitButton*/}
                            {/*    handleClickParent={(event, selectedIndex) => setDialogActions(event, selectedIndex, form)}*/}
                            {/*    options={["Review and Amend", "Save as Draft"]}*/}
                            {/*    handleMenuItemClickParent={(event, selectedIndex) => setDialogActions(event, selectedIndex, form)}*/}
                            {/*    containerStyle={{ width: "unset" }}*/}
                            {/*    selectButtonStyle={{ border: "none", borderRight: "1px solid rgba(0, 0, 0, 0.23)" }}*/}
                            {/*    dropdownButtonStyle={{ border: "none" }}*/}
                            {/*    disabled={submitting}*/}
                            {/*/>*/}
                            <LoadingDiv>
                                <Loader
                                    loading={loading.type === loadingTypes.purchaseOrdersUpdatePurchaseOrder}
                                    size="small" />
                            </LoadingDiv>
                        </DialogActions>
                    </Dialog>
                )
            }}
        />
    )
}
export default NewRequestDialog;