import { size } from 'lodash';

export const modifyFiles = (existingFiles, files, type) => {
  let fileToUpload = {};
  for (let i = 0; i < files.length; i++) {
    const id = size(existingFiles) + i + 1;
    fileToUpload = {
      ...fileToUpload,
      [id]: {
        id,
        file: files[i],
        progress: 0,
        type : type
      },
    }
  }
  return fileToUpload;
} 

export const modifyClearFiles = (files) => {
  let fileToUpload = {};
  for (let i = 0; i < files.length; i++) {
    const id = files[i].id;
    fileToUpload = {
      ...fileToUpload,
      [id] : files[i]
    }
  }
  return fileToUpload;
} 

export const clearFiles = (existingFiles, type) => {
  let filesArray = Object.values({...existingFiles});
  let newArray = [];
  filesArray.forEach((file) => {
    if(file.type !== type){
      newArray.push(file);
    }
  });
  return newArray.length > 0 ? modifyClearFiles(newArray) : {};
} 