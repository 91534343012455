import axiosInstance from '../../assets/helpers/axiosInstance';
import { endLoading, startLoading } from "../loading/actions";
import * as loadingTypes from "../loading/types";
import {setError} from "../error/actions";
import * as errorTypes from "../error/types";

export const SET_ALL_PROFIT_CENTRES = "currencies/SET_ALL_PROFIT_CENTRES";

const setAllProfitCentres = allProfitCentres => ({
    type: SET_ALL_PROFIT_CENTRES,
    allProfitCentres,
});

export const getAllProfitCentres = () => async dispatch => {
    dispatch(startLoading(loadingTypes.profitCentresGetAllProfitCentres));
    try {
        const res = await axiosInstance.get(`/profitCentre/getAll`);
        dispatch(setAllProfitCentres(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.profitCentresGetAllProfitCentres, `${err.message ? err.message + " at " + errorTypes.profitCentresGetAllProfitCentres : "Something went wrong at " + errorTypes.profitCentresGetAllProfitCentres }`));
    } finally {
        dispatch(endLoading());
    }
};