import * as actionTypes from './actions';

const initialState = {
    allProfitCentres: []
};

const setAllProfitCentres = (state, { allProfitCentres }) => ({ ...state, allProfitCentres });

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ALL_PROFIT_CENTRES:
            return setAllProfitCentres(state, action);
        default:
            return state;
    }
};