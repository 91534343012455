import CustomDialogTitle from "../../../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import GetApp from "@material-ui/icons/GetApp";
import {formatAmount} from "../../../../../assets/helpers/globals";
import Dialog from "@material-ui/core/Dialog";
import styled, {css} from "styled-components";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import * as loadingTypes from "../../../../../store/loading/types";
import Loader from "../../../../../components/Loader/Loader";

const H3 = styled.h3`
  margin: 0;
`;

const P = styled.p`
  margin: 0;
  ${props => props.blue && css`color: RoyalBlue;`}
  ${props => props.small && css`font-size: small;`}
`;

const FormDialogContent = styled(DialogContent)`
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const DownloadText = styled.p`
  margin: 15px 0 0 0;
`;

const DownloadButton = styled(Button)`
  position: absolute;
  right: 0;
  margin-top: -5px;
  background: white;
  box-shadow: none;
`;

const DetailsDialog = ({
                           details,
                           onClose,
                           open,
                           downloadFile,
                           loading
                   }) => (
<Dialog
    aria-labelledby="other-purchase-request-details-dialog-title"
    onClose={onClose}
    fullWidth
    maxWidth={"sm"}
    open={open}
>
    <CustomDialogTitle
        id="other-purchase-request-details-dialog-title"
        onClose={onClose}
        showTextContent
    >
        Other Purchase Details
    </CustomDialogTitle>
    <FormDialogContent dividers>
        <ListItem>
            <ListItemText>
                <H3>Attachment</H3>
                {details?.purchase?.attachments.map(file => (
                    <React.Fragment key={`DOWNLOAD_FILE_${file.uniqueId}`}>
                        <DownloadText>- {file.name}
                            <DownloadButton onClick={() => downloadFile(file.uniqueId, file.name)}>
                                <Tooltip title="Download File">
                                    <GetApp color={"primary"}/>
                                </Tooltip>
                            </DownloadButton>
                        </DownloadText>
                    </React.Fragment>
                )) || "Not Specified"}
                <Loader loading={loading.type === loadingTypes.fileDownload} center />
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Response Submitted</H3>
                <P>{details.purchase?.requestDate || "Not specified"}</P>
            </ListItemText>
        </ListItem>

        <ListItem>
            <ListItemText>
                <H3>Last Response Date</H3>
                <P>{details.purchase?.lastResponseDate || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Supplier</H3>
                <P>{details.purchase?.vendor ? details.purchase?.vendor.vendorName : details.purchase?.vendorOther}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Purchase Description</H3>
                <P>{details.purchase?.description || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Quote Number</H3>
                <P>{details.purchase?.quoteNumber || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Purchase Type</H3>
                <P>{details?.productType || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Total Cost ({details.purchase?.xeroTaxOption.taxOptionText})</H3>
                <P>{formatAmount(details.purchase?.totalCost, details.purchase?.currency.name) || "Not specified"}</P>
                                {details.purchase?.exchangeRate && details.purchase?.currency.name !== "ZAR" ? <P blue small>{`~ 1 ${details.purchase?.currency.name} = ${details.purchase?.exchangeRate} ZAR`}</P> : ""}
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Purchase Requester</H3>
                <P>{details.purchase?.requestor?.userFullName || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Purchase Order Status</H3>
                <P>{details.purchase?.purchaseOrderStatus || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>New Quote Requested Reason</H3>
                <P>{details.purchase?.newQuoteRequestedComment || "Not specified"}</P>
            </ListItemText>
        </ListItem>
    </FormDialogContent>
</Dialog>
)

export default DetailsDialog;