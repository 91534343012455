import * as actionTypes from "./actions";

const initialState = {
    supportRequests: [],
};

const setSupportRequests = (state, { supportRequests }) => ({ ...state, supportRequests });

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SUPPORT_REQUESTS:
            return setSupportRequests(state, action);
        default:
            return state;
    }
};
