import * as actionTypes from './actions';

const initialState = {
    contacts: [],
    contactsError : null,
    contactsLoading : false,
    resources: [],
    resourcesError : null,
    resourcesLoading : false
};

const getUserDirectoryStart = (state) => ({ ...state, contactsLoading : true });
const getUserDirectorySuccess = (state, action) => (
    { ...state, contacts : action.contacts,contactsError : null,contactsLoading : false }
);
const getUserDirectoryFail = (state, action) => (
    { ...state, contacts : [],contactsError : action.error,contactsLoading : false }
);
const clearUserDirectory = (state) => ({ ...state, contacts : [] });
const getResourcesStart = (state) => ({ ...state, resourcesLoading : true });
const getResourcesSuccess = (state, action) => (
    { ...state, resources : action.resources,resourcesError : null,resourcesLoading : false }
);
const getResourcesFail = (state, action) => ({ ...state, resourcesError : action.error,resourcesLoading : false });
const clearResources = (state) => ({ ...state, resources : [] });

export default (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.GET_USER_DIRECTORY_START: return getUserDirectoryStart(state);
        case actionTypes.GET_USER_DIRECTORY_SUCCESS: return getUserDirectorySuccess(state, action);
        case actionTypes.GET_USER_DIRECTORY_FAIL: return getUserDirectoryFail(state, action);
        case actionTypes.CLEAR_USER_DIRECTORY: return clearUserDirectory(state);
        case actionTypes.GET_RESOURCES_START: return getResourcesStart(state);
        case actionTypes.GET_RESOURCES_SUCCESS: return getResourcesSuccess(state, action);
        case actionTypes.GET_RESOURCES_FAIL: return getResourcesFail(state, action);
        case actionTypes.CLEAR_RESOURCES: return clearResources(state);
        default: return state;
    }
};
