import * as actionTypes from './actions';
import { modifyFiles, clearFiles } from '../../assets/helpers/utils';

const initialState = { fileProgress: {}, uploadFileError : null, };

const clearUploadFiles = (state, action) => (
    {
        ...state,
        fileProgress: { ...clearFiles(state.fileProgress, action.payload) },
        uploadFileError : null
    }
);

const setUploadFile = (state, action) => (
    {
        ...state,
        fileProgress: {
            ...state.fileProgress,
            ...modifyFiles(state.fileProgress, action.payload.files, action.payload.originType)
        },
        uploadFileError : null
    }
);

const setUploadProgress = (state, action) => (
    {
        ...state,
        fileProgress: {
            ...state.fileProgress,
            [action.payload.id]: {
                ...state.fileProgress[action.payload.id],
                progress: action.payload.progress,
            },
        }
    }
);

const uploadFileSuccess = (state, action) => (
    {
        ...state,
        fileProgress: {
            ...state.fileProgress,
            [action.payload]: {
                ...state.fileProgress[action.payload],
                status: 1,
            }
        }
    }
);

const uploadFileFail = (state, action) => (
    {
        ...state,
        fileProgress: {
            ...state.fileProgress,
            [action.payload]: { ...state.fileProgress[action.payload], status: 0, progress: 0, }
        },
        uploadFileError : action.error
    }
);

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_UPLOAD_FILES: return clearUploadFiles(state, action);
        case actionTypes.SET_UPLOAD_FILE: return setUploadFile(state, action);
        case actionTypes.SET_UPLOAD_PROGRESS: return setUploadProgress(state, action);
        case actionTypes.UPLOAD_FILE_SUCCESS: return uploadFileSuccess(state, action);
        case actionTypes.UPLOAD_FILE_FAIL: return uploadFileFail(state, action);
        default: return state;
    }
};
