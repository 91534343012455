import React from "react";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../../../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {formatShortDate} from "../../../../../assets/helpers/globals";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Loader from "../../../../../components/Loader/Loader";
import * as loadingTypes from "../../../../../store/loading/types";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import {Form} from "react-final-form";
import {focusOnError} from "../../../../../assets/helpers/finalFormHelpers";
import arrayMutators from 'final-form-arrays';
import {TextField} from "mui-rff";

const LoadingDiv = styled.div`
  position: absolute;
  bottom: 11px;
  right: 36px;
`;

const H3 = styled.h3`
  margin: 0;
`;

const ActionButton = styled(Button)`
  margin: 0 5px;
`;

const FormInput = styled.form`
  width: 100%;
`;

const FormDialogContent = styled(DialogContent)`
  padding: 10px 10px;
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

// const StatusRadios = styled(Radios)`
//   ${props => props.data[0]?.label === "Approved" && css`&.Mui-checked { color: Green }`}
//   ${props => props.data[0]?.label === "Rejected" && css`&.Mui-checked { color: Red }`}
// `;

// const P = styled.p`
//   margin: 0;
//   ${props => props.blue && css`color: RoyalBlue;`}
//   ${props => props.small && css`font-size: small;`}
// `;
//
// const Div = styled.div`
//   padding: 16px;
// `;
//
// const Span = styled.span`
//   color: #f57c00;
//
//   ${props => props.status === "Approved" && css`
//     color: #388e3c;
//   `}
//   ${props => props.status === "Rejected" && css`
//     color: #d32f2f;
//   `}
// `;

const ResponseDialog = ({
                        details,
                        onClose,
                        open,
                        onSubmit,
                        loading,
                   }) => {
    const formFields = [
        {
            externalLabel: "Comment",
            size: 12,
            field: (
                <TextField
                    name="newQuoteRequestedComment"
                    margin="none"
                    required
                    placeholder={"Comment"}
                    multiline
                />
            )
        },
    ]

    const validate = values => {
        const errors = {}

        if (!values.newQuoteRequestedComment)
            errors.newQuoteRequestedComment = "Required"

        return errors
    }

    const onSubmitForm = async values => {
        let modifiedPayload = {
            ...details,
            purchase: {
                ...details.purchase,
                ...values,
                purchaseOrderStatus: "New Quote Requested",
                lastResponseDate: formatShortDate(new Date())
            }
        }

        await onSubmit(modifiedPayload);
    }

    let submit;

    return <Form
        onSubmit={onSubmitForm}
        validate={validate}
        decorators={[focusOnError]}
        mutators={{...arrayMutators}}
        initialValues={{newQuoteRequestedComment: details.purchase?.newQuoteRequestedComment}}
        render={({handleSubmit, submitting, pristine}) => {
            submit = handleSubmit
            return (
                <Dialog
                    aria-labelledby="project-purchase-request-details-dialog-title"
                    onClose={onClose}
                    fullWidth
                    maxWidth={"sm"}
                    open={open}
                >
                    <CustomDialogTitle
                        id="project-purchase-request-details-dialog-title"
                        onClose={onClose}
                        showTextContent
                    >
                        Request New Quote
                    </CustomDialogTitle>
                    <FormDialogContent dividers>
                        <ListItem>
                            <FormInput noValidate>
                                <Grid container alignItems="flex-start" spacing={1}>
                                    {formFields.map((item, idx) => (
                                        item.field ? <Grid item xs={item.size} key={idx}>
                                            <ListItemText>
                                                <H3>{item.externalLabel}</H3>
                                                {item.field}
                                            </ListItemText>
                                        </Grid> :
                                        <React.Fragment key={idx}/>
                                    ))}
                                </Grid>
                            </FormInput>
                        </ListItem>
                    </FormDialogContent>

                    <DialogActions>
                        <ActionButton
                            onClick={onClose}
                            type="button"
                            disabled={submitting}
                            color="primary"
                        >
                            Cancel
                        </ActionButton>
                        <ActionButton
                            onClick={event => submit(event)}
                            type="submit"
                            disabled={submitting || pristine}
                            color="default"
                        >
                            Submit
                        </ActionButton>
                        <LoadingDiv>
                            <Loader loading={loading.type === loadingTypes.purchasesUpdatePurchaseProjectRequestStatus} size="small"/>
                        </LoadingDiv>
                    </DialogActions>
                </Dialog>
            )
        }}
    />
}

export default ResponseDialog;