import CustomDialogTitle from "../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import styled from "styled-components";
import DialogContent from "@material-ui/core/DialogContent";

const H3 = styled.h3`
  margin: 0;
`;

const P = styled.p`
  margin: 0;
`;

const FormDialogContent = styled(DialogContent)`
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const RequesterView = ({
                           details,
                           onClose,
                           open,
                       }) =>
    <Dialog
        aria-labelledby="support-request-details-dialog-title"
        onClose={onClose}
        fullWidth
        maxWidth={"sm"}
        open={open}
    >
        <CustomDialogTitle
            id="support-request-details-dialog-title"
            onClose={onClose}
            showTextContent
        >
            Support Request Details
        </CustomDialogTitle>
        <FormDialogContent dividers>
            <ListItem>
                <ListItemText>
                    <H3>Category</H3>
                    <P>{details.category || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Location</H3>
                    <P>{details.location || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Description</H3>
                    <P>{details.description || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Additional Info</H3>
                    <P>{details.additionalInfo || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Submitted Date</H3>
                    <P>{details.requestSubmittedDate || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Response Date</H3>
                    <P>{details.responseDate || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Support Requestor</H3>
                    <P>{details.requestor?.userFullName || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Status</H3>
                    <P>{details.status}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Comment</H3>
                    <P>{details.comment || "Not specified"}</P>
                </ListItemText>
            </ListItem>
        </FormDialogContent>
    </Dialog>

export default RequesterView;