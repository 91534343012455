import React from 'react';
import styles from './DetailsListItem.module.css';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from "@material-ui/core/Icon";
import SvgIcon from '@material-ui/core/SvgIcon';
import Button from '@material-ui/core/Button';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import Badge from '@material-ui/core/Badge';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

const DetailsListItem = (props) => {

    const { item } = props;

    const getBadgeContent = (responseStatus) => {
        if (responseStatus === 'accepted') {
            return <CheckIcon className={styles.GuestAttendingIcon} />;
        }
        else if (responseStatus === 'declined') {
            return <CloseIcon className={styles.GuestAttendingIcon} />;
        }
        return null;
    };

    let listItem = null;

    switch (item.itemType) {
        case 'custom':
            listItem = null;
            break;
        case 'link':
            listItem = item.show ? <ListItem className={styles.ListItem}>
                {item.icon ? <ListItemIcon>
                    {item.iconType && item.iconType === "custom" ? <SvgIcon component={item.icon} viewBox='0 0 48 48' /> : typeof (item.icon) === "string" ? <Icon>{item.icon}</Icon> : <item.icon />}
                </ListItemIcon> : null}
                <ListItemText
                    primary={
                        <Button
                            href={item.href}
                            {...item.buttonConfig}
                            className={item.button ? styles.Button : styles.Link}>
                            {item.buttonLabel}
                        </Button>
                    }
                    secondary={item.secondaryText}
                />
            </ListItem> : null;
            break;
        case 'listItemButton':
            listItem = item.show ?
                <ListItem className={styles.ListItem}
                    {...item.buttonConfig}
                    href={item.href}>
                    {item.icon ? <ListItemIcon>
                        {item.iconType && item.iconType === "custom" ? <SvgIcon component={item.icon} viewBox='0 0 48 48' /> : typeof (item.icon) === "string" ? <Icon>{item.icon}</Icon> : <item.icon />}
                    </ListItemIcon> : null}

                    <ListItemText primary={item.primaryText} />
                </ListItem> : null;
            break;
        case 'attendeeList':
            listItem = item.show && item.attendees && item.attendees.length > 0 ?
                <ListItem className={clsx(styles.ListItem, styles.GuestListItemWrapper)}>
                    {item.icon ? <ListItemIcon>
                        {item.iconType && item.iconType === "custom" ? <SvgIcon component={item.icon} viewBox='0 0 48 48' /> : typeof (item.icon) === "string" ? <Icon>{item.icon}</Icon> : <item.icon />}
                    </ListItemIcon> : null}

                    <ListItemText
                        primary={
                            <div className={styles.AttendeeAccordion}>
                                <span>{item.attendees.length === 1 ? item.attendees.length + ' attendee' : item.attendees.length + ' attendees'}</span>
                                <IconButton size={'small'} onClick={item.onOpen} aria-label="open-collapse">
                                    {item.open ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                            </div>
                        }
                        secondary={item.secondaryText}
                    />
                    <div className={styles.FlexWrap}></div>
                    <Collapse in={item.open} timeout="auto" unmountOnExit>
                        <List>
                            {item.attendees.map(obj => {
                                return (
                                    <ListItem key={obj.email}>
                                        <ListItemIcon>
                                            <Badge
                                                className={styles.GuestAttendingBadge}
                                                color='primary'
                                                overlap='circle'
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                badgeContent={getBadgeContent(obj.responseStatus)}
                                                {...item.badgeConfig}>
                                                <ListItemAvatar>
                                                    <Avatar className={styles.AttendeeIcon}>
                                                        <PersonIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                            </Badge>
                                        </ListItemIcon>

                                        <ListItemText
                                            primary={obj.displayName ? obj.displayName : obj.email}
                                            secondary={obj.organizer ? "organiser" : null} />
                                    </ListItem>
                                );
                            })}  </List>
                    </Collapse>
                </ListItem> : null;
            break;
        default:
            listItem = item.show ? <ListItem className={styles.ListItem}>
                {item.icon ? <ListItemIcon>
                    {item.iconType && item.iconType === "custom" ? <SvgIcon component={item.icon} viewBox='0 0 48 48' /> : typeof (item.icon) === "string" ? <Icon>{item.icon}</Icon> : <item.icon />}
                </ListItemIcon> : null}

                <ListItemText
                    primary={item.primaryText}
                    secondary={item.secondaryText}
                />
            </ListItem> : null;
    }

    return (listItem);
};

export default DetailsListItem;