import React, { Component, Fragment } from "react";
import BoardroomCalendar from "../../../components/Calendars/BoardroomCalendar/BoardroomCalendar";
import ConfirmationDialog from "../../../components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import BoardroomRecurrenceUpdate from "../../../components/Dialogs/Boardrooms/BoardroomRecurrenceUpdate/BoardroomRecurrenceUpdate";
import NotesIcon from "@material-ui/icons/Notes";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import RepeatIcon from "@material-ui/icons/Repeat";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import PeopleIcon from "@material-ui/icons/People";
import CallIcon from "@material-ui/icons/Call";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import DetailsDialog from "../../../components/Dialogs/DetailsDialog/DetailsDialog";
import InputFormDialog from "../../../components/Dialogs/InputFormDialog/InputFormDialog";
import moment from "moment";
import { connect } from "react-redux";
import { removeProtocolFromString } from "../../../assets/helpers/globals";
import * as InputFormHelper from "../../../assets/helpers/forms/inputForm";
import * as EventRecurrenceHelper from "../../../assets/helpers/forms/eventRecurrence";
import * as DetailDialogHelper from "../../../assets/helpers/dialogs/detailsDialog";
import * as BoardroomCalendarHelper from "../../../assets/helpers/calendars/boardroom";
import axios from "axios";
import {
	createBoardroomEvent,
	deleteBoardroomEvent,
	getBoardroomEvents,
	respondToBoardroomEvent,
	updateBoardroomEvent,
} from "../../../store/boardrooms/actions";
import {
	clearUserDirectory,
	getResources,
	getUserDirectory,
} from "../../../store/global/actions";
import {getCurrentUser} from "../../../store/user/actions";
import GoogleMeetIcon from "../../../components/Icons/GoogleMeetIcon";

class Boardrooms extends Component {
	state = {
		bookingForm: {
			fields: [
				{
					id: 1,
					property: "summary",
					elementType: "text",
					required: true,
					gridColumnSize: 12,
					value: "",
					elementConfig: {
						autoFocus: true,
						fullWidth: true,
						placeholder: "Add title",
						onChange: event => this.handleChangeFormInputs(event, 1),
					},
					error: false,
					helperText: "",
				},
				{
					id: 2,
					elementType: "dateTime",
					required: true,
					multiField: true,
					multiFieldReference: 3,
					dateChangeMaster: true,
					dateChangeReference: 3,
					gridColumnSize: 6,
					icon: AccessTimeIcon,
					label: "Start",
					property: "start",
					secondaryProperty: "dateTime",
					value: moment().format(),
					elementConfig: {
						disablePast: true,
						showTodayButton: true,
						fullWidth: true,
						onChange: event => this.handleChangeFormInputs(event, 2),
					},
					error: false,
					helperText: "",
				},
				{
					id: 3,
					elementType: "dateTime",
					required: true,
					multiField: true,
					multiFieldReference: null,
					dateChangeReference: 2,
					dateChangeValue: 1,
					gridColumnSize: 6,
					icon: AccessTimeIcon,
					label: "End",
					property: "end",
					secondaryProperty: "dateTime",
					value: moment().add(1, "hours").format(),
					elementConfig: {
						disablePast: true,
						showTodayButton: true,
						fullWidth: true,
						onChange: event => this.handleChangeFormInputs(event, 3),
					},
					error: false,
					helperText: "",
				},
				{
					id: 4,
					elementType: "eventRecurrence",
					property: "recurrence",
					value: null,
					icon: RepeatIcon,
					open: false,
					elementConfig: {
						handleOpen: () => this.handleToggleRecurringDialog(4, "open"),
						handleClose: () => this.handleToggleRecurringDialog(4, "close"),
						handleClear: () => this.handleClearRecurringDialog(4),
						handleChange: (event, date = false, name = "") =>
							this.handleChangeRecurringInputs(event, date, name, 4),
						handleToggleWeekRecurringDays: val =>
							this.handleToggleWeekRecurringDays(val, 4),
						startDate: () => this.getStartDateValue(2, "bookingForm"),
						recurring: {},
						recurringErrors: {},
						recurringErrorMessages: {},
						handleSubmit: () => this.handleAddRecurrenceDetails(4),
					},
					error: false,
					helperText: "",
				},
				{
					id: 5,
					property: "attendees",
					elementType: "guestsAutoComplete",
					uncontrolled: true,
					loading: false,
					value: null,
					icon: PeopleIcon,
					elementConfig: {
						multiple: true,
						limitTags: 3,
						freeSolo: true,
						inputValue: "",
						openOnFocus: true,
						onChange: (event, value, reason) =>
							this.handleGuestChange(event, value, reason, 5),
						onInputChange: (event, value, reason) =>
							this.handleChangeGuestInput(event, value, reason, 5),
					},
					elementInputConfig: {
						fullWidth: true,
						name: "newGuest",
						placeholder:
							"Add guests (Type and press enter to add external guests)",
					},
					error: false,
					helperText: "",
				},
				{
					id: 6,
					property: "googleMeet",
					elementType: "checkbox",
					gridColumnSize: 12,
					value: false,
					iconType: "custom",
					icon: GoogleMeetIcon,
					label: "Google Meet video conferencing",
					elementConfig: {
						checked: false,
						onChange: event =>
							this.handleChangeFormInputs(event, 6, "bookingForm"),
						name: "googleMeet",
						color: "primary",
					},
					error: false,
					helperText: "",
				},
				{
					id: 7,
					resourceProperty: "resources",
					property: "resourceId",
					inputProperty: "title",
					elementType: "resourcesAutoComplete",
					useResources: true,
					uncontrolled: true,
					required: true,
					icon: MeetingRoomIcon,
					elementConfig: {
						inputValue: "",
						onChange: (event, value, reason) =>
							this.handleResourceChange(event, value, reason, 7),
						getOptionLabel: option => (option.title ? option.title : ""),
						getOptionSelected: (option, value) =>
							option.resourceId === value.resourceId,
						onInputChange: (event, value, reason) =>
							this.handleResourceInputChange(event, value, reason, 7),
					},
					elementInputConfig: {
						fullWidth: true,
						placeholder: "Add boardroom",
					},
					error: false,
					helperText: "",
				},
				{
					id: 8,
					property: "location",
					elementType: "googleLocation",
					icon: LocationOnIcon,
					gridColumnSize: 12,
					value: null,
					elementConfig: {
						placeholder: "Add location",
						googleLocationObj: null,
						setGoogleLocationValue: value =>
							this.handleGoogleLocationChange(value, 8),
					},
					error: false,
					helperText: "",
				},
				{
					id: 9,
					property: "description",
					elementType: "richEditor",
					icon: NotesIcon,
					gridColumnSize: 12,
					value: "",
					elementConfig: {
						initialValue: "",
						name: "description",
						placeholder: "Add description",
						handleRichTextEditorBlur: (event, editorContents) =>
							this.handleRichTextEditorBlur(event, editorContents, 9),
					},
					error: false,
					helperText: "",
				},
			],
		},
		bookingDetails: {
			loaded: false,
			titleProperty: 1,
			onEdit: id => this.handleEditEvent(id),
			onDelete: id => this.handleDeleteEvent(id),
			onClose: () => this.handleCloseEventDetails(),
			topParagraphItems: [
				{
					text: "",
					type: "dateRange",
					propertyChain: 2,
					property: [
						["start", "dateTime"],
						["end", "dateTime"],
					],
					show: false,
				},
				{
					text: "",
					type: "text",
					property: "recurrence",
					formatMethod: arr =>
						EventRecurrenceHelper.convertRecurringObjToString(arr),
					show: false,
				},
			],
			bottomBarActions: {
				description: "Going?",
				actions: [
					{
						id: 1,
						loading: false,
						handler: () => this.respondAPI(1),
						label: "Yes",
						value: "accepted",
						config: {
							color: "secondary",
						},
					},
					{
						id: 2,
						loading: false,
						handler: () => this.respondAPI(2),
						label: "No",
						value: "declined",
						config: {
							color: "secondary",
						},
					},
					{
						id: 3,
						loading: false,
						handler: () => this.respondAPI(3),
						label: "Maybe",
						value: "tentative",
						config: {
							color: "secondary",
						},
					},
				],
			},
			listItems: [
				{
					id: 1,
					show: false,
					itemType: "title",
					property: "summary",
				},
				{
					id: 2,
					show: false,
					itemType: "link",
					icon: GoogleMeetIcon,
					iconType: "custom",
					buttonLabel: "Join with Google Meet",
					secondaryTextProperty: "hangoutLink",
					secondaryTextFormatMethod: val => removeProtocolFromString(val),
					property: "hangoutLink",
					href: "",
					button: true,
					buttonConfig: {
						variant: "contained",
						color: "primary",
						target: "_blank",
						rel: "noopener noreferrer",
					},
				},
				{
					id: 3,
					show: false,
					itemType: "link",
					icon: CallIcon,
					buttonLabel: "Join by phone",
					secondaryTextProperty: "hangoutLink",
					secondaryTextFormatMethod: val =>
						DetailDialogHelper.generateMeetPhoneLink(val, "secondary"),
					property: "conferenceData",
					href: "",
					hrefFormatMethod: val =>
						DetailDialogHelper.generateMeetPhoneLink(val, "primary"),
					buttonConfig: {
						color: "primary",
						target: "_blank",
						rel: "noopener noreferrer",
					},
				},
				{
					id: 4,
					show: false,
					itemType: "listItemButton",
					icon: OpenInNewIcon,
					primaryText: "More phone numbers",
					property: "conferenceData",
					href: "",
					hrefFormatMethod: val =>
						DetailDialogHelper.generateMeetMorePhoneNumbers(val),
					buttonConfig: {
						button: true,
						component: "a",
						target: "_blank",
						rel: "noopener noreferrer",
					},
				},
				{
					id: 5,
					show: false,
					itemType: "attendeeList",
					icon: PeopleIcon,
					secondaryTextFormatMethod: val =>
						DetailDialogHelper.generateAttendeesSummary(val),
					property: "attendees",
					openOnLength: 2,
					open: false,
					onOpen: () => this.handleAttendeesToggle(5),
					attendees: [],
				},
				{
					id: 6,
					show: false,
					itemType: "default",
					icon: MeetingRoomIcon,
					primaryText: "",
					propertyChain: 2,
					property: ["resources", "title"],
				},
				{
					id: 7,
					show: false,
					itemType: "default",
					icon: LocationOnIcon,
					primaryText: "",
					property: "location",
				},
				{
					id: 8,
					show: false,
					itemType: "default",
					icon: NotesIcon,
					primaryText: "",
					property: "description",
				},
			],
		},
		eventDetailsOpen: false,
		eventAddEditOpen: false,
		eventDeleteConfirmation: false,
		selectedEvent: null,
		isEdit: false,
		initialResourceEdit: null,
		timeMin: "",
		timeMax: "",
		cancelToken: null,
		recurrenceUpdateConfirmation: false,
		recurrenceConfirmationType: "",
		currentCalendarView: "day",
		recurringRadio: null,
	};

	getStartDateValue = id => {
		return InputFormHelper.getStartDateValue(id, this.state.bookingForm);
	};

	handleSelectEvent = event => {
		this.setState(
			{
				bookingDetails: DetailDialogHelper.handleDetails(
					event,
					this.state.bookingDetails
				),
				selectedEvent: event,
				currentBookingId: event.unique_id,
			},
			() => {
				this.toggleDialog("eventDetailsOpen");
			}
		);
	};

	handleSelectOpenSlot = slotInfo => {
		this.setState(
			{
				bookingForm: BoardroomCalendarHelper.handleSelectOpenSlot(
					slotInfo,
					this.props.resources,
					this.state.bookingForm
				),
			},
			() => {
				this.toggleDialog("eventAddEditOpen");
			}
		);
	};

	handleNavigate = date => {
		this.setState({ currentCalendarDate: date });
	};

	handleViewChange = view => {
		this.setState({ currentCalendarView: view });
	};

	handleDrillDown = (date, view) => {
		this.handleRangeChange([date], view);
		if (view) {
			this.handleViewChange(view);
		}
		this.handleNavigate(date);
	};

	handleRangeChange = (dates, newView) => {
		this.cancelRequestAndClearTimer();

		let timeMin = "";
		let timeMax = "";

		if (newView === "day") {
			timeMin = moment(dates[0])
				.set("hour", 0)
				.set("minute", 0)
				.set("second", 0)
				.format();
			timeMax = moment(dates[0])
				.set("hour", 0)
				.set("minute", 0)
				.set("second", 0)
				.add(1, "days")
				.format();
		} else if (newView === "work_week") {
			timeMin = moment(dates[0])
				.set("hour", 0)
				.set("minute", 0)
				.set("second", 0)
				.format();
			timeMax = moment(dates[dates.length - 1])
				.set("hour", 0)
				.set("minute", 0)
				.set("second", 0)
				.add(1, "days")
				.format();
		} else if (newView === "month") {
			timeMin = moment(dates.start)
				.set("hour", 0)
				.set("minute", 0)
				.set("second", 0)
				.format();
			timeMax = moment(dates.end)
				.set("hour", 0)
				.set("minute", 0)
				.set("second", 0)
				.format();
		} else {
			const view = this.state.currentCalendarView;

			if (view === "day") {
				timeMin = moment(dates[0])
					.set("hour", 0)
					.set("minute", 0)
					.set("second", 0)
					.format();
				timeMax = moment(dates[0])
					.set("hour", 0)
					.set("minute", 0)
					.set("second", 0)
					.add(1, "days")
					.format();
			} else if (view === "work_week") {
				timeMin = moment(dates[0])
					.set("hour", 0)
					.set("minute", 0)
					.set("second", 0)
					.format();
				timeMax = moment(dates[dates.length - 1])
					.set("hour", 0)
					.set("minute", 0)
					.set("second", 0)
					.add(1, "days")
					.format();
			} else if (view === "month") {
				timeMin = moment(dates.start)
					.set("hour", 0)
					.set("minute", 0)
					.set("second", 0)
					.format();
				timeMax = moment(dates.end)
					.set("hour", 0)
					.set("minute", 0)
					.set("second", 0)
					.format();
			}
		}

		const source = this.createCancelToken();

		if (newView) {
			this.setState(
				{
					cancelToken: source,
					timeMin: timeMin,
					timeMax: timeMax,
				},
				() => {
					this.eventsAPI(timeMin, timeMax, source);
				}
			);
		} else {
			this.setState(
				{
					cancelToken: source,
					timeMin: timeMin,
					timeMax: timeMax,
				},
				() => {
					this.eventsAPI(timeMin, timeMax, source);
				}
			);
		}
	};

	toggleDialog = stateName => {
		this.setState(prevState => ({
			[stateName]: !prevState[stateName],
		}));
	};

	handleCloseEventDetails = () => {
		this.setState(
			{
				selectedEvent: null,
				bookingDetails: DetailDialogHelper.handleClearDetailsState(
					this.state.bookingDetails
				),
			},
			() => {
				this.toggleDialog("eventDetailsOpen");
			}
		);
	};

	handleCloseEventAddEdit = () => {
		if (this.state.isEdit) {
			this.setState(
				{
					isEdit: false,
					bookingForm: InputFormHelper.handleClearFormState(
						this.state.bookingForm
					),
				},
				() => {
					this.toggleDialog("eventAddEditOpen");
				}
			);
		} else {
			this.setState(
				{
					isEdit: false,
					bookingForm: InputFormHelper.handleClearFormState(
						this.state.bookingForm
					),
				},
				() => {
					this.toggleDialog("eventAddEditOpen");
				}
			);
		}
	};

	handleEditEvent = id => {
		if (id === this.state.selectedEvent.id) {
			let editFormObj = InputFormHelper.handleEditEvent(
				this.state.bookingForm,
				this.state.selectedEvent
			);
			this.setState(
				{
					isEdit: true,
					bookingForm: editFormObj.updatedForm,
					initialResourceEdit: editFormObj.initialResourceEdit,
				},
				() => {
					this.toggleDialog("eventAddEditOpen");
				}
			);
		}
	};

	handleDeleteEvent = id => {
		if (id === this.state.selectedEvent.id) {
			if (
				this.state.selectedEvent.recurrence &&
				this.state.selectedEvent.recurrence.length > 0
			) {
				this.handleOpenRecurrenceConfirmation("delete");
			} else {
				this.handleOpenEventDeleteConfirmation();
			}
		}
	};

	handleOpenEventDeleteConfirmation = () => {
		this.toggleDialog("eventDeleteConfirmation");
	};

	handleCloseEventDeleteConfirmation = () => {
		this.toggleDialog("eventDeleteConfirmation");
	};

	handleOpenRecurrenceConfirmation = type => {
		this.setState(
			{
				recurringRadio: "single",
				recurrenceConfirmationType: type,
			},
			() => {
				this.toggleDialog("recurrenceUpdateConfirmation");
			}
		);
	};

	handleCloseRecurrenceConfirmation = () => {
		this.setState(
			{
				recurrenceConfirmationType: "",
			},
			() => {
				this.toggleDialog("recurrenceUpdateConfirmation");
			}
		);
	};

	handleRecurringConfirmationChange = event => {
		this.setState({
			recurringRadio: event.target.value,
		});
	};

	handleAttendeesToggle = id => {
		this.setState({
			bookingDetails: DetailDialogHelper.handleAttendeesToggle(
				id,
				this.state.bookingDetails
			),
		});
	};

	handleConfirmDelete = ans => {
		let payload = {
			id: this.state.selectedEvent.id,
			resourceId: this.state.selectedEvent.resourceId,
			recurringEventId: "",
			type: "",
		};

		if (ans) {
			this.deleteAPI(payload);
		} else {
			this.handleCloseEventDeleteConfirmation();
		}
	};

	handleToggleRecurringDialog = (formElementId, type) => {
		this.setState({
			bookingForm: EventRecurrenceHelper.handleToggleRecurringDialog(
				formElementId,
				type,
				this.state.bookingForm
			),
		});
	};

	handleClearRecurringDialog = formElementId => {
		this.setState({
			bookingForm: EventRecurrenceHelper.handleClearRecurringDialog(
				formElementId,
				this.state.bookingForm
			),
		});
	};

	handleGuestChange = (event, values, reasons, formElementId) => {
		this.setState({
			bookingForm: InputFormHelper.handleGuestChange(
				values,
				reasons,
				formElementId,
				this.state.bookingForm
			),
		});
	};

	handleCreateAttendeeObject = (email, formElementIndex) => {
		this.setState({
			bookingForm: InputFormHelper.handleCreateAttendeeObject(
				email,
				formElementIndex,
				this.state.bookingForm
			),
		});
	};

	handleChangeGuestInput = (event, value, reason, formElementId) => {
		this.setState({
			bookingForm: InputFormHelper.handleChangeGuestInput(
				value,
				reason,
				formElementId,
				this.state.bookingForm
			),
		});
	};

	handleResourceChange = (event, value, reason, formElementId) => {
		this.setState({
			bookingForm: InputFormHelper.handleResourceChange(
				value,
				reason,
				formElementId,
				this.state.bookingForm
			),
		});
	};

	handleResourceInputChange = (event, value, reason, formElementId) => {
		this.setState({
			bookingForm: InputFormHelper.handleResourceInputChange(
				event,
				value,
				reason,
				formElementId,
				this.state.bookingForm,
				this.props.resources
			),
		});
	};

	handleGoogleLocationChange = (value, formElementId) => {
		this.setState({
			bookingForm: InputFormHelper.handleGoogleLocationChange(
				value,
				formElementId,
				this.state.bookingForm
			),
		});
	};

	handleRichTextEditorBlur = (event, editorContents, formElementId) => {
		this.setState({
			bookingForm: InputFormHelper.handleRichTextEditorBlur(
				editorContents,
				formElementId,
				this.state.bookingForm
			),
		});
	};

	handleToggleWeekRecurringDays = (val, formElementId) => {
		this.setState({
			bookingForm: EventRecurrenceHelper.handleToggleWeekRecurringDays(
				val,
				formElementId,
				this.state.bookingForm
			),
		});
	};

	handleChangeRecurringInputs = (
		event,
		date = false,
		name = "",
		formElementId
	) => {
		this.setState({
			bookingForm: EventRecurrenceHelper.handleChangeRecurringInputs(
				event,
				(date = false),
				(name = ""),
				formElementId,
				this.state.bookingForm
			),
		});
	};

	handleAddRecurrenceDetails = formElementId => {
		this.setState({
			bookingForm: EventRecurrenceHelper.handleAddRecurrenceDetails(
				formElementId,
				this.state.bookingForm
			),
		});
	};

	handleChangeFormInputs = (event, formElementId) => {
		this.setState({
			bookingForm: InputFormHelper.handleChangeFormInputs(
				event,
				formElementId,
				this.state.bookingForm
			),
		});
	};

	buildRequestPayload = () => {
		let returnObj = {};
		returnObj = InputFormHelper.buildRequestPayload(this.state.bookingForm);
		if (this.state.isEdit) {
			returnObj.id = this.state.selectedEvent.id;
		}
		return returnObj;
	};

	handleRecurrenceConfirmation = () => {
		const type = this.state.recurrenceConfirmationType;
		if (type === "delete") {
			let payload = {
				id: this.state.selectedEvent.id,
				resourceId: this.state.selectedEvent.resourceId,
				recurrence: true,
				type: this.state.recurringRadio,
				recurringEventId: this.state.selectedEvent.recurringEventId,
			};

			this.props.onDeleteBoardroomEvent(payload).then(() => {
				this.handleCloseEventDetails();
				this.handleCloseRecurrenceConfirmation();
				this.updateEvents();
			});
		} else if (type === "edit") {
			let payload = {
				data: this.buildRequestPayload(),
				param: this.state.initialResourceEdit,
				type: this.state.recurringRadio,
				recurrence: true,
			};
			payload.data.recurringEventId = this.state.selectedEvent.recurringEventId;

			this.props.onUpdateBoardroomEvent(payload).then(() => {
				this.handleCloseRecurrenceConfirmation();
				this.handleCloseEventAddEdit();
				this.handleCloseEventDetails();
				this.updateEvents();
			});
		} else if (type === "response") {
			const payload = {
				eventId: this.state.selectedEvent.id,
				response: this.state.currentResponseAnswer,
				resourceId: this.state.selectedEvent.resourceId,
				recurringEventId: this.state.selectedEvent.recurringEventId,
				type: this.state.recurringRadio,
				recurrence: true,
			};

			this.props.onRespondToBoardroomEvent(payload).then(() => {
				this.handleCloseRecurrenceConfirmation();
				this.handleCloseEventDetails();
				this.updateEvents();
			});
		}
	};

	createAPI = () => {
		let updatedForm = { ...this.state.bookingForm };
		let validateObj = InputFormHelper.validateForm(updatedForm);
		this.setState(
			{
				bookingForm: validateObj.updatedForm,
			},
			() => {
				if (validateObj.valid) {
					const payload = {
						data: this.buildRequestPayload(),
					};
					this.props.onCreateBoardroomEvent(payload).then(() => {
						this.handleCloseEventAddEdit();
						this.handleCloseEventDetails();
						this.updateEvents();
					});
				}
			}
		);
	};

	editAPI = () => {
		let updatedForm = { ...this.state.bookingForm };
		let validateObj = InputFormHelper.validateForm(updatedForm);
		this.setState(
			{
				bookingForm: validateObj.updatedForm,
			},
			() => {
				if (validateObj.valid) {
					if (
						this.state.selectedEvent.recurrence &&
						this.state.selectedEvent.recurrence.length > 0
					) {
						this.handleOpenRecurrenceConfirmation("edit");
					} else {
						let payload = {
							data: this.buildRequestPayload("bookingForm"),
							param: this.state.initialResourceEdit,
							recurringEventId: "",
							type: "",
						};

						this.props.onUpdateBoardroomEvent(payload).then(() => {
							this.handleCloseEventAddEdit();
							this.handleCloseEventDetails();
							this.updateEvents();
						});
					}
				}
			}
		);
	};

	deleteAPI = payload => {
		this.props.onDeleteBoardroomEvent(payload).then(() => {
			this.handleCloseEventDetails();
			this.handleCloseEventDeleteConfirmation();
			this.updateEvents();
		});
	};

	respondAPI = id => {
		let updatedDetails = { ...this.state.bookingDetails };
		let updatedDetailsBottomBar = { ...updatedDetails.bottomBarActions };
		let updatedDetailsBottomBarActions = [...updatedDetailsBottomBar.actions];

		const updatedDetailsBottomBarActionIndex = updatedDetailsBottomBarActions.findIndex(
			action => action.id === id
		);
		if (updatedDetailsBottomBarActionIndex !== -1) {
			let updatedAction = {
				...updatedDetailsBottomBarActions[updatedDetailsBottomBarActionIndex],
			};

			if (
				this.state.selectedEvent.recurrence &&
				this.state.selectedEvent.recurrence.length > 0
			) {
				this.setState(
					{
						currentResponseAnswer: updatedAction.value,
					},
					() => {
						this.handleOpenRecurrenceConfirmation("response");
					}
				);
			} else {
				const payload = {
					eventId: this.state.selectedEvent.id,
					response: updatedAction.value,
					resourceId: this.state.selectedEvent.resourceId,
					recurringEventId: "",
					type: "",
				};
				updatedAction.loading = true;
				updatedDetailsBottomBarActions[
					updatedDetailsBottomBarActionIndex
					] = updatedAction;
				updatedDetailsBottomBar.actions = updatedDetailsBottomBarActions;
				updatedDetails.bottomBarActions = updatedDetailsBottomBar;

				this.setState(
					{
						bookingDetails: updatedDetails,
					},
					() => {
						this.props.onRespondToBoardroomEvent(payload).then(() => {
							let updatedDetails = { ...this.state.bookingDetails };
							let updatedDetailsBottomBar = {
								...updatedDetails.bottomBarActions,
							};
							let updatedDetailsBottomBarActions = [
								...updatedDetailsBottomBar.actions,
							];
							let updatedAction = {
								...updatedDetailsBottomBarActions[
									updatedDetailsBottomBarActionIndex
									],
							};
							updatedAction.loading = false;
							updatedDetailsBottomBarActions[
								updatedDetailsBottomBarActionIndex
								] = updatedAction;
							updatedDetailsBottomBar.actions = updatedDetailsBottomBarActions;
							updatedDetails.bottomBarActions = updatedDetailsBottomBar;
							this.setState(
								{
									bookingDetails: updatedDetails,
								},
								() => {
									this.handleCloseEventDetails();
									this.updateEvents();
								}
							);
						});
					}
				);
			}
		}
	};

	eventsAPI = (timeMin, timeMax, cancelToken) => {
		const eventsPayload = {
			timeMin: timeMin,
			timeMax: timeMax,
			cancelToken: cancelToken,
		};

		this.props.onGetEvents(eventsPayload).then(() => {
			this.timer = setInterval(() => {
				this.props.onGetEvents(eventsPayload);
			}, 10000);
		});
	};

	cancelRequestAndClearTimer = () => {
		if (this.state.cancelToken) {
			this.state.cancelToken.cancel();
		}
		if (this.timer) {
			clearInterval(this.timer);
			this.timer = null;
		}
	};

	createCancelToken = () => {
		const CancelToken = axios.CancelToken;
		return CancelToken.source();
	};

	updateEvents = () => {
		this.cancelRequestAndClearTimer();
		const source = this.createCancelToken();
		this.setState(
			{
				cancelToken: source,
			},
			() => {
				this.eventsAPI(this.state.timeMin, this.state.timeMax, source);
			}
		);
	};

	componentDidMount = () => {
		const source = this.createCancelToken();
		const timeMin = moment()
			.set("hour", 0)
			.set("minute", 0)
			.set("second", 0)
			.format();
		const timeMax = moment()
			.set("hour", 0)
			.set("minute", 0)
			.set("second", 0)
			.add(1, "days")
			.format();

		this.props.onGetContacts();
		if (!this.props.user.userEmail)
			this.props.getCurrentUser();

		this.setState(
			{
				cancelToken: source,
				timeMin: timeMin,
				timeMax: timeMax,
			},
			() => {
				this.props.onGetResources({ type: "BoardRoom" }).then(() => {
					this.eventsAPI(timeMin, timeMax, source);
				});
			}
		);
	};

	componentWillUnmount() {
		this.cancelRequestAndClearTimer();
	}

	render() {
		const {
			eventDetailsOpen,
			selectedEvent,
			eventAddEditOpen,
			isEdit,
			eventDeleteConfirmation,
			recurringRadio,
			recurrenceConfirmationType,
			recurrenceUpdateConfirmation,
			bookingForm,
			bookingDetails,
			currentCalendarDate,
			currentCalendarView,
		} = this.state;
		const {
			eventsLoading,
			events,
			resources,
			newEventLoading,
			updateEventLoading,
			deleteEventLoading,
			respondToEventLoading,
			user
		} = this.props;
		return (
			<Fragment>
				<BoardroomCalendar
					events={events}
					resourceMap={resources}
					handleSelectOpenSlot={this.handleSelectOpenSlot}
					handleSelectEvent={this.handleSelectEvent}
					handleRangeChange={this.handleRangeChange}
					handleDrillDown={this.handleDrillDown}
					handleNavigate={this.handleNavigate}
					handleViewChange={this.handleViewChange}
					date={currentCalendarDate}
					view={currentCalendarView}
					eventsLoading={eventsLoading}
				/>
				<DetailsDialog
					user={user}
					open={eventDetailsOpen}
					bookingDetails={bookingDetails}
					handleClose={this.handleCloseEventDetails}
				/>
				<InputFormDialog
					formFields={bookingForm.fields}
					open={eventAddEditOpen}
					onClose={this.handleCloseEventAddEdit}
					onSubmit={isEdit ? this.editAPI : this.createAPI}
					loading={isEdit ? updateEventLoading : newEventLoading}
				/>
				<ConfirmationDialog
					open={eventDeleteConfirmation}
					handleClose={this.handleCloseEventDeleteConfirmation}
					handleConfirmation={this.handleConfirmDelete}
					promptText={"Are you sure you want to cancel the booking ?"}
					loading={deleteEventLoading}
				/>
				<BoardroomRecurrenceUpdate
					open={recurrenceUpdateConfirmation}
					handleClose={this.handleCloseRecurrenceConfirmation}
					handleConfirmation={this.handleRecurrenceConfirmation}
					type={recurrenceConfirmationType}
					loadingDelete={deleteEventLoading}
					loadingRespond={respondToEventLoading}
					loadingUpdate={updateEventLoading}
					selectedEvent={selectedEvent}
					recurringRadio={recurringRadio}
					handleRecurringConfirmationChange={
						this.handleRecurringConfirmationChange
					}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		resources: state.globalReducer.resources,
		resourcesLoading: state.globalReducer.resourcesLoading,
		resourcesError: state.globalReducer.resourcesError,
		eventsLoadedDates: state.boardroomsReducer.eventsLoadedDates,
		events: state.boardroomsReducer.events,
		eventsLoading: state.boardroomsReducer.eventsLoading,
		eventsError: state.boardroomsReducer.eventsError,
		event: state.boardroomsReducer.event,
		eventLoading: state.boardroomsReducer.eventLoading,
		eventError: state.boardroomsReducer.eventError,
		newEventLoading: state.boardroomsReducer.newEventLoading,
		newEventError: state.boardroomsReducer.newEventError,
		updateEventLoading: state.boardroomsReducer.updateEventLoading,
		updateEventError: state.boardroomsReducer.updateEventError,
		deleteEventLoading: state.boardroomsReducer.deleteEventLoading,
		deleteEventError: state.boardroomsReducer.deleteEventError,
		respondToEventLoading: state.boardroomsReducer.respondToEventLoading,
		respondToEventError: state.boardroomsReducer.respondToEventError,
		currentResponse: state.boardroomsReducer.currentResponse,
		contacts: state.globalReducer.contacts,
		contactsLoading: state.globalReducer.contactsLoading,
		contactsError: state.globalReducer.contactsError,
		user: state.userReducer.currentUser,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetEvents: payload => dispatch(getBoardroomEvents(payload)),
		onGetContacts: () => dispatch(getUserDirectory()),
		onGetResources: payload => dispatch(getResources(payload)),
		onClearContacts: () => dispatch(clearUserDirectory()),
		onCreateBoardroomEvent: payload => dispatch(createBoardroomEvent(payload)),
		onUpdateBoardroomEvent: payload => dispatch(updateBoardroomEvent(payload)),
		onDeleteBoardroomEvent: payload => dispatch(deleteBoardroomEvent(payload)),
		onRespondToBoardroomEvent: payload =>
			dispatch(respondToBoardroomEvent(payload)),
		getCurrentUser: () => getCurrentUser()(dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Boardrooms);