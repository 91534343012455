import React from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Autocomplete, TextField, Radios, KeyboardDateTimePicker} from "mui-rff";

import styled from "styled-components";
import {Form} from "react-final-form";

import CustomDialogTitle from "../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import Loader from "../../../components/Loader/Loader";
import * as loadingTypes from "../../../store/loading/types";
import {Condition, focusOnError} from "../../../assets/helpers/finalFormHelpers";
import {sleep} from "../../../assets/helpers/globals";
import DateFnsUtils from "@date-io/date-fns";
import 'date-fns';

const LoadingDiv = styled.div`
  position: absolute;
  bottom: 11px;
  right: 36px;
`;

const FormDialogContent = styled(DialogContent)`
  padding: 24px 30px;
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const ActionButton = styled(Button)`
  margin: 0 5px;
`;

function NewTenderDialog  ({
                                open,
                                onClose,
                                onSubmit,
                                loading,
                                initialValues,
                                timeZones,
                                allProfitCentres,
                                legalEntities,
                                users
}) {
    const formFields = [
        {
            field: (
                <Grid item xs={12}>
                    <TextField
                        label="New Tender Number"
                        name="tenderNumber"
                        margin="none"
                        disabled
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Radios
                        label="Legal Entity"
                        name="legalEntityType"
                        margin="none"
                        required
                        radioGroupProps={{row: true}}
                        data={[
                            {label: 'Existing Legal Entity', value: 'existingLegalEntity'},
                            {label: 'Other Legal Entity', value: 'otherLegalEntity'},
                        ]}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Condition when="legalEntityType" is="existingLegalEntity">
                    <Grid item xs={12}>
                        <Autocomplete
                            label="Existing Legal Entity"
                            name="legalEntity"
                            noOptionsText={"No legal entities found"}
                            required
                            options={["", ...legalEntities.sort((a, b) => a.entityName.localeCompare(b.entityName))]}
                            getOptionLabel={option => option ? `${option.entityName}` : ""}
                            getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                            filterOptions={(options, params) => options.filter((option) => option !== "" && option.entityName.toLowerCase().includes(params.inputValue.toLowerCase()))}
                            handleHomeEndKeys
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Condition when="legalEntityType" is="otherLegalEntity">
                    <Grid item xs={12}>
                        <TextField
                            label="Other Legal Entity Name"
                            name="otherLegalEntity"
                            margin="none"
                            required
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <TextField
                        label="Tender Description"
                        name="tenderDescription"
                        margin="none"
                        required
                        multiline
                        rowsMax={10}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Autocomplete
                        label="Profit Centre"
                        name="profitCentres"
                        noOptionsText={"No profit centres found"}
                        required
                        options={["", ...allProfitCentres.sort((a, b) => a.centres.localeCompare(b.centres))]}
                        getOptionLabel={option => option ? `${option.centres}` : ""}
                        getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                        filterOptions={(options, params) => options.filter((option) => option !== "" &&
                            option.centres.toLowerCase().includes(params.inputValue.toLowerCase()))}
                        handleHomeEndKeys
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <TextField
                        label="Client Name"
                        name="clientName"
                        margin="none"
                        required
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Autocomplete
                        label="Tender Coordinator"
                        name="tenderCoordinator"
                        noOptionsText={"No tender coordinators found"}
                        required
                        options={["", ...users.sort((a, b) => a.userFullName.localeCompare(b.userFullName))]}
                        getOptionLabel={option => option ? `${option.userFullName}` : ""}
                        getOptionSelected={(option, value) => option.id === value.id}
                        filterOptions={(options, params) => options.filter((option) => option !== "" &&
                            option.userFullName.toLowerCase().includes(params.inputValue.toLowerCase()))}
                        handleHomeEndKeys
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Autocomplete
                        label="Technical Lead"
                        name="technicalLead"
                        noOptionsText={"No technical leads found"}
                        options={["", ...users.sort((a, b) => a.userFullName.localeCompare(b.userFullName))]}
                        getOptionLabel={option => option ? `${option.userFullName}` : ""}
                        getOptionSelected={(option, value) => option.id === value.id}
                        filterOptions={(options, params) => options.filter((option) => option !== "" &&
                            option.userFullName.toLowerCase().includes(params.inputValue.toLowerCase()))}
                        handleHomeEndKeys
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={6}>
                    <KeyboardDateTimePicker
                        dateFunsUtils={DateFnsUtils}
                        label="Due Date"
                        name="dueDate"
                        margin="none"
                        required
                        placeholder={"dd-mm-yyyy hh:mm"}
                        format="dd-MM-yyyy HH:mm"
                        showTodayButton
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={6}>
                    <KeyboardDateTimePicker
                        dateFunsUtils={DateFnsUtils}
                        label="Site Visit"
                        name="siteVisit"
                        margin="none"
                        placeholder={"dd-mm-yyyy hh:mm"}
                        format="dd-MM-yyyy HH:mm"
                        showTodayButton
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Autocomplete
                        label="Due Date Time Zone"
                        name="dueDateTimeZone"
                        noOptionsText={"No due date time zones found"}
                        required
                        options={["", ...timeZones.sort()]}
                        getOptionLabel={option => option ? option : ""}
                        getOptionSelected={(option, value) => option === value}
                        filterOptions={(options, params) => options.filter((option) => option !== "" &&
                            option.toLowerCase().includes(params.inputValue.toLowerCase()))}
                        handleHomeEndKeys
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Radios
                        label="How Will We Tender"
                        name="bidType"
                        margin="none"
                        radioGroupProps={{row: true}}
                        data={[
                            {label: 'Self Bidding', value: 'Self bidding'},
                            {label: 'Sub-Consultant', value: 'Sub-Consultant'},
                            {label: 'Joint Venture', value: 'Joint Venture'},
                        ]}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <>
                <Condition when="bidType" is={'Sub-Consultant'}>
                    <Grid item xs={12}>
                        <TextField
                            label="Bidding Comment"
                            name="bidTypeComment"
                            margin="none"
                            multiline
                            rows={4}
                            rowsMax={10}
                        />
                    </Grid>
                </Condition>
                    <Condition when="bidType" is={'Joint Venture'}>
                        <Grid item xs={12}>
                            <TextField
                                label="Bidding Comment"
                                name="bidTypeComment"
                                margin="none"
                                multiline
                                rows={4}
                                rowsMax={10}
                            />
                        </Grid>
                    </Condition>
                </>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <TextField
                        label="Comments"
                        name="comments"
                        margin="none"
                        multiline
                        rows={4}
                        rowsMax={10}
                    />
                </Grid>
            ),
        },
    ]

    const validate = values => {
        const errors = {}

        if (values.legalEntityType === "existingLegalEntity" && !values.legalEntity)
            errors.legalEntity = 'Required'
        if (values.legalEntityType === "otherLegalEntity" && !values.otherLegalEntity)
            errors.otherLegalEntity = 'Required'
        if (!values.profitCentres)
            errors.profitCentres = 'Required'
        if (!values.clientName)
            errors.clientName = 'Required'
        if (!values.tenderDescription)
            errors.tenderDescription = 'Required'
        if (!values.tenderCoordinator)
            errors.tenderCoordinator = 'Required'
        if (!values.dueDate)
            errors.dueDate = 'Required'
        if (isNaN(Date.parse(values.dueDate)))
            errors.dueDate = 'Due date is not a valid date'
        if (values.siteVisit && isNaN(Date.parse(values.siteVisit)))
            errors.siteVisit = 'Site visit is not a valid date'
        if (!values.dueDateTimeZone)
            errors.dueDateTimeZone = 'Required'

        return errors
    }

    const onSubmitForm = async values => {
        let formData = new FormData();

        if (values.legalEntityType === "existingLegalEntity" && values.legalEntity) {
            formData.append('legalEntity', values.legalEntity.uniqueId);
            formData.append('otherLegalEntity', "");
        }
        else {
            formData.append('legalEntity', "");
            formData.append('otherLegalEntity', values.otherLegalEntity);
        }

        formData.append('profitCentres', values.profitCentres.uniqueId);
        formData.append('tenderNumber', values.tenderNumber);
        formData.append('tenderDescription', values.tenderDescription);
        formData.append('clientName', values.clientName);

        formData.append('dueDate', (values.dueDate).toISOString());
        if (values.siteVisit)
            formData.append('siteVisit', (values.siteVisit).toISOString() || "");
        formData.append('dueDateTimeZone', values.dueDateTimeZone);

        formData.append('bidType', values.bidType);

        if (values.bidType === "Sub-Consultant" || values.bidType === "Joint Venture")
            formData.append('bidTypeComment', values.bidTypeComment || "");

        formData.append('comments', values.comments || "");

        if (initialValues.uniqueId)
            formData.append('uniqueId', values.uniqueId);

        await onSubmit(values.technicalLead.id || "", values.tenderCoordinator.id, formData);
    }

    let submit;

    return (
        <Form
            onSubmit={onSubmitForm}
            validate={validate}
            decorators={[focusOnError]}
            initialValues={initialValues}
            render={({handleSubmit, submitting, form, pristine, values}) => {
                submit = handleSubmit
                return (
                    <Dialog
                        aria-labelledby="add-tender-dialog-title"
                        onClose={onClose}
                        fullWidth
                        maxWidth={"sm"}
                        open={open}
                    >
                        <CustomDialogTitle
                            id="add-tender-dialog-title"
                            onClose={onClose}
                            showTextContent
                        >
                            Add Tender
                        </CustomDialogTitle>
                        <FormDialogContent dividers>
                            <form onSubmit={handleSubmit} noValidate>
                                <Grid container alignItems="flex-start" spacing={4}>
                                    {formFields.map((item, idx) => (
                                        <React.Fragment key={idx}>
                                            {item.field}
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </form>
                        </FormDialogContent>
                        <DialogActions>
                            <ActionButton
                                onClick={onClose}
                                type="button"
                                disabled={submitting}
                                color="primary"
                            >
                                Cancel
                            </ActionButton>
                            <ActionButton
                                type="button"
                                onClick={form.reset}
                                color="primary"
                                disabled={submitting || pristine}
                            >
                                Reset
                            </ActionButton>
                            <ActionButton
                                onClick={event => {
                                    const promise = submit(event);
                                    promise && promise.then(async () => {
                                        await sleep(300);
                                        form.reset();
                                    })
                                    return promise;
                                }}
                                type="submit"
                                disabled={submitting || pristine}
                                color="default"
                            >
                                Submit
                            </ActionButton>
                            <LoadingDiv>
                                <Loader loading={loading.type === loadingTypes.tendersAddTender ||
                                    loading.type === loadingTypes.tendersUpdateTender}
                                    size="small"/>
                            </LoadingDiv>
                        </DialogActions>
                    </Dialog>
                )
            }}
        />
    )
}
export default NewTenderDialog;