import React from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import styled from "styled-components";
import {Form, Field} from "react-final-form";
import CustomDialogTitle from "../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import Loader from "../../../components/Loader/Loader";
import * as loadingTypes from "../../../store/loading/types";
import FileFieldAdapter from "../../../components/Inputs/FileField/FileField";
import {formatShortDate, sleep} from "../../../assets/helpers/globals";

const LoadingDiv = styled.div`
  position: absolute;
  bottom: 11px;
  right: 36px;
`;

const FormDialogContent = styled(DialogContent)`
  padding: 10px 30px 24px 30px;
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const ActionButton = styled(Button)`
  margin: 0 5px;
`;

const NewResponseDialog = ({
      open,
      onClose,
      loading,
      onSubmit,
      initialValues
}) => {
    const formFields = [
        {
            size: 12,
            field: (
                <Field
                    component={FileFieldAdapter}
                    name="attachments"
                    label="Attachment"
                    multiple
                    buttonLabel="UPLOAD AN INVOICE"
                    clearLabel="INVOICES"
                />
            ),
        },
    ]

    const validate = values => {
        const errors = {}

        if (!values.attachments || !values.attachments?.length)
            errors.attachments = 'Required'

        return errors
    }

    const onSubmitForm = async values => {
        let formData = new FormData();
        let attachArray = [];

        values.attachments.forEach(file => {
            if (file.generatedFilename)
                attachArray.push(file.generatedFilename)
            else
                formData.append("file", file);
        });

        if (attachArray && attachArray.length)
            formData.append("existingAttachments", attachArray);
        else
            formData.append("existingAttachments", null);
        formData.append("id", values.uniqueId);
        formData.append('responseSubmittedDate', formatShortDate(new Date()));
        formData.append('status', "Invoice Attached");
        formData.append('comment', "");

        await onSubmit(formData);
    }

    let submit;

    return (
        <Form
            onSubmit={onSubmitForm}
            validate={validate}
            initialValues={initialValues}
            render={({handleSubmit, submitting, form, pristine}) => {
                submit = handleSubmit
                return (
                    <Dialog
                        aria-labelledby="add-invoice-response-dialog-title"
                        onClose={onClose}
                        fullWidth
                        maxWidth={"sm"}
                        open={open}
                    >
                        <CustomDialogTitle
                            id="add-invoice-response-dialog-title"
                            onClose={onClose}
                            showTextContent
                        >
                            Add Invoice Response
                        </CustomDialogTitle>
                        <FormDialogContent dividers>
                            <form onSubmit={handleSubmit} noValidate>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    {formFields.map((item, idx) => (
                                        <Grid item xs={item.size} key={idx}>
                                            {item.field}
                                        </Grid>
                                    ))}
                                </Grid>
                            </form>
                        </FormDialogContent>
                        <DialogActions>
                            <ActionButton
                                onClick={onClose}
                                type="button"
                                disabled={submitting}
                                color="primary"
                            >
                                Cancel
                            </ActionButton>
                            <ActionButton
                                type="button"
                                color="primary"
                                onClick={form.reset}
                                disabled={submitting || pristine}
                            >
                                Reset
                            </ActionButton>
                            <ActionButton
                                onClick={event => {
                                    const promise = submit(event);
                                    promise && promise.then(async() => {
                                        await sleep(300);
                                        form.reset();
                                    })
                                    return promise;
                                }}
                                type="submit"
                                disabled={submitting || pristine}
                                color="default"
                            >
                                Submit
                            </ActionButton>
                            <LoadingDiv>
                                <Loader
                                    loading={loading.type === loadingTypes.invoicesAddInvoiceResponse || loading.type === loadingTypes.invoicesUpdateInvoiceResponse}
                                     size="small"/>
                            </LoadingDiv>
                        </DialogActions>
                    </Dialog>
                )
            }}
        />
    )
}

export default NewResponseDialog;
