import axiosInstance from '../../assets/helpers/axiosInstance';
import { endLoading, startLoading } from "../loading/actions";
import * as loadingTypes from "../loading/types";
import {setError} from "../error/actions";
import * as errorTypes from "../error/types";

export const SET_PERMISSIONS_BY_GROUP = "permissions/SET_PERMISSIONS_BY_GROUP";
export const SET_PERMISSIONS_BY_FEATURE = "permissions/SET_PERMISSIONS_BY_FEATURE";
export const SET_PERMISSIONS_BY_ROLE = "permissions/SET_PERMISSIONS_BY_ROLE";
export const SET_NEXT_APPROVERS = "permissions/SET_NEXT_APPROVERS";

const setPermissionsByGroup = permissions => ({
    type: SET_PERMISSIONS_BY_GROUP,
    permissions,
});

const setPermissionsByFeature = permissions => ({
    type: SET_PERMISSIONS_BY_FEATURE,
    permissions,
});

const setPermissionsByRole = permissions => ({
    type: SET_PERMISSIONS_BY_ROLE,
    permissions,
});

const setNextApprovers = permissions => ({
    type: SET_NEXT_APPROVERS,
    permissions,
});

export const getNextApprovers = (type,id) => async dispatch => {
    dispatch(startLoading(loadingTypes.permissionsGetNextApprovers));
    try {
        const res = await axiosInstance.get(`/userPermissions/getNextApprover?type=${type}&uniqueId=${id}`);
        dispatch(setNextApprovers(res.data));
        return res.data;
    } catch (err) {
        dispatch(setError(errorTypes.permissionsGetNextApprovers, `${err.message ? err.message + " at " + errorTypes.permissionsGetNextApprovers : "Something went wrong at " + errorTypes.permissionsGetNextApprovers }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getPermissionsByGroup = group => async dispatch => {
    dispatch(startLoading(loadingTypes.permissionsGetPermissionsByGroup));
    try {
        const res = await axiosInstance.get(`/userPermissions/getUserPermissionByGroup?permissionGroup=${group}&debug=0`);
        dispatch(setPermissionsByGroup(res.data));
        return res.data;
    } catch (err) {
        dispatch(setError(errorTypes.permissionsGetPermissionsByGroup, `${err.message ? err.message + " at " + errorTypes.permissionsGetPermissionsByGroup : "Something went wrong at " + errorTypes.permissionsGetPermissionsByGroup }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getPermissionsByFeature = feature => async dispatch => {
    dispatch(startLoading(loadingTypes.permissionsGetPermissionsByFeature));
    try {
        const res = await axiosInstance.get(`/userPermissions/getUserPermissionByFeature?featureName=${feature}&debug=0`);
        dispatch(setPermissionsByFeature(res.data));
        return res.data;
    } catch (err) {
        dispatch(setError(errorTypes.permissionsGetPermissionsByFeature, `${err.message ? err.message + " at " + errorTypes.permissionsGetPermissionsByFeature : "Something went wrong at " + errorTypes.permissionsGetPermissionsByFeature }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getPermissionsByRole = (type, subType) => async dispatch => {
    dispatch(startLoading(loadingTypes.permissionsGetPermissionsByRole));
    try {
        const res = await axiosInstance.get(`/userPermissions/getUserRoles?type=${type}&subType=${subType}`);
        dispatch(setPermissionsByRole(res.data));
        return res.data;
    } catch (err) {
        dispatch(setError(errorTypes.permissionsGetPermissionsByRole, `${err.message ? err.message + " at " + errorTypes.permissionsGetPermissionsByRole : "Something went wrong at " + errorTypes.permissionsGetPermissionsByRole }`));
    } finally {
        dispatch(endLoading());
    }
};