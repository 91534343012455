import * as actionTypes from "./actions";

const initialState = { bookings: [], currentResponse: "" };

const setVehicleBookings = (state, { bookings }) => ({ ...state, bookings });

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_VEHICLE_BOOKINGS:
			return setVehicleBookings(state, action);
		default:
			return state;
	}
};
