import React, { Component, Fragment } from 'react';

import classes from './AuthLayout.module.css';
import Container from '@material-ui/core/Container';
import Navbar from '../../../components/Navigation/Navbar/Navbar';


class AuthLayout extends Component {
    state = {
        isMobile : false
    }

    windowResize = () => {
        if (window.innerWidth < 500) {
          this.setState({ 
            isMobile: true
        });
        }
        else{
            this.setState({ 
                isMobile: false
            });
        }
    };

    componentDidMount() {
        this.windowResize();
        window.addEventListener('resize', this.windowResize);
    }

    render () {
        return (
            <Fragment>
                <div className={classes.Background} />


                <Container maxWidth={"lg"} className={classes.Container}>
                    <Navbar  />

                    <div className={classes.Content}>
                        {this.props.children}
                    </div>

                </Container>

            </Fragment>
        )
    }
}

export default AuthLayout;