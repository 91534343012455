import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import CustomAction from "../../../../components/Table/CustomAction";
import styled, {css} from "styled-components";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FilterAutocomplete from "../../../../components/Inputs/Filter/FilterAutocomplete";
import Button from "@material-ui/core/Button";
import {Autorenew, Done, Clear, Info, DoneAll} from "@material-ui/icons";
import isEqual from "lodash/isEqual";
import {formatShortDate, getFilteredData} from "../../../../assets/helpers/globals";

import * as purchaseActions from "../../../../store/purchases/actions";
import * as purchaseOrderActions from "../../../../store/purchaseOrders/actions";
import {getAllProjects} from "../../../../store/projects/actions";
import {getCurrentUser} from "../../../../store/user/actions";
import {getAllTenders} from "../../../../store/tenders/actions";
import * as loadingTypes from "../../../../store/loading/types";
import {downloadFile} from "../../../../store/file/actions";
import {getAllLegalEntities} from "../../../../store/legalEntities/actions";
import {getVendorsBySupplier} from "../../../../store/vendors/actions";

import NewProjectPurchaseRequestDialog from "./Project/NewRequestDialog";
import PurchaseProjectDetailsDialog from "./Project/DetailsDialog"
import PurchaseTenderDetailsDialog from "./Tender/DetailsDialog";
import NewPurchaseTenderRequestDialog from "./Tender/NewRequestDialog";
import PurchaseItEquipmentDetailsDialog from "./IT/DetailsDialog";
import NewPurchaseItEquipmentRequestDialog from "./IT/NewRequestDialog";
import PurchaseOfficeEquipmentDetailsDialog from "./Office/DetailsDialog";
import NewPurchaseOfficeEquipmentRequestDialog from "./Office/NewRequestDialog";
import PurchaseOtherDetailsDialog from "./Other/DetailsDialog";
import NewPurchaseOtherRequestDialog from "./Other/NewRequestDialog";
import {getPermissionsByRole} from "../../../../store/permissions/actions";
import debounce from "lodash/debounce";
import AuditLoggingTableDialog from "../../../Audit/TableDialog";
import {getAuditLog, getTransactionSubTypes} from "../../../../store/audit/actions";
import {getCurrenciesByLegalEntity} from "../../../../store/currencies/actions";
import {useInterval} from "../../../../hooks/useInterval";
import {getTaxOptions} from "../../../../store/tax/actions";
import ConfirmationDialog from "../../../../components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import PurchaseProjectResponseDialog from "./Project/ResponseDialog";
import PurchaseTenderResponseDialog from "./Tender/ResponseDialog";
import PurchaseItEquipmentResponseDialog from "./IT/ResponseDialog";
import PurchaseOfficeEquipmentResponseDialog from "./Office/ResponseDialog";
import PurchaseOtherResponseDialog from "./Other/ResponseDialog";
import {setError} from "../../../../store/error/actions";
import {populateVendors} from "../../../../store/xero/actions";

const PurchaseOrderCreatedIcon = styled(DoneAll)`
  display: none;
  ${props => props.status === "PO Created" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #388e3c;
  `}
`;

const QuoteReviewedIcon = styled(Done)`  
  display: none;
  ${props => props.status === "Quote Reviewed" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #388e3c;
  `}
`;

const QuoteReviewInProgressIcon = styled(Info)`
  display: none;
  ${props => props.status === "Quote Review In Progress (Draft)" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #1976d2;
  `}
`;

const NewQuoteRequestedIcon = styled(Autorenew)`
  display: none;
  ${props => props.status === "New Quote Requested" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #f57c00;
  `}
`;

const QuoteAwaitingReviewIcon = styled(Autorenew)`
  display: none;
  ${props => props.status === "Quote Awaiting Review" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #f57c00;
  `}
`;

const POPendingIcon = styled(Autorenew)`
  display: none;
  ${props => props.status === "PO Pending" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #f57c00;
  `}
`;

const P = styled.p`
  color: #f57c00;
  border: 1px solid #ff9800;
  border-radius: 16px;
  height: 24px;
  padding-left: 4px;
  padding-right: 8px;
  white-space: nowrap;
  display: flex;
  max-width: fit-content;

  ${props => (props.status === "PO Created" || props.status === "Quote Reviewed") && css`
    color: #388e3c;
    border: 1px solid #4caf50;
  `}
  ${props => props.status === "Quote Review In Progress (Draft)" && css`
    color: #1976d2;
    border: 1px solid #2196f3;
  `}
`;

const FilterPaper = styled(Paper)`
  margin-bottom: 18px;
  margin-top: 14px;
`;

const FilterGrid = styled(Grid)`
  ${props => props.container && css`
    margin-left: 10px; 
    width: 98%;
  `}
  ${props => props.item && css`
    align-self: center
  `}
`;

const ApprovedPurchases = ({
                          //state
                          user,
                          loading,
                          approvedPurchases,
                          projects,
                          tenders,
                          legalEntities,
                          vendorsBySupplier,
                          auditLog,
                          transactionSubTypes,
                          currenciesByLegalEntity,
                          taxOptions,

                          //actions
                          getCurrentUser,
                          downloadFile,

                          getAllLegalEntities,
                          getVendorsBySupplier,

                          getApprovedPurchases,
                          updatePurchaseProjectRequest,
                          updatePurchaseProjectRequestStatus,
                          getAllProjects,

                          updatePurchaseTenderRequest,
                          updatePurchaseTenderRequestStatus,
                          getAllTenders,

                          updatePurchaseItEquipmentRequest,
                          updatePurchaseItEquipmentRequestStatus,

                          updatePurchaseOfficeEquipmentRequest,
                          updatePurchaseOfficeEquipmentRequestStatus,

                          updatePurchaseOtherRequest,
                          updatePurchaseOtherRequestStatus,
                          getUserRoles,
                          getAuditLog,
                          getCurrenciesByLegalEntity,
                          getTaxOptions,
                          getTransactionSubTypes,

                          createPurchaseOrder,
                          updatePurchaseRequestStatus,
                          deletePurchaseOrder,
                          setError,
                          populateVendors
                      }) => {
    const initialDialogState = { open: false, type: "" };
    const initialDetailsState = { open: false, type: "", data: {} };
    const initialResponseState = { open: false, type: "", data: {} };
    const initialFilterState = { purchaseOrderStatus: null, name: null, vendorName: null, totalCost: null, actions: null, taxOption: null, entityName: null };

    const [editDialogOpen, setEditDialogOpen] = useState(initialDialogState);
    const [purchaseDetails, setPurchaseDetails] = useState(initialDetailsState);
    const [deletePurchaseOrderConfirmationOpen, setDeletePurchaseOrderConfirmationOpen] = useState(false);
    const [selectedPurchase, setSelectedPurchase] = useState(undefined);
    const [purchaseResponse, setPurchaseResponse] = useState(initialResponseState);
    const [editing, setEditing] = useState(undefined);
    const [auditLogDetails, setAuditLogDetails] = useState(undefined);
    const [auditLogDialogOpen, setAuditLogDialogOpen] = useState({open: false, type: ""});
    const [filter, setFilter] = useState(initialFilterState);
    const [roles, setRoles] = useState({
        is_purchase_order_creator: false,
        is_purchase_order_viewer: false
    })

    const actions = [
        {
            icon: "visibility",
            tooltip: "View Request",
            onClick: async (_event, selectedPurchase) => {
                setPurchaseDetails({
                    open: true,
                    type: selectedPurchase.purchase.purchaseType.typeName,
                    data: selectedPurchase
                })
            }
        },
        rowData =>
            ({
                icon: "reply",
                tooltip: "Request New Quote",
                onClick: (_event, selectedPurchase) =>
                    setPurchaseResponse({
                        open: true,
                        type: selectedPurchase.purchase.purchaseType.typeName,
                        data: selectedPurchase
                    }),
                hidden: !(roles.is_purchase_order_creator && rowData.purchase.purchaseOrderStatus !== "Quote Reviewed" && rowData.purchase.purchaseOrderStatus !== "PO Created"),
            }),
        rowData =>
            ({
            icon: "edit",
            tooltip: "Review and Amend",
            hidden: !((roles.is_purchase_order_viewer && rowData.purchase.purchaseOrderStatus === "New Quote Requested") || roles.is_purchase_order_creator),
            onClick: async (_event, selectedPurchase) => {
                const defaultFields = await setEditDialogDefaultPurchasePayload(selectedPurchase);
                switch (selectedPurchase.purchase.purchaseType.typeName) {
                    case "Project":
                        // if (!projects || !projects.length)
                            await getAllProjects("Registered");

                        setEditing({
                            ...defaultFields,
                            project: selectedPurchase.workshop === "" ? selectedPurchase.projectsDetails[0].project : "",
                            projectManager: selectedPurchase.workshop === "" ? selectedPurchase.projectsDetails[0].project?.projectManager?.userFullName : "",
                            isWorkshop: selectedPurchase.workshop !== "",
                            purchaseLevel: selectedPurchase.purchase.purchaseLevel
                        })
                        setEditDialogOpen({open: true, type: selectedPurchase.purchase.purchaseType.typeName});
                        break;
                    case "Tender":
                        if (!tenders || !tenders.length)
                            await getAllTenders("Registered");
                        setEditing({
                            ...defaultFields,
                            tenderType: selectedPurchase.tender ? "tender" : "tenderAdmin",
                            purchaseLevel: selectedPurchase.purchase.purchaseLevel
                        });
                        setEditDialogOpen({open: true, type: selectedPurchase.purchase.purchaseType.typeName});
                        break;
                    default:
                        setEditDialogOpen({open: true, type: selectedPurchase.purchase.purchaseType.typeName});
                        break;
                }
            }
        }),
        rowData =>
            ({
            icon: "queue",
            tooltip: "Create PO",
            hidden: !(roles.is_purchase_order_creator && (rowData.purchase.purchaseOrderStatus === "Quote Reviewed" || rowData.purchase.purchaseOrderStatus === "PO Created")),
            onClick: async (_event, selectedPurchase) => {
                if (!rowData.purchase.vendor) {
                    setError("approvedPurchases/InputError", "Please select an existing supplier before creating a purchase order")
                }
                else if (rowData.purchase.isPurchaseOrderGenerated) {
                    setSelectedPurchase(selectedPurchase)
                    setDeletePurchaseOrderConfirmationOpen(true)
                }
                else {
                    await createPurchaseOrder(selectedPurchase.uniqueId, selectedPurchase.purchase.purchaseType.tableName);
                    let updatedPurchase = {
                        status: selectedPurchase.purchase.status,
                        rejectReason: selectedPurchase.purchase.rejectReason,
                        lastResponseDate: formatShortDate(new Date()),
                        purchaseLevel: selectedPurchase.purchase.purchaseLevel,
                        purchaseOrderStatus: "PO Created"
                    };

                    await updatePurchaseRequestStatus(selectedPurchase.purchase.uniqueId, updatedPurchase)
                }

                getApprovedPurchases();
            }
        }),
        {
            icon: "history",
            tooltip: "View Request History",
            onClick: async (_event, selectedPurchase) => {
                if (!isEqual(selectedPurchase, auditLogDetails)) {
                    let typeId

                    if (!transactionSubTypes || !transactionSubTypes.length) {
                        const res = await getTransactionSubTypes();
                        typeId = res.data.find(transactionSubType => transactionSubType.subTypeName === selectedPurchase.purchase?.purchaseType?.typeName).uniqueId;
                    } else
                        typeId = transactionSubTypes.find(transactionSubType => transactionSubType.subTypeName === selectedPurchase.purchase?.purchaseType?.typeName).uniqueId;

                    await getAuditLog(selectedPurchase.purchase?.uniqueId, typeId);
                    setAuditLogDetails(selectedPurchase)
                    setAuditLogDialogOpen({open: true, type: "Purchase"})
                }
                else
                    setAuditLogDialogOpen({open: true, type: "Purchase"})
            }
        },
        {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: debounce(getApprovedPurchases, 300, {
                'leading': false,
                'trailing': true
            }),
        },
    ];

    const setEditDialogDefaultPurchasePayload = async selectedPurchase => {
        if (!legalEntities || !legalEntities.length)
            await getAllLegalEntities()
        if (!taxOptions || !taxOptions.length)
            await getTaxOptions();
        if (selectedPurchase.purchase.vendor)
            await getVendorsBySupplier(selectedPurchase.purchase.vendor.legalEntities.uniqueId);
        else
            await getVendorsBySupplier(selectedPurchase.purchase.legalEntity.uniqueId)

        await getCurrenciesByLegalEntity(selectedPurchase.purchase.legalEntity.uniqueId);

        const purchaseObject = {
            ...selectedPurchase,
            vendor: selectedPurchase.purchase.vendor ? selectedPurchase.purchase.vendor : "",
            legalEntity: selectedPurchase.purchase.legalEntity,
            vendorOther: selectedPurchase.purchase.vendorOther ? selectedPurchase.purchase.vendorOther : "",
            vendorType: selectedPurchase.purchase.vendor ? "existingVendor" : "otherVendor",
            description: selectedPurchase.purchase.description,
            attachments: selectedPurchase.purchase.attachments,
            totalCost: selectedPurchase.purchase.totalCost,
            currency: selectedPurchase.purchase.currency,
            xeroTaxOption: selectedPurchase.purchase.xeroTaxOption,
            quoteNumber: selectedPurchase.purchase.quoteNumber,
            status: selectedPurchase.purchase.status
        }

        setEditing(purchaseObject)

        return purchaseObject
    }

    const filterData = (data, query) => {
        return data.filter((item) => {
            for (let key in query) {
                switch (key) {
                    case "entityName":
                        if (item.purchase.legalEntity === null || item.purchase.legalEntity[key] === undefined || !query[key].includes(item.purchase.legalEntity[key])) {
                            return false;
                        }
                        break;
                    case "vendorName":
                        if (item.purchase.vendor === null || item.purchase.vendor[key] === undefined || !query[key].includes(item.purchase.vendor[key]))
                            return false;
                        break;
                    case "name":
                        if (item.purchase.currency === null || item.purchase.currency[key] === undefined || !query[key].includes(item.purchase.currency[key]))
                            return false;
                        break;
                    case "totalCost":
                        if (item.purchase[key] === undefined || item.purchase[key] === null) {
                            return false;
                        }
                        if (query[key]['value']['min'] !== null && item.purchase[key] < query[key]['value']['min']) {
                            return false;
                        }
                        if (query[key]['value']['max'] !== null && item.purchase[key] >= query[key]['value']['max']) {
                            return false;
                        }
                        break;
                    case "taxOption":
                        if (item.purchase.xeroTaxOption === null || item.purchase.xeroTaxOption[key] === undefined || !query[key].includes(item.purchase.xeroTaxOption[key]))
                            return false;
                        break;
                    default:
                        if (item.purchase[key] === undefined || !query[key].includes(item.purchase[key]))
                            return false;
                        break;
                }
            }
            return true;
        });
    };

    useInterval(() => {
        getApprovedPurchases();
    }, 300000);

    useEffect(() => {
        (async function() {
            const res = await getUserRoles("purchase","order");
            const permissionObj = {
                is_purchase_order_creator: res.some(name => name === "purchase_order_creator"),
                is_purchase_order_viewer: res.some(name => name === "purchase_order_viewer")
            }

            setRoles(permissionObj);
            // setFilter(filter => ({...filter, purchaseOrderStatus: "PO Pending"}))
        })();

        if (!user.userEmail)
            getCurrentUser();
        getApprovedPurchases();
    }, [getCurrentUser, getApprovedPurchases, getUserRoles, user.userEmail])

    return (
        <>
            <FilterPaper>
                <FilterGrid container spacing={2}>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, purchaseOrderStatus: newValue})}
                            value={filter.purchaseOrderStatus}
                            options={["PO Pending", ...Object.values(approvedPurchases.purchases || {}).reduce((prev, current) => [...prev, ...current], [])
                                .map(purchaseItem => purchaseItem.purchase.purchaseOrderStatus)]}
                            placeholder="Status"
                            noOptionsText="No Statuses"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, entityName: newValue})}
                            value={filter.entityName}
                            options={Object.values(approvedPurchases.purchases || {}).reduce((prev, current) => [...prev, ...current], [])
                                .map(purchaseItem => purchaseItem.purchase?.legalEntity?.entityName)}
                            placeholder="Legal Entity"
                            noOptionsText="No Legal Entities"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, vendorName: newValue})}
                            value={filter.vendorName}
                            options={ Object.values(approvedPurchases.purchases || {}).reduce((prev, current) => [...prev, ...current], [])
                                .map(purchaseItem => purchaseItem.purchase?.vendor !== null ? purchaseItem.purchase.vendor.vendorName : '')
                                .filter((purchaseItem, index, array) => array.indexOf(purchaseItem) === index && purchaseItem !== '')}
                            placeholder="Supplier"
                            noOptionsText="No Suppliers"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, name: newValue})}
                            value={filter.name}
                            options={Object.values(approvedPurchases.purchases || {}).reduce((prev, current) => [...prev, ...current], [])
                                .map(purchaseItem => purchaseItem.purchase?.currency?.name)}
                            placeholder="Currency"
                            noOptionsText="No Currencies"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter(filter => ({...filter, totalCost: newValue}))}
                            value={filter.totalCost}
                            options={[
                                {description: "Quote less than 50K", value: {min: 0, max: 50000}},
                                {description: "Quote greater than 50K", value: {min: 50000, max: null}},
                            ]}
                            placeholder="Quote"
                            noOptionsText="No Quotes"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, taxOption: newValue})}
                            value={filter.taxOption}
                            options={Object.values(approvedPurchases.purchases || {}).reduce((prev, current) => [...prev, ...current], [])
                                .map(purchaseItem => purchaseItem.purchase?.xeroTaxOption?.taxOptionText)}
                            placeholder="Tax"
                            noOptionsText="No Tax Options"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <Button
                            color="secondary"
                            size="small"
                            startIcon={<Clear/>}
                            disabled={isEqual(filter, initialFilterState)}
                            onClick={() => setFilter(initialFilterState)}>
                            Clear All
                        </Button>
                    </FilterGrid>
                </FilterGrid>
            </FilterPaper>

            <MaterialTable
                columns={[
                    {
                        title: "Submitted Date",
                        field: "purchase.createdDate",
                        type: "date",
                    },
                    {
                        title: "Modified Date",
                        field: "purchase.lastModifiedDate",
                        type: "date",
                        defaultSort: "desc",
                        customSort: (a, b) => {
                            let d1 = new Date(a.purchase.lastModifiedDate);
                            let d2 = new Date(b.purchase.lastModifiedDate);
                            return d1.getTime() - d2.getTime();
                        }
                    },
                    {
                        title: "Legal Entity",
                        field: "purchase.legalEntity.entityName",
                    },
                    {
                        title: "Supplier",
                        field: "vendor.vendorName",
                        render: rowData => rowData.purchase.vendor ? <span>{rowData.purchase.vendor.vendorName}</span> :
                            <span>{rowData.purchase.vendorOther}</span>,
                    },
                    {
                        title: "Amount",
                        field: "purchase.totalCost",
                        align: "right",
                    },
                    {
                        title: "Currency",
                        field: "purchase.currency.name",
                    },
                    {
                        title: "Tax",
                        field: "purchase.xeroTaxOption.taxOptionText",
                    },
                    {
                        title: "Quote Number",
                        field: "purchase.quoteNumber"
                    },
                    {
                        title: "Requestor",
                        field: "purchase.requestor.userFullName",
                    },
                    {
                        title: "Purchase Type",
                        field: "purchase.purchaseType.typeName",
                        render: rowData => <span>{rowData.purchase.purchaseType.typeName}</span>
                    },
                    {
                        title: "Status",
                        field: "purchase.purchaseOrderStatus",
                        render: data => (
                            <P status={data.purchase.purchaseOrderStatus}>
                                <POPendingIcon status={data.purchase.purchaseOrderStatus}/>
                                <QuoteAwaitingReviewIcon status={data.purchase.purchaseOrderStatus}/>
                                <NewQuoteRequestedIcon status={data.purchase.purchaseOrderStatus}/>
                                <QuoteReviewInProgressIcon status={data.purchase.purchaseOrderStatus}/>
                                <QuoteReviewedIcon status={data.purchase.purchaseOrderStatus}/>
                                <PurchaseOrderCreatedIcon status={data.purchase.purchaseOrderStatus}/>{data.purchase.purchaseOrderStatus}</P>
                        ),
                    }
                ]}
                actions={actions}
                components={{ Action: CustomAction }}
                data={isEqual(filter, initialFilterState) ? Object.values(approvedPurchases.purchases || {}).reduce((prev, current) => [...prev, ...current], [])
                    : getFilteredData(filter, Object.values(approvedPurchases.purchases || {}).reduce((prev, current) => [...prev, ...current], []), [], filterData)}
                isLoading={editDialogOpen.open === false && (
                    loading.type === loadingTypes.purchaseOrdersGetApprovedPurchases ||
                    loading.type === loadingTypes.projectsGetAllProjects ||
                    loading.type === loadingTypes.tendersGetAllTenders ||
                    loading.type === loadingTypes.legalEntitiesGetAllLegalEntities ||
                    loading.type === loadingTypes.taxGetTaxOptions ||
                    loading.type === loadingTypes.vendorsGetVendorsBySupplier ||
                    loading.type === loadingTypes.currenciesGetCurrenciesByLegalEntity ||
                    loading.type === loadingTypes.auditGetTransactionSubTypes ||
                    loading.type === loadingTypes.auditGetAuditLog ||
                    loading.type === loadingTypes.permissionsGetPermissionsByRole)
                }
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    searchFieldAlignment: "left",
                    showTitle: false,
                    isLoading: true,
                    search: true,
                    // loadingType: "linear",
                    // thirdSortClick: false,
                    toolbarButtonAlignment: "right",
                    sorting: true
                }}
            />

            <PurchaseProjectDetailsDialog
                key={"Purchase-Project-Details-" + purchaseDetails?.data?.uniqueId}
                details={purchaseDetails.data}
                onClose={() => setPurchaseDetails(initialDetailsState)}
                open={purchaseDetails.open && purchaseDetails.type === "Project"}
                isAdmin={false}
                userEmail={user.userEmail}
                downloadFile={downloadFile}
                onSubmit={async updatedPurchaseRequest => {
                    await updatePurchaseProjectRequestStatus(purchaseDetails.data.uniqueId, updatedPurchaseRequest);
                    getApprovedPurchases();
                    setPurchaseDetails(initialDetailsState);
                }}
                loading={loading}
            />
            <PurchaseProjectResponseDialog
                key={"Purchase-Project-Respond-" + purchaseResponse.data?.uniqueId}
                details={purchaseResponse.data}
                onClose={() => setPurchaseResponse(initialResponseState)}
                open={purchaseResponse.open && purchaseResponse.type === "Project"}
                isAdmin={false}
                userEmail={user.userEmail}
                onSubmit={async (updatedPurchaseRequest) => {
                    await updatePurchaseProjectRequestStatus(purchaseResponse.data.uniqueId, updatedPurchaseRequest);
                    getApprovedPurchases();
                    setPurchaseResponse(initialResponseState);
                }}
                loading={loading}
            />
            <NewProjectPurchaseRequestDialog
                key={"Purchase-Project-Edit-" + editing?.uniqueId}
                open={editDialogOpen.open && editDialogOpen.type === "Project"}
                onClose={() => setEditDialogOpen(initialDialogState)}
                initialValues={editing}
                onSubmit={async updatedPurchase => {
                    await updatePurchaseProjectRequest(editing?.uniqueId, updatedPurchase);
                    getApprovedPurchases();
                    setEditDialogOpen(initialDialogState);
                }}
                downloadFile={downloadFile}
                isAdmin={roles.is_purchase_order_creator}
                loading={loading}
                projects={projects}
                getVendorsBySupplier={getVendorsBySupplier}
                vendorsBySupplier={vendorsBySupplier}
                legalEntities={legalEntities}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
                taxOptions={taxOptions}
                populateVendors={populateVendors}
            />

            <PurchaseTenderDetailsDialog
                key={"Purchase-Tender-Details-" + purchaseDetails?.data?.uniqueId}
                details={purchaseDetails.data}
                onClose={() => setPurchaseDetails(initialDetailsState)}
                open={purchaseDetails.open && purchaseDetails.type === "Tender"}
                isAdmin={false}
                downloadFile={downloadFile}
                onSubmit={async updatedPurchaseRequest => {
                    await updatePurchaseTenderRequestStatus(purchaseDetails.data.uniqueId, updatedPurchaseRequest);
                    getApprovedPurchases();
                    setPurchaseDetails(initialDetailsState);
                }}
                loading={loading}
            />
            <PurchaseTenderResponseDialog
                key={"Purchase-Tender-Respond-" + purchaseResponse.data?.uniqueId}
                details={purchaseResponse.data}
                onClose={() => setPurchaseResponse(initialResponseState)}
                open={purchaseResponse.open && purchaseResponse.type === "Tender"}
                onSubmit={async (updatedPurchaseRequest) => {
                    await updatePurchaseTenderRequestStatus(purchaseResponse.data.uniqueId, updatedPurchaseRequest);
                    getApprovedPurchases();
                    setPurchaseResponse(initialResponseState);
                }}
                loading={loading}
            />
            <NewPurchaseTenderRequestDialog
                key={"Purchase-Tender-Edit-" + editing?.uniqueId}
                open={editDialogOpen.open && editDialogOpen.type === "Tender"}
                onClose={() => setEditDialogOpen(initialDialogState)}
                initialValues={editing}
                onSubmit={async updatedPurchase => {
                    await updatePurchaseTenderRequest(editing?.uniqueId, updatedPurchase);
                    getApprovedPurchases();
                    setEditDialogOpen(initialDialogState);
                }}
                downloadFile={downloadFile}
                isAdmin={roles.is_purchase_order_creator}
                loading={loading}
                tenders={tenders}
                getVendorsBySupplier={getVendorsBySupplier}
                vendorsBySupplier={vendorsBySupplier}
                legalEntities={legalEntities}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
                taxOptions={taxOptions}
                populateVendors={populateVendors}
            />

            <PurchaseItEquipmentDetailsDialog
                key={"Purchase-It-Equipment-Details-" + purchaseDetails?.data?.uniqueId}
                details={purchaseDetails.data}
                onClose={() => setPurchaseDetails(initialDetailsState)}
                open={purchaseDetails.open && purchaseDetails.type === "It"}
                isAdmin={false}
                downloadFile={downloadFile}
                onSubmit={async updatedPurchaseRequest => {
                    await updatePurchaseItEquipmentRequestStatus(purchaseDetails.data.uniqueId, updatedPurchaseRequest);
                    getApprovedPurchases();
                    setPurchaseDetails(initialDetailsState);
                }}
                loading={loading}
            />
            <PurchaseItEquipmentResponseDialog
                key={"Purchase-It-Equipment-Respond-" + purchaseResponse.data?.uniqueId}
                details={purchaseResponse.data}
                onClose={() => setPurchaseResponse(initialResponseState)}
                open={purchaseResponse.open && purchaseResponse.type === "It"}
                onSubmit={async (updatedPurchaseRequest) => {
                    await updatePurchaseItEquipmentRequestStatus(purchaseResponse.data.uniqueId, updatedPurchaseRequest);
                    getApprovedPurchases();
                    setPurchaseResponse(initialResponseState);
                }}
                loading={loading}
            />
            <NewPurchaseItEquipmentRequestDialog
                key={"Purchase-It-Equipment-Edit-" + editing?.uniqueId}
                open={editDialogOpen.open && editDialogOpen.type === "It"}
                onClose={() => setEditDialogOpen(initialDialogState)}
                initialValues={editing}
                onSubmit={async updatedPurchase => {
                    await updatePurchaseItEquipmentRequest(editing?.uniqueId, updatedPurchase);
                    getApprovedPurchases();
                    setEditDialogOpen(initialDialogState);
                }}
                downloadFile={downloadFile}
                isAdmin={roles.is_purchase_order_creator}
                loading={loading}
                getVendorsBySupplier={getVendorsBySupplier}
                vendorsBySupplier={vendorsBySupplier}
                legalEntities={legalEntities}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
                taxOptions={taxOptions}
                populateVendors={populateVendors}
            />

            <PurchaseOfficeEquipmentDetailsDialog
                key={"Purchase-Office-Equipment-Details-" + purchaseDetails?.data?.uniqueId}
                details={purchaseDetails.data}
                onClose={() => setPurchaseDetails(initialDetailsState)}
                open={purchaseDetails.open && purchaseDetails.type === "Office"}
                isAdmin={false}
                downloadFile={downloadFile}
                onSubmit={async updatedPurchaseRequest => {
                    await updatePurchaseOfficeEquipmentRequestStatus(purchaseDetails.data.uniqueId, updatedPurchaseRequest);
                    getApprovedPurchases();
                    setPurchaseDetails(initialDetailsState);
                }}
                loading={loading}
            />
            <PurchaseOfficeEquipmentResponseDialog
                key={"Purchase-Office-Equipment-Respond-" + purchaseResponse.data?.uniqueId}
                details={purchaseResponse.data}
                onClose={() => setPurchaseResponse(initialResponseState)}
                open={purchaseResponse.open && purchaseResponse.type === "Office"}
                onSubmit={async (updatedPurchaseRequest) => {
                    await updatePurchaseOfficeEquipmentRequestStatus(purchaseResponse.data.uniqueId, updatedPurchaseRequest);
                    getApprovedPurchases();
                    setPurchaseResponse(initialResponseState);
                }}
                loading={loading}
            />
            <NewPurchaseOfficeEquipmentRequestDialog
                key={"Purchase-Office-Equipment-Edit-" + editing?.uniqueId}
                open={editDialogOpen.open && editDialogOpen.type === "Office"}
                onClose={() => setEditDialogOpen(initialDialogState)}
                initialValues={editing}
                onSubmit={async updatedPurchase => {
                    await updatePurchaseOfficeEquipmentRequest(editing?.uniqueId, updatedPurchase);
                    getApprovedPurchases();
                    setEditDialogOpen(initialDialogState);
                }}
                downloadFile={downloadFile}
                isAdmin={roles.is_purchase_order_creator}
                loading={loading}
                getVendorsBySupplier={getVendorsBySupplier}
                vendorsBySupplier={vendorsBySupplier}
                legalEntities={legalEntities}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
                taxOptions={taxOptions}
                populateVendors={populateVendors}
            />

            <PurchaseOtherDetailsDialog
                key={"Purchase-Other-Details-" + purchaseDetails?.data?.uniqueId}
                details={purchaseDetails.data}
                onClose={() => setPurchaseDetails(initialDetailsState)}
                open={purchaseDetails.open && purchaseDetails.type === "Other"}
                isAdmin={false}
                downloadFile={downloadFile}
                onSubmit={async updatedPurchaseRequest => {
                    await updatePurchaseOtherRequestStatus(purchaseDetails.data.uniqueId, updatedPurchaseRequest);
                    getApprovedPurchases();
                    setPurchaseDetails(initialDetailsState);
                }}
                loading={loading}
            />
            <PurchaseOtherResponseDialog
                key={"Purchase-Other-Respond-" + purchaseResponse.data?.uniqueId}
                details={purchaseResponse.data}
                onClose={() => setPurchaseResponse(initialResponseState)}
                open={purchaseResponse.open && purchaseResponse.type === "Other"}
                onSubmit={async (updatedPurchaseRequest) => {
                    await updatePurchaseOtherRequestStatus(purchaseResponse.data.uniqueId, updatedPurchaseRequest);
                    getApprovedPurchases();
                    setPurchaseResponse(initialResponseState);
                }}
                loading={loading}
            />
            <NewPurchaseOtherRequestDialog
                key={"Purchase-Other-Edit-" + editing?.uniqueId}
                open={editDialogOpen.open && editDialogOpen.type === "Other"}
                onClose={() => setEditDialogOpen(initialDialogState)}
                initialValues={editing}
                onSubmit={async updatedPurchase => {
                    await updatePurchaseOtherRequest(editing?.uniqueId, updatedPurchase);
                    getApprovedPurchases();
                    setEditDialogOpen(initialDialogState);
                }}
                downloadFile={downloadFile}
                isAdmin={roles.is_purchase_order_creator}
                loading={loading}
                getVendorsBySupplier={getVendorsBySupplier}
                vendorsBySupplier={vendorsBySupplier}
                legalEntities={legalEntities}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
                taxOptions={taxOptions}
                populateVendors={populateVendors}
            />

            <AuditLoggingTableDialog
                open={auditLogDialogOpen.open}
                auditPayload={auditLog}
                type={auditLogDialogOpen.type}
                onClose={() => setAuditLogDialogOpen({open: false, type: ""})}
                loading={loading.type === loadingTypes.auditGetAuditLog}
            />

            <ConfirmationDialog
                open={deletePurchaseOrderConfirmationOpen}
                handleClose={() => setDeletePurchaseOrderConfirmationOpen(false)}
                headerText={"Confirm purchase order removal"}
                handleConfirmation={async confirm => {
                    if (confirm) {
                        let resp = await deletePurchaseOrder(selectedPurchase.uniqueId, selectedPurchase.purchase.purchaseType.tableName);
                        if (resp && resp.status && resp.status === 200) {
                            await createPurchaseOrder(selectedPurchase.uniqueId, selectedPurchase.purchase.purchaseType.tableName);
                            let updatedPurchase = {
                                status: selectedPurchase.purchase.status,
                                rejectReason: selectedPurchase.purchase.rejectReason,
                                lastResponseDate: formatShortDate(new Date()),
                                purchaseLevel: selectedPurchase.purchase.purchaseLevel,
                                purchaseOrderStatus: "PO Created"
                            };

                            await updatePurchaseRequestStatus(selectedPurchase.purchase.uniqueId, updatedPurchase);
                        }
                        getApprovedPurchases();
                    }
                    setDeletePurchaseOrderConfirmationOpen(false);
                }}
                promptText={"Are you sure you want to remove the purchase order? A purchase order already exists for this purchase. If you create a new purchase order, the previous purchase order will be removed."}
                loading={loading.type === loadingTypes.purchaseOrdersDeletePurchaseOrder}
            />
        </>
    );
}

const mapStateToProps = state => ({
    approvedPurchases: state.purchaseOrdersReducer.approvedPurchases,
    user: state.userReducer.currentUser,
    loading: state.loadingReducer.loading,
    projects: state.projectsReducer.projects,
    tenders: state.tendersReducer.tenders,
    vendorsBySupplier: state.vendorsReducer.vendorsBySupplier,
    legalEntities: state.legalEntitiesReducer.legalEntities,
    auditLog: state.auditReducer.auditLog,
    taxOptions: state.taxReducer.taxOptions,
    transactionSubTypes: state.auditReducer.transactionSubTypes,
    currenciesByLegalEntity: state.currenciesReducer.currenciesByLegalEntity
});

const mapDispatchToProps = dispatch => ({
    //region General API's
    getCurrentUser: () => getCurrentUser()(dispatch),
    downloadFile: (id, name) => dispatch(downloadFile(id, name)),
    getUserRoles: (roleType, roleSubType) => dispatch(getPermissionsByRole(roleType, roleSubType)),
    getCurrenciesByLegalEntity: (id) => dispatch(getCurrenciesByLegalEntity(id)),
    getTaxOptions: () => dispatch(getTaxOptions()),
    setError: (error, errorMessage) => dispatch(setError(error, errorMessage)),
    populateVendors: () => dispatch(populateVendors()),
    //endregion

    //region Purchase Order
    getApprovedPurchases: () => dispatch(purchaseOrderActions.getApprovedPurchases()),
    createPurchaseOrder: (id, type) => dispatch(purchaseOrderActions.createPurchaseOrder(id, type)),
    updatePurchaseRequestStatus: (id, body) => dispatch(purchaseOrderActions.updatePurchaseRequestStatus(id, body)),
    deletePurchaseOrder: (id, type) => dispatch(purchaseOrderActions.deletePurchaseOrder(id, type)),
    //endregion

    //region Project Purchase
    updatePurchaseProjectRequest: (id, body) => dispatch(purchaseActions.updatePurchaseProjectRequest(id, body)),
    updatePurchaseProjectRequestStatus: (id, body) => dispatch(purchaseActions.updatePurchaseProjectRequestStatus(id, body)),
    getAllProjects: (type) => dispatch(getAllProjects(type)),
    //endregion

    //region Tender Purchase
    updatePurchaseTenderRequest: (id, body) => dispatch(purchaseActions.updatePurchaseTenderRequest(id, body)),
    updatePurchaseTenderRequestStatus: (id, body) => dispatch(purchaseActions.updatePurchaseTenderRequestStatus(id, body)),
    getAllTenders: (type) => dispatch(getAllTenders(type)),
    //endregion

    //region IT Equipment Purchase
    updatePurchaseItEquipmentRequest: (id, body) => dispatch(purchaseActions.updatePurchaseItEquipmentRequest(id, body)),
    updatePurchaseItEquipmentRequestStatus: (id, body) => dispatch(purchaseActions.updatePurchaseItEquipmentRequestStatus(id, body)),
    //endregion

    //region Office Equipment Purchase
    updatePurchaseOfficeEquipmentRequest: (id, body) => dispatch(purchaseActions.updatePurchaseOfficeEquipmentRequest(id, body)),
    updatePurchaseOfficeEquipmentRequestStatus: (id, body) => dispatch(purchaseActions.updatePurchaseOfficeEquipmentRequestStatus(id, body)),
    //endregion

    //region Other Purchase
    updatePurchaseOtherRequest: (id, body) => dispatch(purchaseActions.updatePurchaseOtherRequest(id, body)),
    updatePurchaseOtherRequestStatus: (id, body) => dispatch(purchaseActions.updatePurchaseOtherRequestStatus(id, body)),
    //endregion

    //region Legal Entities and Suppliers
    getAllLegalEntities: () => dispatch(getAllLegalEntities()),
    getVendorsBySupplier: id => dispatch(getVendorsBySupplier(id)),
    //endregion

    //region Audit Logging
    getAuditLog: (id, type) => dispatch(getAuditLog(id, type)),
    getTransactionSubTypes: () => dispatch(getTransactionSubTypes())
    //endregion
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovedPurchases);