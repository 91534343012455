import axiosInstance from '../../assets/helpers/axiosInstance';
import {endLoading, startLoading} from "../loading/actions";
import * as loadingTypes from "../loading/types";
import fileDownload from "js-file-download";
import {setError} from "../error/actions";
import * as errorTypes from "../error/types";

export const CLEAR_UPLOAD_FILES = 'uploadFile/CLEAR_UPLOAD_FILES';
export const SET_UPLOAD_FILE = 'uploadFile/SET_UPLOAD_FILE';
export const SET_UPLOAD_PROGRESS = 'uploadFile/SET_UPLOAD_PROGRESS';
export const UPLOAD_FILE_SUCCESS = 'uploadFile/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'uploadFile/UPLOAD_FILE_FAIL';

export const clearUploadFiles = data => ({ type: CLEAR_UPLOAD_FILES, payload: data });
export const setUploadFile = data => ({ type: SET_UPLOAD_FILE, payload: data });

export const uploadFile = files => dispatch => {
  if (files.length) {
    files.forEach(async file => {
      const formPayload = new FormData();
      formPayload.append('file', file.file);
      try {
        await axiosInstance({
          url: '/files/upload_file',
          method: 'post',
          data: formPayload,
          onUploadProgress: progress => {
            const { loaded, total } = progress;
            const percentageProgress = Math.floor((loaded / total) * 100);
            dispatch({ type: SET_UPLOAD_PROGRESS, payload: { id: file.id, progress: percentageProgress.progress }});
          },
        })
        dispatch({ type: UPLOAD_FILE_SUCCESS, payload: file.id });
      } catch (error) {
        dispatch({ type: UPLOAD_FILE_FAIL, payload: file.id, error: error, errorMessage: error.message });
      }
    })
  }
};

export const downloadFile = (id, name) => async dispatch => {
  dispatch(startLoading(loadingTypes.fileDownload));
  try {
    const res = await axiosInstance.get(`/files/download/${id}`, {
      responseType: 'blob',
    });
    fileDownload(res.data, name);
  } catch (err) {
    dispatch(setError(errorTypes.fileDownload, "Something went wrong. Try again."));
  } finally {
    dispatch(endLoading());
  }
}
