import React, { Component } from 'react';

import classes from './Login.module.css';
import Grid from '@material-ui/core/Grid';
// import TextField from '@material-ui/core/TextField';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
// import MailIcon from '@material-ui/icons/Mail';
// import LockIcon from '@material-ui/icons/Lock';
// import IconButton from '@material-ui/core/IconButton';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import googleLogo from '../../assets/images/google-logo.png';
import { GOOGLE_AUTH_URL } from '../../assets/helpers/globals';
import Logo from "../../components/Logo/Logo";

class Login extends Component {

    state = {
        showPassword : false
    }

    handleClickShowPassword = () => {
        this.setState((prevState) =>{
            return{
               showPassword : !prevState.showPassword
            } 
        });
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    render(){
        return (

            <div className={classes.Wrapper}>
                <div className={classes.ColorContainer} />


                <Grid container className={classes.Container} justify="center">

                    <Grid item xs={12} sm={6} md={5} lg={4} className={classes.Login}>

                        <div className={classes.HeaderContainer}>

                            <h1 className={classes.Header}>Welcome Back</h1>

                        </div>
                        <form className={classes.Form}>
                            <div className={classes.LinkContainer}>
                                <Link href={GOOGLE_AUTH_URL} className={classes.Google}>
                                    <img className={classes.GoogleLogo} src={googleLogo} alt="google sign in" />
                                    Sign in with Google
                                </Link>
                            </div>
                            {/* <TextField
                                id="email"
                                label="Email"
                                fullWidth
                                className={classes.FieldGroup}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <MailIcon className={classes.Icon} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField 
                                id="password"
                                label="Password"
                                type={this.state.showPassword ? 'text' : 'password'}
                                fullWidth
                                className={classes.FieldGroup}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword.bind(this)}
                                                >
                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                            <LockIcon className={classes.Icon} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button className={classes.Submit}>
                                Log in
                            </Button>
                            <Button className={classes.ForgotPassword}>
                                Forgot Password ?
                            </Button> */}

                        </form>
                    </Grid>

                </Grid>
                <div className={classes.Logo}>
                    <Logo light />
                </div>
                </div>

        );
    }
}

export default Login;