import axiosInstance from '../../assets/helpers/axiosInstance';
import { endLoading, startLoading } from "../loading/actions";
import * as loadingTypes from "../loading/types";
import {setError} from "../error/actions";
import * as errorTypes from "../error/types";

export const SET_TAX_OPTIONS = "taxOptions/SET_TAX_OPTIONS";

const setTaxOptions = taxOptions => ({
    type: SET_TAX_OPTIONS,
    taxOptions,
});

export const getTaxOptions = () => async dispatch => {
    dispatch(startLoading(loadingTypes.taxGetTaxOptions));
    try {
        const res = await axiosInstance.get(`/xeroTaxOptions/getAll`);
        dispatch(setTaxOptions(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.taxGetTaxOptions, `${err.message ? err.message + " at " + errorTypes.taxGetTaxOptions : "Something went wrong at " + errorTypes.taxGetTaxOptions }`));
    } finally {
        dispatch(endLoading());
    }
};