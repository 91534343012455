import React from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import styles from "./CustomDialogTitle.module.css";
import ReplyIcon from "@material-ui/icons/Reply";
import IconLink from "@material-ui/icons/CloudQueue";
import {Tooltip} from "@material-ui/core";


const CustomDialogTitle = ({
							   children,
							   classes,
							   onClose,
							   onEdit,
							   onRespond,
							   onDelete,
							   onLink,
							   onLinkTitle,
							   showTextContent,
							   ...other
						   }) =>
	<MuiDialogTitle
		className={styles.MuiDialogTitle}
		disableTypography
		{...other}
	>
		{showTextContent ? (
			<Typography variant="h6" className={styles.DialogTitle}>
				{children}
			</Typography>
		) : null}
		<div className={styles.ToolbarActions}>
			{onRespond ? (
				<IconButton onClick={onRespond} className={styles.ToolbarActionButtons}>
					<ReplyIcon className={styles.ActionIcons} />
				</IconButton>
			) : null}
			{onEdit ? (
				<IconButton onClick={onEdit} className={styles.ToolbarActionButtons}>
					<EditIcon className={styles.ActionIcons} />
				</IconButton>
			) : null}
			{onDelete ? (
				<IconButton
					onClick={onDelete}
					className={styles.ToolbarActionButtons}
				>
					<DeleteIcon className={styles.ActionIcons} />
				</IconButton>
			) : null}
			{onLink ? (
				<Tooltip title={onLinkTitle} >
					<IconButton
						onClick={() => window.open(onLink, '_blank')}
						className={styles.ToolbarActionButtons}
					>
						<IconLink   className={styles.ActionIcons}  />
				</IconButton>
				</Tooltip>
				) : null}
			{onClose ? (
				<IconButton onClick={onClose} className={styles.ToolbarActionButtons}>
					<CloseIcon className={styles.ActionIcons} />
				</IconButton>
			) : null}
		</div>
	</MuiDialogTitle>

export default React.memo(CustomDialogTitle);