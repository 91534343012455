import * as actionTypes from "./actions";

const initialState = {
    approvedPurchases: [],
    allPurchaseOrders: []
};

const setApprovedPurchases = (state, { approvedPurchases }) => ({ ...state, approvedPurchases });
const setAllPurchaseOrders = (state, { allPurchaseOrders }) => ({ ...state, allPurchaseOrders });

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_APPROVED_PURCHASES:
            return setApprovedPurchases(state, action);
        case actionTypes.SET_ALL_PURCHASE_ORDERS:
            return setAllPurchaseOrders(state, action);
        default:
            return state;
    }
};
