import React from "react";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {formatShortDate} from "../../../../assets/helpers/globals";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Loader from "../../../../components/Loader/Loader";
import * as loadingTypes from "../../../../store/loading/types";
import styled, {css} from "styled-components";
import {Radios, TextField} from "mui-rff";
import Grid from "@material-ui/core/Grid";
import {Form} from "react-final-form";
import {focusOnError} from "../../../../assets/helpers/finalFormHelpers";

const LoadingDiv = styled.div`
  position: absolute;
  bottom: 11px;
  right: 36px;
`;

const H3 = styled.h3`
  margin: 0;
`;

const ActionButton = styled(Button)`
  margin: 0 5px;
`;

const FormInput = styled.form`
  width: 100%;
`;

const FormDialogContent = styled(DialogContent)`
  padding: 10px 10px;
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const StatusRadios = styled(Radios)`
  ${props => props.data[0]?.label === "Approved" && css`&.Mui-checked { color: Green }`}
  ${props => props.data[0]?.label === "Rejected" && css`&.Mui-checked { color: Red }`}
`;

const ResponseDialog = ({
                            details,
                            onClose,
                            open,
                            onSubmit,
                            loading,
                        }) => {
    const formFields = [
        {
            externalLabel: "Status",
            size: 12,
            field: (
                <>
                    <StatusRadios
                        name="status"
                        formControlProps={{margin: 'none'}}
                        radioGroupProps={{row: true}}
                        data={[
                            {label: "Approved", value: "Approved"},
                        ]}
                    />
                    <StatusRadios
                        name="status"
                        formControlProps={{margin: 'none'}}
                        radioGroupProps={{row: true}}
                        data={[
                            {label: "Rejected", value: "Rejected"},
                        ]}
                        showError={() => false}
                    />
                </>
            ),
        },
        {
            externalLabel: "Comment",
            size: 12,
            field: (
                <TextField
                    name="rejectReason"
                    margin="none"
                    required
                    placeholder={"Comment"}
                    multiline
                />
            ),
        },
    ]

    const validate = values => {
        const errors = {}
        if (!values.status)
            errors.status = "Required"

        if (values.status === "Rejected")
            if (!values.rejectReason)
                errors.rejectReason = "Required"

        return errors
    }

    const onSubmitForm = async values => {
        let modifiedPayload = {...values, lastResponseDate: formatShortDate(new Date())};

        if (values.status === "Approved")
            modifiedPayload = {...modifiedPayload, purchaseOrderStatus: "PO Pending"}

        await onSubmit(modifiedPayload);
    }

    let submit;

    return <Form
        onSubmit={onSubmitForm}
        validate={validate}
        decorators={[focusOnError]}
        initialValues={
            details.purchase?.status !== "Awaiting Approval" ?
                {status: details.purchase?.status, rejectReason: details.purchase?.rejectReason} :
                {}
        }
        render={({handleSubmit, submitting, pristine}) => {
            submit = handleSubmit
            return (
                <Dialog
                    aria-labelledby="other-purchase-request-details-dialog-title"
                    onClose={onClose}
                    fullWidth
                    maxWidth={"sm"}
                    open={open}
                >
                    <CustomDialogTitle
                        id="other-purchase-request-details-dialog-title"
                        onClose={onClose}
                        showTextContent
                    >
                        Other Purchase Response
                    </CustomDialogTitle>
                    <FormDialogContent dividers>
                        <ListItem>
                            <FormInput noValidate>
                                <Grid container alignItems="flex-start" spacing={1}>
                                    {formFields.map((item, idx) => (
                                        <Grid item xs={item.size} key={idx}>
                                            <ListItemText>
                                                <H3>{item.externalLabel}</H3>
                                                {item.field}
                                            </ListItemText>
                                        </Grid>
                                    ))}
                                </Grid>
                            </FormInput>
                        </ListItem>
                    </FormDialogContent>

                    <DialogActions>
                        <ActionButton
                            onClick={onClose}
                            type="button"
                            disabled={submitting}
                            color="primary"
                        >
                            Cancel
                        </ActionButton>
                        <ActionButton
                            onClick={event => submit(event)}
                            type="submit"
                            disabled={submitting || pristine}
                            color="default"
                        >
                            Submit
                        </ActionButton>
                        <LoadingDiv>
                            <Loader loading={loading.type === loadingTypes.purchasesUpdatePurchaseOtherRequestStatus}
                                    size="small"/>
                        </LoadingDiv>
                    </DialogActions>
                </Dialog>
            )
        }}
    />
}

export default ResponseDialog;