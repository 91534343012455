import * as actionTypes from './actions';

const initialState = {
    projects: [],
    projectsStatuses: []
};

const setAllProjects = (state, { projects }) => ({ ...state, projects });
const setAllProjectsStatuses = (state, { projectsStatuses }) => ({ ...state, projectsStatuses })

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ALL_PROJECTS:
            return setAllProjects(state, action);
        case actionTypes.SET_ALL_PROJECTS_STATUSES:
            return setAllProjectsStatuses(state, action);
        default:
            return state;
    }
};
