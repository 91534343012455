import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import styles from "./ConfirmationDialog.module.css";
import Button from "../../Buttons/Button/Button";

const ConfirmationDialog = props => {
	return (
		<Dialog open={props.open} onClose={props.handleClose}>
			<DialogTitle disableTypography onClose={props.handleClose}>
				<h3 className={styles.RecurringHeader}>{props.headerText ? props.headerText : "Confirm cancellation"}</h3>
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1">{props.promptText}</Typography>
			</DialogContent>

			<DialogActions className={styles.DialogActions}>
				<Button
					color="secondary"
					onClick={() => props.handleConfirmation(false)}
					disabled={props.loading}
				>
					No
				</Button>
				<Button
					hasLoader={true}
					loading={props.loading}
					disabled={props.loading}
					color="primary"
					onClick={() => props.handleConfirmation(true)}
				>
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default React.memo(ConfirmationDialog);
