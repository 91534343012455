import React from "react";
import * as loadingTypes from "../../../store/loading/types";
import styled from "styled-components";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {KeyboardDatePicker, TextField, Autocomplete, Radios} from "mui-rff";
import Dialog from "@material-ui/core/Dialog";
import {Form} from "react-final-form";
import Loader from "../../../components/Loader/Loader";
import DateFnsUtils from '@date-io/date-fns';
import {DialogActions} from "@material-ui/core";
import {formatShortDate, sleep} from "../../../assets/helpers/globals";
import {NumberFormatOnInput, WhenFieldChanges, Condition, focusOnError } from "../../../assets/helpers/finalFormHelpers";
import CustomDialogTitle from "../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";

const LoadingDiv = styled.div`
  position: absolute;
  bottom: 11px;
  right: 36px;
`;

const FormDialogContent = styled(DialogContent)`
  padding: 24px 30px;
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const ActionButton = styled(Button)`
  margin: 0 5px;
`;

function NewRequestDialog({
                              open,
                              onClose,
                              onSubmit,
                              initialValues,
                              loading,
                              projects,
                              legalEntities,
                              vendorsByCustomer,
                              getVendorsByCustomer,
                              taxOptions,
                              currenciesByLegalEntity,
                              getCurrenciesByLegalEntity
}) {
    const whenFieldChanges = <>
        <WhenFieldChanges
            field="legalEntity"
            set="vendor"
            to={''}
            shouldChangeHandler={(legalEntity) => legalEntity !== null}
        />
        <WhenFieldChanges
            field="legalEntity"
            set="vendor"
            to={''}
            shouldChangeHandler={(legalEntity) => legalEntity === null}
        />
        <WhenFieldChanges
            field="legalEntity"
            set="currency"
            to={''}
            shouldChangeHandler={(legalEntity) => legalEntity !== null}
        />
        <WhenFieldChanges
            field="legalEntity"
            set="currency"
            to={''}
            shouldChangeHandler={(legalEntity) => legalEntity === null}
        />
    </>

    const formFields = values => [
        {
            field: (
                <Grid item xs={12}>
                    <Autocomplete
                        label="Billing To"
                        name="legalEntity"
                        noOptionsText={"No legal entities found"}
                        required
                        // filterSelectedOptions
                        // disableClearable
                        onChange={(event, value ) => {
                            if (value) {
                                getVendorsByCustomer(value.uniqueId);
                                getCurrenciesByLegalEntity(value.uniqueId);
                            }
                        }}
                        options={["", ...legalEntities.sort((a, b) => a.entityName.localeCompare(b.entityName))]}
                        getOptionLabel={option => option ? `${option.entityName}` : ""}
                        getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                        filterOptions={(options, params) => options.filter((option) => option !== "" && option.entityName.toLowerCase().includes(params.inputValue.toLowerCase()))}
                        handleHomeEndKeys
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Radios
                        label="Client"
                        name="vendorType"
                        margin="none"
                        disabled={!values.legalEntity}
                        required
                        radioGroupProps={{ row: true }}
                        data={[
                            {label: 'Existing Client', value: 'existingVendor'},
                            {label: 'Other Client', value: 'otherVendor'},
                        ]}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Condition when="vendorType" is="existingVendor">
                    <Grid item xs={12}>
                        <Autocomplete
                            label="Client Name"
                            name="vendor"
                            noOptionsText={"No clients found"}
                            required
                            disabled={!values.legalEntity}
                            options={["", ...vendorsByCustomer.sort((a, b) => a.vendorName.localeCompare(b.vendorName))]}
                            getOptionLabel={option => option ? `${option.vendorName}` : ""}
                            getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                            filterOptions={(options, params) => options.filter((option) => option !== "" && option.vendorName.toLowerCase().includes(params.inputValue.toLowerCase()))}
                            handleHomeEndKeys
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Condition when="vendorType" is="otherVendor">
                    <Grid item xs={12}>
                        <TextField
                            label="Other Client Name"
                            name="vendorOther"
                            margin="none"
                            disabled={!values.legalEntity}
                            required
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Grid item xs={6}>
                    <KeyboardDatePicker
                        dateFunsUtils={DateFnsUtils}
                        label="Invoice Date"
                        name="invoiceDate"
                        margin="none"
                        showTodayButton
                        placeholder={"dd-mm-yyyy"}
                        required
                        format="dd-MM-yyyy"
                        // format="EEEE, d MMMM, y"
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={6}>
                    <KeyboardDatePicker
                        dateFunsUtils={DateFnsUtils}
                        label="Invoice Due Date"
                        name="invoiceDueDate"
                        margin="none"
                        showTodayButton
                        required
                        placeholder={"dd-mm-yyyy"}
                        format="dd-MM-yyyy"
                        // format="EEEE, d MMMM, y"
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Autocomplete
                        label="Project Number"
                        name="project"
                        noOptionsText={"No projects found"}
                        required
                        options={["", ...projects.sort((a, b) => b.projectNumber.localeCompare(a.projectNumber))]}
                        getOptionLabel={option => option ? `${option.projectNumber}: ${option.projectName}` : ""}
                        getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                        filterOptions={(options, params) => options.filter((option) => option !== "" &&
                            (option.projectNumber.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                option.projectName.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                (option.projectNumber + ": " + option.projectName).toLowerCase().includes(params.inputValue.toLowerCase())))}
                        handleHomeEndKeys
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <TextField
                        label="Contract Number / PO Number / Order Number"
                        name="contractNumber"
                        margin="none"
                        required
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <TextField
                        label="Full Description"
                        name="fullDescription"
                        margin="none"
                        required
                        multiline
                    />
                </Grid>
            ),
        },
        {
            size: 3,
            field: (
                <Grid item xs={4}>
                    <Autocomplete
                        label="Currency"
                        name="currency"
                        noOptionsText={"No currencies found"}
                        disabled={!values.legalEntity}
                        required
                        options={["", ...currenciesByLegalEntity.sort((a, b) => a.name.localeCompare(b.name))]}
                        getOptionLabel={option => option ? option.name : ""}
                        getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                        filterOptions={(options, params) => options.filter((option) => option !== "" &&
                            (option.name.toLowerCase().includes(params.inputValue.toLowerCase())))}
                        handleHomeEndKeys
                        // disableClearable
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={4}>
                    <TextField
                        label="Invoice Amount"
                        name="invoiceAmount"
                        margin="none"
                        required
                        InputProps={{
                            inputComponent: NumberFormatOnInput,
                        }}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={4}>
                    <Autocomplete
                        label="Tax Option"
                        name="xeroTaxOption"
                        noOptionsText={"No tax options found"}
                        required
                        options={["", ...taxOptions.sort((a, b) => a.taxOptionText.localeCompare(b.taxOptionText))]}
                        getOptionLabel={option => option ? option.taxOptionText : ""}
                        getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                        filterOptions={(options, params) => options.filter((option) => option !== "" &&
                            (option.taxOptionText.toLowerCase().includes(params.inputValue.toLowerCase())))}
                        handleHomeEndKeys
                        // disableClearable
                    />
                </Grid>
            ),
        },
    ];

    const validate = values => {
        const errors = {}

        if (!values.legalEntity)
            errors.legalEntity = 'Required'
        if (values.vendorType === "existingVendor" && !values.vendor)
            errors.vendor = 'Required'
        if (values.vendorType === "otherVendor" && !values.vendorOther)
            errors.vendorOther = 'Required'
        if (!values.invoiceDate)
            errors.invoiceDate = 'Required'
        if (!values.invoiceDueDate)
            errors.invoiceDueDate = 'Required'
        if (isNaN(Date.parse(values.invoiceDate)))
            errors.invoiceDate = 'Invoice date is not a valid date'
        if (isNaN(Date.parse(values.invoiceDueDate)))
            errors.invoiceDueDate = 'Invoice due date is not a valid date'
        if (!values.project)
            errors.project = 'Required'
        if (!values.contractNumber)
            errors.contractNumber = 'Required'
        if (!values.fullDescription)
            errors.fullDescription = 'Required'
        if (!values.currency)
            errors.currency = 'Required'
        if (!values.invoiceAmount)
            errors.invoiceAmount = 'Required'
        if (!values.xeroTaxOption)
            errors.xeroTaxOption = 'Required'

        return errors
    }

    const onSubmitForm = async values => {
        // let errors = {}
        // if (!values.invoiceDate) errors.invoiceDate = 'Required'
        // if (!values.invoiceDueDate) errors.invoiceDueDate = 'Required'
        // if (!isEmptyObject(errors))
        //     return errors
        // else {
            let formData = new FormData();

            formData.append('project', values.project.uniqueId);
            formData.append('legalEntity', values.legalEntity.uniqueId)

            if (values.vendorType === "existingVendor" && values.vendor)
                formData.append('vendor', values.vendor.uniqueId);
            else
                formData.append('vendorOther', values.vendorOther);

            formData.append('invoiceAmount', values.invoiceAmount)
            formData.append('currency', values.currency.uniqueId)
            formData.append('xeroTaxOption', values.xeroTaxOption.uniqueId);
            formData.append('fullDescription', values.fullDescription)
            formData.append('contractNumber', values.contractNumber)
            formData.append('invoiceDueDate', values.invoiceDueDate)
            formData.append('invoiceDate', values.invoiceDate)
            formData.append('requestSubmittedDate', formatShortDate(new Date()));
            formData.append('status', "Pending");
            formData.append('comment', "");

            await onSubmit(formData)
        // }
    }

    let submit;

    return (
       <Form
            onSubmit={onSubmitForm}
            validate={validate}
            decorators={[focusOnError]}
            initialValues={initialValues ? initialValues : {
                vendorType: "existingVendor",
                vendor: '',
                currency: '',
                xeroTaxOption: taxOptions.find(taxOption => taxOption.taxOptionText === "Tax Exclusive") }}
            render={({ handleSubmit, form, submitting, pristine, values }) => {
                submit = handleSubmit
                return (
                    <Dialog
                    aria-labelledby="add-invoice-request-dialog-title"
                    onClose={onClose}
                    fullWidth
                    maxWidth={"sm"}
                    open={open}
                >
                    <CustomDialogTitle
                        id="add-invoice-request-dialog-title"
                        onClose={onClose}
                        showTextContent
                    >
                        Add Invoice Request
                    </CustomDialogTitle>
                    <FormDialogContent dividers>
                        <form onSubmit={handleSubmit} noValidate>
                            {whenFieldChanges}
                            <Grid container alignItems="flex-start" spacing={4}>
                                {formFields(values).map((item, idx) => (
                                    <React.Fragment key={idx}>
                                        {item.field}
                                    </React.Fragment>
                                ))}
                            </Grid>
                        </form>
                    </FormDialogContent>
                    <DialogActions>
                        <ActionButton
                            onClick={onClose}
                            type="button"
                            disabled={submitting}
                            color="primary"
                        >
                            Cancel
                        </ActionButton>
                        <ActionButton
                            type="button"
                            onClick={form.reset}
                            color="primary"
                            disabled={submitting || pristine}
                        >
                            Reset
                        </ActionButton>
                        <ActionButton
                            onClick={event => {
                                const promise = submit(event);
                                promise && promise.then(async() => {
                                    await sleep(300);
                                    form.reset();
                                })
                                return promise;
                            }}
                            type="submit"
                            disabled={submitting || pristine}
                            color="default"
                        >
                            Submit
                        </ActionButton>
                        <LoadingDiv>
                            <Loader loading={loading.type === loadingTypes.invoicesAddInvoiceRequest || loading.type === loadingTypes.invoicesUpdateInvoiceRequest} size="small" />
                        </LoadingDiv>
                    </DialogActions>
                </Dialog>
            )}}
        />
    )}

export default NewRequestDialog;
