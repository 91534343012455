import * as actionTypes from './actions';

const initialState = {
    legalEntities: [],
    allDeliveryAddresses: [],
    deliveryAddressPerLegalEntity: []
};

const setAllLegalEntities = (state, { legalEntities }) => ({ ...state, legalEntities });
const setAllDeliveryAddresses = (state, { allDeliveryAddresses }) => ({ ...state, allDeliveryAddresses });
const setDeliveryAddressPerLegalEntity = (state, { deliveryAddressPerLegalEntity }) => ({ ...state, deliveryAddressPerLegalEntity });

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ALL_LEGAL_ENTITIES:
            return setAllLegalEntities(state, action);
        case actionTypes.SET_ALL_DELIVERY_ADDRESSES:
            return setAllDeliveryAddresses(state, action);
        case actionTypes.SET_DELIVERY_ADDRESS_PER_LEGAL_ENTITY:
            return setDeliveryAddressPerLegalEntity(state, action);
        default:
            return state;
    }
};