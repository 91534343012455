import React from "react";
import MuiButton from "@material-ui/core/Button";
import Loader from "../../Loader/Loader";
import styles from "./Button.module.css";

const Button = props => {
	return props.hasLoader ? (
		<div className={styles.ActionButtonWrapper} style={props.style}>
			<MuiButton
				variant="contained"
				color={props.color}
				onClick={props.onClick}
				className={styles.Button}
				disabled={props.disabled}
			>
				{props.children}
			</MuiButton>
			{<Loader loading={props.loading} center size="small" light />}
		</div>
	) : (
		<MuiButton
			variant="contained"
			color={props.color}
			onClick={props.onClick}
			className={styles.Button}
			style={props.style}
			disabled={props.disabled}
		>
			{props.children}
		</MuiButton>
	);
};

export default Button;
