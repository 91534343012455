import React from 'react';
import Grid from '@material-ui/core/Grid';
import DigitalBoardsIFrame from '../../../components/IFrames/DigitalBoardsIFrame';

const CurrentEPCTenders = () => {
    return (
        <Grid 
            container
            justify="center"
            alignItems="center"
            style={{height : "100%"}}>
            <DigitalBoardsIFrame src="https://boards.epcmdev.com/epctenders/" title="Current EPC Tenders" />
         </Grid>
    );
};

export default CurrentEPCTenders;