import React , { Fragment } from 'react';
import PropTypes from 'prop-types';

import defaultLogo from '../../assets/images/LogoColor.png';
import lightLogo from '../../assets/images/EPCM_Logo.png';
import classes from './Logo.module.css';

const Logo = (props) => {

    const logoImg = props.light ? (
        <div className={classes.Logo} style={{height: props.height}}>
            <img src={lightLogo} alt="EPCM Logo Light"/>
        </div> 
    ) : 
    (
        <div className={classes.Logo} style={{height: props.height}}>
            <img src={defaultLogo} alt="EPCM Logo"/>
        </div> 
    );

    return (
        <Fragment>
            {logoImg}
        </Fragment>
    );
};

export default Logo;

Logo.propTypes = {
    height : PropTypes.number
}