import React, { Fragment, useRef } from "react";
import Button from "../../Buttons/Button/Button";

const FileUpload = props => {
	const hiddenFileInput = useRef(null);
	const handleClick = () => hiddenFileInput.current.click();

	return (
		<Fragment>
			<Button onClick={handleClick}>Upload a file</Button>
			<input
				type="file"
				multiple
				ref={hiddenFileInput}
				onChange={props.onChange}
				style={{ display: "none" }}
			/>
			{props.value.length ? (
				<Fragment>
					<Button style={{ marginLeft: "15px" }} onClick={() => props.clear()}>
						Clear Files
					</Button>
				</Fragment>
			) : null}

			{props.value.map(file => (
				<p key={`UPLOAD_FILE_${file.name}`}>- {file.name}</p>
			))}
		</Fragment>
	);
};
export default FileUpload;
