import axiosInstance from '../../assets/helpers/axiosInstance';
// import { setSuccessMessage } from "../success/actions";
import { endLoading, startLoading } from "../loading/actions";
import * as loadingTypes from "../loading/types";
import {setError} from "../error/actions";
import * as errorTypes from "../error/types";
// import {setSuccessMessage} from "../success/actions";

export const SET_ALL_TAX_TYPES = "xero/SET_ALL_TAX_TYPES";
export const SET_ALL_LATEST_ACCOUNTS = "xero/SET_ALL_LATEST_ACCOUNTS";
export const SET_ALL_ACCOUNTS = "xero/SET_ALL_ACCOUNTS";
export const SET_ALL_TRACKING = "xero/SET_ALL_TRACKING";
export const SET_TAX_TYPES_BY_LEGAL_ENTITY = "xero/SET_TAX_TYPES_BY_LEGAL_ENTITY";
export const SET_ACCOUNTS_BY_LEGAL_ENTITY = "xero/SET_ACCOUNTS_BY_LEGAL_ENTITY";
export const SET_TRACKING_BY_LEGAL_ENTITY = "xero/SET_TRACKING_BY_LEGAL_ENTITY";

const setAllTaxTypes = allTaxTypes => ({ type: SET_ALL_TAX_TYPES, allTaxTypes });
const setAllLatestAccounts = allLatestAccounts => ({ type: SET_ALL_LATEST_ACCOUNTS, allLatestAccounts });
const setAllAccounts = allAccounts => ({ type: SET_ALL_ACCOUNTS, allAccounts });
const setAllTracking = allTracking => ({ type: SET_ALL_TRACKING, allTracking });
const setTaxTypesByLegalEntity = taxTypesByLegalEntity => ({ type: SET_TAX_TYPES_BY_LEGAL_ENTITY, taxTypesByLegalEntity });
const setAccountsByLegalEntity = accountsByLegalEntity => ({ type: SET_ACCOUNTS_BY_LEGAL_ENTITY, accountsByLegalEntity });
const setTrackingByLegalEntity = trackingByLegalEntity => ({ type: SET_TRACKING_BY_LEGAL_ENTITY, trackingByLegalEntity });

export const getAllTaxTypes = () => async dispatch => {
    dispatch(startLoading(loadingTypes.xeroGetAllTaxTypes));
    try {
        const res = await axiosInstance.get("/xero/taxTypes/getAll");
        dispatch(setAllTaxTypes(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.xeroGetAllTaxTypes, `${err.message ? err.message + " at " + errorTypes.xeroGetAllTaxTypes : "Something went wrong at " + errorTypes.xeroGetAllTaxTypes }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getAllLatestAccounts = () => async dispatch => {
    dispatch(startLoading(loadingTypes.xeroGetAllLatestAccounts));
    try {
        const res = await axiosInstance.get("/xero/accounts/getNewest");
        dispatch(setAllLatestAccounts(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.xeroGetAllLatestAccounts, `${err.message ? err.message + " at " + errorTypes.xeroGetAllLatestAccounts : "Something went wrong at " + errorTypes.xeroGetAllLatestAccounts }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getAllAccounts = () => async dispatch => {
    dispatch(startLoading(loadingTypes.xeroGetAllAccounts));
    try {
        const res = await axiosInstance.get("/xero/accounts/getAll");
        dispatch(setAllAccounts(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.xeroGetAllAccounts, `${err.message ? err.message + " at " + errorTypes.xeroGetAllAccounts : "Something went wrong at " + errorTypes.xeroGetAllAccounts }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getAllTracking = () => async dispatch => {
    dispatch(startLoading(loadingTypes.xeroGetAllTracking));
    try {
        const res = await axiosInstance.get("/xero/tracking/getAll");
        dispatch(setAllTracking(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.xeroGetAllTracking, `${err.message ? err.message + " at " + errorTypes.xeroGetAllTracking : "Something went wrong at " + errorTypes.xeroGetAllTracking }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getTaxTypesByLegalEntity = (id) => async dispatch => {
    dispatch(startLoading(loadingTypes.xeroGetTaxTypesByLegalEntity));
    try {
        const res = await axiosInstance.get(`/xero/taxTypes/getTaxTypeByLegalEntity?uniqueId=${id}`);
        dispatch(setTaxTypesByLegalEntity(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.xeroGetTaxTypesByLegalEntity, `${err.message ? err.message + " at " + errorTypes.xeroGetTaxTypesByLegalEntity : "Something went wrong at " + errorTypes.xeroGetTaxTypesByLegalEntity }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getAccountsByLegalEntity = (id) => async dispatch => {
    dispatch(startLoading(loadingTypes.xeroGetAccountsByLegalEntity));
    try {
        const res = await axiosInstance.get(`/xero/accounts/getAccountsByLegalEntity?uniqueId=${id}`);
        dispatch(setAccountsByLegalEntity(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.xeroGetAccountsByLegalEntity, `${err.message ? err.message + " at " + errorTypes.xeroGetAccountsByLegalEntity : "Something went wrong at " + errorTypes.xeroGetAccountsByLegalEntity }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getTrackingByLegalEntity = (id) => async dispatch => {
    dispatch(startLoading(loadingTypes.xeroGetTrackingByLegalEntity));
    try {
        const res = await axiosInstance.get(`/xero/tracking/getTrackingPerLegalEntity?uniqueId=${id}`);
        dispatch(setTrackingByLegalEntity(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.xeroGetTrackingByLegalEntity, `${err.message ? err.message + " at " + errorTypes.xeroGetTrackingByLegalEntity : "Something went wrong at " + errorTypes.xeroGetTrackingByLegalEntity }`));
    } finally {
        dispatch(endLoading());
    }
};

export const populateAll = () => async dispatch => {
    dispatch(startLoading(loadingTypes.xeroPopulateAll));
    try {
        await axiosInstance.get(`/xeroDefault/populateAll`);
        // dispatch(setSuccessMessage("Success! Data has been populated."));
    } catch (err) {
        dispatch(setError(errorTypes.xeroPopulateAll, `${err.message ? err.message + " at " + errorTypes.xeroPopulateAll : "Something went wrong at " + errorTypes.xeroPopulateAll }`));
    } finally {
        dispatch(endLoading());
    }
};

export const populateTaxTypeCurrencyAddress = () => async dispatch => {
    dispatch(startLoading(loadingTypes.xeroPopulateCurrencyAddressTaxTypes));
    try {
        await axiosInstance.get(`/xeroDefault/populateCurrencyAddressTaxTypes`);
        // dispatch(setSuccessMessage("Success! Currency address tax types have been populated."));
    } catch (err) {
        dispatch(setError(errorTypes.xeroPopulateCurrencyAddressTaxTypes, `${err.message ? err.message + " at " + errorTypes.xeroPopulateCurrencyAddressTaxTypes : "Something went wrong at " + errorTypes.xeroPopulateCurrencyAddressTaxTypes }`));
    } finally {
        dispatch(endLoading());
    }
};

export const populateTrackingOptions = () => async dispatch => {
    dispatch(startLoading(loadingTypes.xeroPopulateTrackingOptions));
    try {
        await axiosInstance.get(`/xeroDefault/populateTrackingOptions`);
        // dispatch(setSuccessMessage("Success! Tracking options have been populated."));
    } catch (err) {
        dispatch(setError(errorTypes.xeroPopulateTrackingOptions, `${err.message ? err.message + " at " + errorTypes.xeroPopulateTrackingOptions : "Something went wrong at " + errorTypes.xeroPopulateTrackingOptions }`));
    } finally {
        dispatch(endLoading());
    }
};

export const populateVendors = () => async dispatch => {
    dispatch(startLoading(loadingTypes.xeroPopulateVendors));
    try {
        await axiosInstance.get(`/xeroDefault/populateVendors`);
        // dispatch(setSuccessMessage("Success! Vendors have been populated."));
    } catch (err) {
        dispatch(setError(errorTypes.xeroPopulateVendors, `${err.message ? err.message + " at " + errorTypes.xeroPopulateVendors : "Something went wrong at " + errorTypes.xeroPopulateVendors }`));
    } finally {
        dispatch(endLoading());
    }
};