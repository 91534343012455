import React from 'react'
import SvgIcon from "@material-ui/core/SvgIcon";

export default function LogoutIcon(props){
    return(
        <SvgIcon {...props}>
            <path  d="M707.37,53.67c.61,2,2.45,1.48,3.86,1.9,22,6.47,35.18,27.06,32.33,50.54C741,127.33,722,144.28,699.76,144.9c-11.08.31-22.19.09-33.28.09q-196.38,0-392.77,0c-41.7,0-66.57,25-66.57,67q0,328.61,0,657.2c0,42.39,24.87,67.27,67.25,67.27q211.13,0,422.25,0c33.42,0,55.7,30.62,44.83,61.06a45.53,45.53,0,0,1-43.07,30.16c-31.38.21-62.76.06-94.15.06q-166.9,0-333.8,0C194.82,1027.66,134,978,118.67,904.09a152,152,0,0,1-2.88-31.17q.06-331.93,0-663.86c0-75.71,48-135.46,121.93-152.36,2.45-.56,5.95.47,7.32-3Z" ></path>
            <path  d="M808.29,495.06c-46.91-46.88-91.91-92-137.1-136.9-14.13-14-19.37-30.35-13.23-49.35,9.93-30.71,48.42-41.08,72.84-19.95,2.64,2.28,5.06,4.8,7.53,7.26Q842.24,400,946.12,503.94c24.14,24.14,24.13,49.44,0,73.61Q841.19,682.48,736.25,787.36c-17.63,17.62-39.65,21-58.65,9.39-25.88-15.86-29.43-50.12-7.1-72.61Q734.84,659.36,799.63,595c2.35-2.34,5.58-3.8,8.4-5.67l-2.06-3H795.91q-172.62,0-345.22,0c-25.8,0-45.84-18-47.55-42.24-1.9-26.75,18.87-48.92,46.46-49,59-.16,117.93-.05,176.9-.05H808.29Z" ></path>
        </SvgIcon>
    );
}

LogoutIcon.defaultProps={
    viewBox:"0 0 1000 1000"
}