import * as actionTypes from "./actions";

const initialState = {
	fleets: [],
	loadingFleets: false,
	fleetsError: null,
	createFleetLoading: false,
	updateFleetLoading: false,
	deleteFleetLoading: false,
};

const getFleetsSuccess = (state, action) => ({
	...state,
	fleets: action.fleets,
	error: null,
});
const deleteFleetSuccess = (state, action) => ({
	...state,
	deleteFleetLoading: false,
	fleets: state.fleets.filter(
		({ Fleet: { FleetNo } }) => FleetNo !== action.FleetNo
	),
});
const updateFleetSuccess = (state, action) => ({
	...state,
	updateFleetLoading: false,
	fleets: state.fleets.map(fleet => {
		if (fleet.Fleet.FleetNo !== action.fleet.FleetNo) {
			return fleet;
		}

		return action.fleet;
	}),
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_FLEET_SUCCESS:
			return updateFleetSuccess(state, action);
		case actionTypes.GET_FLEETS_SUCCESS:
			return getFleetsSuccess(state, action);
		case actionTypes.DELETE_FLEETS_START:
			return { ...state, deleteFleetLoading: true };
		case actionTypes.DELETE_FLEETS_FAIL:
			return { ...state, deleteFleetLoading: false };
		case actionTypes.DELETE_FLEETS_SUCCESS:
			return deleteFleetSuccess(state, action);
		default:
			return state;
	}
};

export default reducer;
