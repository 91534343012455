import React from "react";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {formatAmount, formatShortDate} from "../../../../assets/helpers/globals";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Loader from "../../../../components/Loader/Loader";
import * as loadingTypes from "../../../../store/loading/types";
import styled, {css} from "styled-components";
import {Radios, TextField} from "mui-rff";
import Grid from "@material-ui/core/Grid";
import {Field, Form} from "react-final-form";
import FileFieldAdapter from "../../../../components/Inputs/FileField/FileField";
import {Condition, focusOnError} from "../../../../assets/helpers/finalFormHelpers";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import List from "@material-ui/core/List";

const LoadingDiv = styled.div`
  position: absolute;
  bottom: 11px;
  right: 36px;
`;

const H3 = styled.h3`
  margin: 0;
`;

const P = styled.p`
  margin: 0;
  ${props => props.blue && css`color: RoyalBlue;`}
  ${props => props.small && css`font-size: small;`}
`;

const ActionButton = styled(Button)`
  margin: 0 5px;
`;

const FormInput = styled.form`
  width: 100%;
`;

const FormDialogContent = styled(DialogContent)`
  padding: 12px 20px;
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const StatusRadios = styled(Radios)`
  ${props => props.data[0]?.label === "Accepted" && css`&.Mui-checked { color: Green }`}
  ${props => props.data[0]?.label === "Rejected" && css`&.Mui-checked { color: Red }`}
`;

const AdminView = ({
                       details,
                       onClose,
                       open,
                       onSubmit,
                       loading,
                   }) => {
    const formFields = [
        {
            field: (
                <Grid item xs={12}>
                    <ListItemText>
                        <H3>Status</H3>
                        <StatusRadios
                            name="status"
                            formControlProps={{margin: 'none'}}
                            radioGroupProps={{row: true}}
                            data={[
                                {label: "Accepted", value: "Accepted"},
                            ]}
                        />
                        <StatusRadios
                            name="status"
                            formControlProps={{margin: 'none'}}
                            radioGroupProps={{row: true}}
                            data={[
                                {label: "Rejected", value: "Rejected"},
                            ]}
                            showError={() => false}
                        />
                    </ListItemText>
                </Grid>
            ),
        },
        {
            field: (
                <Condition when="status" is="Accepted">
                    <Grid item xs={12}>
                        <ListItemText>
                            <H3>Attachment</H3>
                            <Field
                                component={FileFieldAdapter}
                                name="attachments"
                                multiple
                                buttonLabel="UPLOAD AN INVOICE"
                                clearLabel="INVOICES"
                            />
                        </ListItemText>
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Condition when="status" is="Rejected">
                    <Grid item xs={12}>
                        <ListItemText>
                            <H3>Comment</H3>
                            <TextField
                                name="comment"
                                margin="none"
                                required
                                placeholder={"Comment"}
                                multiline
                            />
                        </ListItemText>
                    </Grid>
                </Condition>
            ),
        },
    ]

    const validate = values => {
        const errors = {}
        if (!values.status)
            errors.status = "Required"

        if (values.status === "Accepted" && (!values.attachments || !values.attachments?.length))
            errors.attachments = "Required"


        if (values.status === "Rejected" && !values.comment)
            errors.comment = "Required"

        return errors
    }

    let submit;

    const onSubmitForm = async values => {
        if (values.status === "Accepted") {
            let formData = new FormData();
            let attachArray = [];

            values.attachments.forEach(file => {
                if (file.generatedFilename)
                    attachArray.push(file.generatedFilename)
                else
                    formData.append("file", file);
            });

            if (attachArray && attachArray.length)
                formData.append("existingAttachments", attachArray);
            else
                formData.append("existingAttachments", null);
            formData.append("id", values.uniqueId);
            formData.append('responseSubmittedDate', formatShortDate(new Date()));
            formData.append('status', "Invoice Attached");
            formData.append('comment', "")

            await onSubmit(formData, true);
        }
        else
            await onSubmit({ ...values, lastResponseDate: formatShortDate(new Date()) }, false)
    }

    return <Form
        onSubmit={onSubmitForm}
        validate={validate}
        initialValues={details}
        decorators={[focusOnError]}
        render={({handleSubmit, submitting, pristine}) => {
            submit = handleSubmit
            return (
                <Dialog
                    aria-labelledby="invoice-request-details-dialog-title"
                    onClose={onClose}
                    fullWidth
                    maxWidth={"sm"}
                    open={open}
                >
                    <CustomDialogTitle
                        id="invoice-request-details-dialog-title"
                        onClose={onClose}
                        showTextContent
                    >
                        Invoice Request Details
                    </CustomDialogTitle>
                    <FormDialogContent dividers>
                        <ListItem>
                            <ListItemText>
                                <H3>Contract Number / PO Number / Order Number</H3>
                                <P>{details?.contractNumber || "Not specified"}</P>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <H3>Invoice Requestor</H3>
                                <P>{details?.requestor?.userFullName || "Not specified"}</P>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <H3>Request Submitted</H3>
                                <P>{details?.requestSubmittedDate || "Not specified"}</P>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <H3>Last Response Date</H3>
                                <P>{details.lastResponseDate || "Not specified"}</P>
                            </ListItemText>
                        </ListItem>
                        {details.nextApprovers?.length !== 0 ?
                            <ListItem style={{padding: "16px 16px"}}>
                                <ExpansionPanel style={{ boxShadow: "none",width: "99.4%" }} >
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        style={{ minHeight: "20px",height:"10px",padding:"0px"}}
                                    >
                                        <Typography><span style={{fontWeight:"bold",fontSize:"1.17em"}}>{details?.nextApprovers?.length} Allowed Approvers</span></Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{ padding: "8px 16px 0px 16px" }}>
                                        <List style={{ paddingBottom: 0, paddingTop: 14 }}>
                                            { details?.nextApprovers?.map(approver => (
                                                <ListItem   key={approver?.userEmail}>
                                                    <ListItemText primary={approver.userFullName}
                                                                  secondary={ approver.userEmail}
                                                    />
                                                </ListItem>
                                            )) || "None"}
                                        </List>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </ListItem> : ""}
                        <ListItem>
                            <ListItemText>
                                <H3>Project Number</H3>
                                <P>{details.project?.projectNumber || "Not specified"}</P>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <H3>Client</H3>
                                <P>{details.vendor ? details.vendor.vendorName : details.vendorOther}</P>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <H3>Invoice Due Date</H3>
                                <P>{formatShortDate(details?.invoiceDueDate) || "Not specified"}</P>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <H3>Invoice Date</H3>
                                <P>
                                    {formatShortDate(details?.invoiceDate) || "Not specified"}
                                </P>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <H3>Invoice Amount ({details.xeroTaxOption?.taxOptionText})</H3>
                                <P>{formatAmount(details.invoiceAmount, details.currency?.name) || "Not specified"}</P>
                                {details.exchangeRate && details.currency?.name !== "ZAR" ? <P blue small>{`~ 1 ${details.currency?.name} = ${details.exchangeRate} ZAR`}</P> : ""}
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <H3>Full Description</H3>
                                <P>{details?.fullDescription || "Not specified"}</P>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <FormInput noValidate>
                                <Grid container alignItems="flex-start" spacing={1}>
                                    {formFields.map((item, idx) => (
                                        <React.Fragment key={idx}>
                                            {item.field}
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </FormInput>
                        </ListItem>

                    </FormDialogContent>
                    <DialogActions>
                        <ActionButton
                            onClick={onClose}
                            type="button"
                            disabled={submitting}
                            color="primary"
                        >
                            Cancel
                        </ActionButton>
                        <ActionButton
                            onClick={event => submit(event)}
                            type="submit"
                            disabled={submitting || pristine}
                            color="default"
                        >
                            Submit
                        </ActionButton>
                        <LoadingDiv>
                            <Loader
                                loading={loading.type === loadingTypes.invoicesUpdateInvoiceRequestStatus || loading.type === loadingTypes.invoicesAddInvoiceResponse}
                                 size="small"/>
                        </LoadingDiv>
                    </DialogActions>
                </Dialog>
            )
        }}
    />
}

export default AdminView;