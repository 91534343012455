import axiosInstance from '../../assets/helpers/axiosInstance';
import { endLoading, startLoading } from "../loading/actions";
import * as loadingTypes from "../loading/types";
import {setError} from "../error/actions";
import * as errorTypes from "../error/types";

export const SET_ALL_LEGAL_ENTITIES = "legalEntities/SET_ALL_LEGAL_ENTITIES";
export const SET_ALL_DELIVERY_ADDRESSES = "legalEntities/SET_ALL_DELIVERY_ADDRESSES";
export const SET_DELIVERY_ADDRESS_PER_LEGAL_ENTITY = "legalEntities/SET_DELIVERY_ADDRESS_PER_LEGAL_ENTITY";

const setAllLegalEntities = legalEntities => ({ type: SET_ALL_LEGAL_ENTITIES, legalEntities });
const setAllDeliveryAddresses = allDeliveryAddresses => ({ type: SET_ALL_DELIVERY_ADDRESSES, allDeliveryAddresses });
const setDeliveryAddressPerLegalEntity = deliveryAddressPerLegalEntity => ({ type: SET_DELIVERY_ADDRESS_PER_LEGAL_ENTITY, deliveryAddressPerLegalEntity });

export const getAllLegalEntities = () => async dispatch => {
    dispatch(startLoading(loadingTypes.legalEntitiesGetAllLegalEntities));
    try {
        const res = await axiosInstance.get("/legalEntities/getAll");
        dispatch(setAllLegalEntities(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.legalEntitiesGetAllLegalEntities, `${err.message ? err.message + " at " + errorTypes.legalEntitiesGetAllLegalEntities : "Something went wrong at " + errorTypes.legalEntitiesGetAllLegalEntities }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getAllDeliveryAddresses = () => async dispatch => {
    dispatch(startLoading(loadingTypes.legalEntitiesGetAllDeliveryAddresses));
    try {
        const res = await axiosInstance.get("legalEntityAddress/getAll");
        dispatch(setAllDeliveryAddresses(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.legalEntitiesGetAllDeliveryAddresses, `${err.message ? err.message + " at " + errorTypes.legalEntitiesGetAllDeliveryAddresses : "Something went wrong at " + errorTypes.legalEntitiesGetAllDeliveryAddresses }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getDeliveryAddressPerLegalEntity = (id) => async dispatch => {
    dispatch(startLoading(loadingTypes.legalEntitiesGetDeliveryAddressPerLegalEntity));
    try {
        const res = await axiosInstance.get(`legalEntityAddress/getPerLegalEntity?uniqueId=${id}`);
        dispatch(setDeliveryAddressPerLegalEntity(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.legalEntitiesGetDeliveryAddressPerLegalEntity, `${err.message ? err.message + " at " + errorTypes.legalEntitiesGetDeliveryAddressPerLegalEntity : "Something went wrong at " + errorTypes.legalEntitiesGetDeliveryAddressPerLegalEntity }`));
    } finally {
        dispatch(endLoading());
    }
};