import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import CustomAction from "../../components/Table/CustomAction";
import RequestDetailsDialog from "./Requests/RequestDetailsDialog";
import NewRequestDialog from "./Requests/NewRequestDialog";
import { getCurrentUser } from "../../store/user/actions";
import styled, {css} from "styled-components";

import {getAllProjects} from "../../store/projects/actions";
import * as invoiceActions from "../../store/invoices/actions";
import NewResponseDialog from "./Responses/NewResponseDialog";
import ResponseDetailsDialog from "./Responses/ResponseDetailsDialog";

import * as loadingTypes from "../../store/loading/types";
import {
    formatShortTableDate,
    getFilteredData
} from "../../assets/helpers/globals";
import {getAllLegalEntities} from "../../store/legalEntities/actions";
import {getVendorsByCustomer} from "../../store/vendors/actions";
import {downloadFile} from "../../store/file/actions";
import RespondOnRequestDialog from "./Requests/ResponseDialog";
import RespondOnResponseDialog from "./Responses/ResponseDialog"
import FilterAutocomplete from '../../components/Inputs/Filter/FilterAutocomplete'
import {Autorenew, Clear, Done, Warning} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import {getNextApprovers, getPermissionsByRole} from "../../store/permissions/actions";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import debounce from "lodash/debounce";
import {getAuditLog, getTransactionSubTypes} from "../../store/audit/actions";
import AuditLoggingTableDialog from "../Audit/TableDialog";
import isEqual from "lodash/isEqual";
import {getCurrenciesByLegalEntity} from "../../store/currencies/actions";
import {useInterval} from "../../hooks/useInterval";
import {getTaxOptions} from "../../store/tax/actions";

const P = styled.p`
  color: #f57c00;
  border: 1px solid #ff9800;
  border-radius: 16px;
  height: 24px;
  padding-left: 4px;
  padding-right: 8px;
  white-space: nowrap;
  display: flex;
  max-width: fit-content;
  ${props => props.status === "Invoice Approved" && css`
    color: #388e3c;
    border: 1px solid #4caf50;
  `}
  ${props => (props.status === "Rejected" || props.status === "Invoice Rejected") && css`
    color: #d32f2f;
    border: 1px solid #f44336;
  `}
`;

const ApprovedIcon = styled(Done)`
  display: none;
  ${props => props.status === "Invoice Approved" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #388e3c;
  `}
`;

const RejectedIcon = styled(Warning)`
  display: none;
  ${props => (props.status === "Rejected" || props.status === "Invoice Rejected") && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #d32f2f;
  `}
`;

const PendingIcon = styled(Autorenew)`
  display: none;
  ${props => (props.status === "Pending" || props.status === "Invoice Attached") && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #f57c00;
  `}
`;

const FilterPaper = styled(Paper)`
  margin-bottom: 18px;
  margin-top: 14px;
`;

const FilterGrid = styled(Grid)`
  ${props => props.container && css`
    margin-left: 10px; 
    width: 98%;
  `}
  ${props => props.item && css`
    align-self: center
  `}
`;

const Requests = ({
                    user,
                    loading,
                    projects,
                    invoices,
                    getInvoices,
                    addInvoiceRequest,
                    updateInvoiceRequest,
                    updateInvoiceRequestStatus,
                    addInvoiceResponse,
                    updateInvoiceResponse,
                    updateInvoiceResponseStatus,
                    getAllProjects,
                    downloadFile,
                    getAllLegalEntities,
                    legalEntities,
                    getVendorsByCustomer,
                    vendorsByCustomer,
                    getUserRoles,
                    getNextApprovers,
                    getCurrentUser,
                    deleteInvoiceRequest,
                    getAuditLog,
                    auditLog,
                    getTransactionSubTypes,
                    transactionSubTypes,
                    taxOptions,
                    getTaxOptions,
                    currenciesByLegalEntity,
                    getCurrenciesByLegalEntity
}) => {
    const initialFilterState = { status: null, name: null, vendorName: null, actions: null, entityName: null }
    const initialDialogState = { open: false, type: "" };
    const initialDetailsState = { open: false, type: "", data: {} };
    const initialResponseState = { open: false, type: "", data: {} };

    const [addDialogOpen, setAddDialogOpen] = useState(initialDialogState);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(initialDialogState);
    const [invoiceDetails, setInvoiceDetails] = useState(initialDetailsState);
    const [invoiceResponse, setInvoiceResponse] = useState(initialResponseState);
    const [editing, setEditing] = useState(undefined);
    const [selectedInvoiceRequest, setSelectedInvoiceRequest] = useState([]);
    const [auditLogDetails, setAuditLogDetails] = useState(undefined);
    const [auditLogDialogOpen, setAuditLogDialogOpen] = useState({open: false, type: ""});
    const [filter, setFilter] = useState(initialFilterState);
    const [roles, setRoles] = useState({
        is_invoice_approver: false,
        is_invoice_requestor: false
    });

    const actions = [
        rowData =>
            ({
                icon: "visibility",
                tooltip: "View Request",
                onClick: async (_event, selectedInvoice) => {
                    const res = await getNextApprovers("Invoice", selectedInvoice.uniqueId)
                    setInvoiceDetails({open: true, type: "Request", data: {...selectedInvoice, nextApprovers: res}})

                },
                hidden: !(rowData.status === "Pending" || rowData.status === "Rejected")
            }),
        rowData =>
            ({
                icon: "visibility",
                tooltip: "View Response",
                onClick: async (_event, selectedInvoice) => {
                    const res = await getNextApprovers("Invoice", selectedInvoice.uniqueId)
                    setInvoiceDetails({open: true, type: "Response", data: {...selectedInvoice, nextApprovers: res}})
                },
                hidden: !(rowData.status === "Invoice Attached" || rowData.status === "Invoice Rejected" || rowData.status === "Invoice Approved")
            }),
        rowData =>
            ({
                icon: "reply",
                tooltip: "Respond to Request",
                onClick: (_event, selectedInvoice) =>
                    setInvoiceResponse({open: true, type: "Request", data: selectedInvoice}),
                hidden: !(roles.is_invoice_approver && (rowData.status === "Pending" || rowData.status === "Rejected")),
            }),
        rowData =>
            ({
                icon: "reply",
                tooltip: "Respond to Invoice",
                onClick: (_event, selectedInvoice) =>
                    setInvoiceResponse({open: true, type: "Response", data: selectedInvoice}),
                hidden: !((rowData.status === "Invoice Attached" || rowData.status === "Invoice Rejected") && rowData.requestor.userEmail === user.userEmail)
            }),
        rowData =>
            ({
                icon: "edit",
                tooltip: "Edit Request",
                onClick: async (_event, selectedInvoice) => {
                    // if (!projects || !projects.length)
                        await getAllProjects("Registered");
                    if (!legalEntities || !legalEntities.length)
                        await getAllLegalEntities()
                    if (!taxOptions || !taxOptions.length)
                        await getTaxOptions();
                    if (selectedInvoice.vendor)
                        await getVendorsByCustomer(selectedInvoice.vendor.legalEntities.uniqueId);
                    else
                        await getVendorsByCustomer(selectedInvoice.legalEntity.uniqueId)

                    await getCurrenciesByLegalEntity(selectedInvoice.legalEntity.uniqueId);

                    setEditing({
                        ...selectedInvoice,
                        currency: selectedInvoice.currency,
                        vendorType: selectedInvoice.vendor ? "existingVendor" : "otherVendor",
                    })

                    setEditDialogOpen({open: true, type: "Request"});
                },
                hidden: !(rowData.requestor.userEmail === user.userEmail && (rowData.status === "Pending" || rowData.status === "Rejected"))
            }),
        rowData =>
            ({
                icon: "edit",
                tooltip: "Edit Response",
                onClick: (_event, selectedInvoice) => {
                    setEditing(selectedInvoice);
                    setEditDialogOpen({open: true, type: "Response"});
                },
                hidden: !(rowData.responder && rowData.responder?.userEmail === user.userEmail && (rowData.status === "Invoice Attached" || rowData.status === "Invoice Rejected"))
            }),
        rowData =>
            ({
                icon: "delete",
                tooltip: "Cancel Request",
                onClick: async (_event, selectedInvoice) => {
                    setSelectedInvoiceRequest(selectedInvoice);
                    setDeleteConfirmationOpen(true)
                },
                hidden: !(rowData.requestor.userEmail === user.userEmail && rowData.status === "Pending")
            }),
        {
            icon: "history",
            tooltip: "View Request History",
            onClick: async (_event, supportRequest) => {
                if (!isEqual(supportRequest, auditLogDetails)) {
                    let typeId

                    if (!transactionSubTypes || !transactionSubTypes.length) {
                        let res = await getTransactionSubTypes();
                        typeId = res.data.find(transactionSubType => transactionSubType.subTypeName === "N/A" && transactionSubType.transactionType?.typeName === "Invoice").uniqueId;
                    } else
                        typeId = transactionSubTypes.find(transactionSubType => transactionSubType.subTypeName === "N/A" && transactionSubType.transactionType?.typeName === "Invoice").uniqueId;

                    await getAuditLog(supportRequest.uniqueId, typeId);
                    setAuditLogDetails(supportRequest)
                    setAuditLogDialogOpen({open: true, type: "Invoice"})
                }
            else
                    setAuditLogDialogOpen({open: true, type: "Invoice"})
            }
        },
        {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: debounce(getInvoices, 300, {
                'leading': false,
                'trailing': true
            }),
        },
        {
            icon: "add",
            isFreeAction: true,
            custom: true,
            component: Button,
            onClick: () => {
            },
            props: {
                disabled: !roles.is_invoice_requestor,
                variant: "contained",
                color: "primary",
                children: "Add Invoice Request",
                onClick: async () => {
                    // if (!projects || !projects.length)
                        getAllProjects("Registered");
                    if (!legalEntities || !legalEntities.length)
                        getAllLegalEntities()
                    if (!taxOptions || !taxOptions.length)
                        getTaxOptions();
                    setAddDialogOpen({open: true, type: "Request"})
                },
            },
        },
    ];

    const filterData = (data, query) => {
        return data.filter((item) => {
            for (let key in query) {
                switch (key) {
                    case "vendorName":
                        if (item.vendor === null || item.vendor[key] === undefined || !query[key].includes(item.vendor[key])) {
                            return false;
                        }
                        break;
                    case "actions":
                        if (query[key]['value'] === "approve") {
                            if(actions[2](item).hidden && actions[3](item).hidden)
                                return false;
                        } else if ( !(item.requestor.userEmail === user.userEmail) )
                            return false;
                        break;
                    case "name":
                        if (item.currency === null || item.currency[key] === undefined || !query[key].includes(item.currency[key])) {
                            return false;
                        }
                        break;
                    case "taxOption":
                        if (item.xeroTaxOption === null || item.xeroTaxOption[key] === undefined || !query[key].includes(item.xeroTaxOption[key]))
                            return false;
                        break;
                    case "entityName":
                        if (item.legalEntity === null || item.legalEntity[key] === undefined || !query[key].includes(item.legalEntity[key])) {
                            return false;
                        }
                        break;
                    default:
                        if (item[key] === undefined || !query[key].includes(item[key])) {
                            return false;
                        }
                        break;
                }
            }
            return true;
        });
    };

    useInterval(() => {
        getInvoices();
    }, 300000);

    useEffect(() => {
        (async function() {
            const res = await getUserRoles("invoice","");
            const permissionObj = {
                is_invoice_requestor: res.some(name => name === "invoice_requestor"),
                is_invoice_approver: res.some(name => name === "invoice_approver")
            }

            setRoles(permissionObj);

            if (Object.entries(permissionObj).some(([key, value]) => key !== "is_invoice_requestor" && value === true))
                setFilter(f => ({...f, actions: {description: "Awaiting Your Approval", value: "approve"}}))
        })();

        if (!user.userEmail)
            getCurrentUser();
        getInvoices();
    }, [getInvoices, getCurrentUser, getUserRoles, user.userEmail])

    return (
        <>
            <FilterPaper>
                <FilterGrid container spacing={2}>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, status: newValue})}
                            value={filter.status}
                            options={invoices.map(invoice => invoice.status)}
                            placeholder="Status"
                            noOptionsText="No Statuses"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, name: newValue})}
                            value={filter.name}
                            options={invoices.map(invoice => invoice.currency?.name)}
                            placeholder="Currency"
                            noOptionsText="No Currencies"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, entityName: newValue})}
                            value={filter.entityName}
                            options={invoices.map(invoice => invoice.legalEntity?.entityName)}
                            placeholder="Legal Entity"
                            noOptionsText="No Legal Entities"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, taxOption: newValue})}
                            value={filter.taxOption}
                            options={invoices.map(invoice => invoice.xeroTaxOption?.taxOptionText)}
                            placeholder="Tax"
                            noOptionsText="No Tax Options"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, vendorName: newValue})}
                            value={filter.vendorName}
                            options={invoices
                                .map(invoice => invoice.vendor !== null ? invoice.vendor.vendorName : '')
                                .filter((invoice, index, array) => array.indexOf(invoice) === index && invoice !== '')}
                            placeholder="Client"
                            noOptionsText="No Clients"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter(filter => ({...filter, actions: newValue}))}
                            value={filter.actions}
                            options={[
                                {description: "Your Invoice Requests", value: "own"},
                                {description: "Awaiting Your Approval", value: "approve"},
                            ]}
                            placeholder="Actions"
                            noOptionsText="No Actions"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <Button
                            color="secondary"
                            size="small"
                            startIcon={<Clear/>}
                            disabled={isEqual(filter, initialFilterState)}
                            onClick={() => setFilter(initialFilterState)}>
                            Clear All
                        </Button>
                    </FilterGrid>
                </FilterGrid>
            </FilterPaper>

            <MaterialTable
                columns={[
                    {
                        title: "Created Date",
                        field: "createdDate",
                        type: "date",
                    },
                    {
                        title: "Modified Date",
                        field: "lastModifiedDate",
                        type: "date",
                        defaultSort: "desc",
                        customSort: (a, b) => {
                            let d1 = new Date(a.lastModifiedDate);
                            let d2 = new Date(b.lastModifiedDate);
                            return d1.getTime() - d2.getTime();
                        }
                    },
                    {
                        title: "Legal Entity",
                        field: "legalEntity.entityName",
                    },
                    {
                        title: "Client",
                        field: "vendor.vendorName",
                        render: rowData => rowData.vendor ? <span>{rowData.vendor.vendorName}</span> :
                            <span>{rowData.vendorOther}</span>,
                    },
                    {
                        title: "Requestor",
                        field: "requestor.userFullName",
                    },
                    {
                        title: "Amount",
                        field: "invoiceAmount",
                        align: "right",
                    },
                    {
                        title: "Currency",
                        field: "currency.name",
                    },
                    {
                        title: "Tax",
                        field: "xeroTaxOption.taxOptionText",
                    },
                    {
                        title: "Invoice Date",
                        field: "invoiceDate",
                        type: "date",
                        render: rowData => <span>{formatShortTableDate(rowData.invoiceDate)}</span>,
                        customSort: (a, b) => {
                            let d1 = new Date(a.invoiceDate);
                            let d2 = new Date(b.invoiceDate);

                            return d1.getTime() - d2.getTime();
                        }
                    },
                    {
                        title: "Invoice Due Date",
                        field: "invoiceDueDate",
                        type: "date",
                        render: rowData => <span>{formatShortTableDate(rowData.invoiceDueDate)}</span>,
                        customSort: (a, b) => {
                            let d1 = new Date(a.invoiceDueDate);
                            let d2 = new Date(b.invoiceDueDate);

                            return d1.getTime() - d2.getTime();
                        }
                    },
                    {
                        title: "Status",
                        field: "status",
                        render: data => (
                            <P status={data.status}>
                                <ApprovedIcon status={data.status}/>
                                <RejectedIcon status={data.status}/>
                                <PendingIcon status={data.status}/>
                                {data.status}</P>
                        ),
                        // customSort: (a, b) => {
                        //     if (a.status.length === b.status.length && a.status === "Pending")
                        //         return 1;
                        //     else if (a.status.length > b.status.length)
                        //         return 0;
                        //     else
                        //         return -1
                        // }
                        // customSort: (a, b) => {
                        //     if (a.status < b.status)
                        //         return -1;
                        //     else if (a.status > b.status)
                        //         return 1;
                        //     else
                        //         return 0
                        // }
                    }
                ]}
                actions={actions}
                components={{ Action: CustomAction }}
                data={isEqual(filter, initialFilterState) ? invoices : getFilteredData(filter, invoices, [], filterData)}
                isLoading={addDialogOpen.open === false &&
                    editDialogOpen.open === false && (
                    loading.type === loadingTypes.invoicesGetInvoices ||
                    loading.type === loadingTypes.projectsGetAllProjects ||
                    loading.type === loadingTypes.legalEntitiesGetAllLegalEntities ||
                    loading.type === loadingTypes.taxGetTaxOptions ||
                    loading.type === loadingTypes.vendorsGetVendorsByCustomer ||
                    loading.type === loadingTypes.currenciesGetCurrenciesByLegalEntity ||
                    loading.type === loadingTypes.auditGetTransactionSubTypes ||
                    loading.type === loadingTypes.auditGetAuditLog ||
                    loading.type === loadingTypes.permissionsGetNextApprovers ||
                    loading.type === loadingTypes.permissionsGetPermissionsByRole)
                }
                options={{
                    actionsColumnIndex: -1,
                    toolbarButtonAlignment: "right",
                    pageSize: 10,
                    searchFieldAlignment: "left",
                    showTitle: false,
                    // grouping: true,
                    sorting: true,
                    // isLoading: true,
                    search: true,
                    // loadingType: "linear",
                    // thirdSortClick: false,
                }}
            />

            <RequestDetailsDialog
                key={"Invoice-Request-Details-" + invoiceDetails.data?.uniqueId}
                details={invoiceDetails.data}
                onClose={() => setInvoiceDetails(initialDetailsState)}
                open={invoiceDetails.open && invoiceDetails.type === "Request"}
                isAdmin={roles.is_invoice_approver && (invoiceDetails.data.status === "Pending" || invoiceDetails.data.status === "Rejected")}
                onSubmit={async (updatedInvoiceRequestStatus, isResponse) => {
                    isResponse ?
                        await addInvoiceResponse(updatedInvoiceRequestStatus.get("id"), updatedInvoiceRequestStatus) :
                        await updateInvoiceRequestStatus(invoiceDetails.data?.uniqueId, updatedInvoiceRequestStatus);
                    getInvoices();
                    setInvoiceDetails(initialDetailsState)
                }}
                loading={loading}
            />
            <RespondOnRequestDialog
                key={"Invoice-Request-Respond-" + invoiceResponse.data?.uniqueId}
                details={invoiceResponse.data}
                onClose={() => setInvoiceResponse(initialResponseState)}
                open={invoiceResponse.open && invoiceResponse.type === "Request"}
                onSubmit={async (updatedInvoiceRequestStatus, isResponse) => {
                    isResponse ?
                        await addInvoiceResponse(updatedInvoiceRequestStatus.get("id"), updatedInvoiceRequestStatus) :
                        await updateInvoiceRequestStatus(invoiceResponse.data?.uniqueId, updatedInvoiceRequestStatus);
                    getInvoices();
                    setInvoiceResponse(initialResponseState)
                }}
                loading={loading}
            />
            <NewRequestDialog
                open={addDialogOpen.open && addDialogOpen.type === "Request"}
                onClose={() => setAddDialogOpen(initialDialogState)}
                onSubmit={async newInvoice => {
                    await addInvoiceRequest(newInvoice);
                    getInvoices();
                    setAddDialogOpen(initialDialogState);
                }}
                loading={loading}
                projects={projects}
                getVendorsByCustomer={getVendorsByCustomer}
                vendorsByCustomer={vendorsByCustomer}
                legalEntities={legalEntities}
                taxOptions={taxOptions}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
            />
            <NewRequestDialog
                key={"Invoice-Request-Edit-" + editing?.uniqueId}
                open={editDialogOpen.open && editDialogOpen.type === "Request"}
                onClose={() => setEditDialogOpen(initialDialogState)}
                initialValues={editing}
                onSubmit={async updatedInvoice => {
                    await updateInvoiceRequest(editing?.uniqueId, updatedInvoice);
                    getInvoices();
                    setEditDialogOpen(initialDialogState);
                }}
                loading={loading}
                projects={projects}
                getVendorsByCustomer={getVendorsByCustomer}
                vendorsByCustomer={vendorsByCustomer}
                legalEntities={legalEntities}
                taxOptions={taxOptions}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
            />

            <ResponseDetailsDialog
                key={"Invoice-Response-Details-" + invoiceDetails.data.uniqueId}
                details={invoiceDetails.data}
                onClose={() => setInvoiceDetails(initialDetailsState)}
                open={invoiceDetails.open && invoiceDetails.type === "Response"}
                isProjectManager={roles.is_invoice_requestor && (invoiceDetails.data.status === "Invoice Attached" || invoiceDetails.data.status === "Invoice Rejected") && invoiceDetails.data.requestor.userEmail === user.userEmail}
                onSubmit={async updatedInvoiceRequestResponse => {
                    await updateInvoiceResponseStatus(invoiceDetails.data.uniqueId, updatedInvoiceRequestResponse);
                    getInvoices();
                    setInvoiceDetails(initialDetailsState);
                }}
                loading={loading}
                downloadFile={downloadFile}
            />
            <RespondOnResponseDialog
                key={"Invoice-Response-Respond-" + invoiceResponse.data?.uniqueId}
                details={invoiceResponse.data}
                onClose={() => setInvoiceResponse(initialResponseState)}
                open={invoiceResponse.open && invoiceResponse.type === "Response"}
                onSubmit={async updatedInvoiceRequestResponse => {
                    await updateInvoiceResponseStatus(invoiceResponse.data.uniqueId, updatedInvoiceRequestResponse);
                    getInvoices();
                    setInvoiceResponse(initialResponseState);
                }}
                loading={loading}
            />
            <NewResponseDialog
                open={addDialogOpen.open && addDialogOpen.type === "Response"}
                onClose={() => setAddDialogOpen(initialDialogState)}
                onSubmit={async newInvoice => {
                    await addInvoiceResponse(newInvoice.get("id"), newInvoice);
                    getInvoices();
                    setAddDialogOpen(initialDialogState);
                }}
                loading={loading}
            />
            <NewResponseDialog
                key={"Invoice-Response-Edit-" + editing?.uniqueId}
                open={editDialogOpen.open && editDialogOpen.type === "Response"}
                onClose={() => setEditDialogOpen(initialDialogState)}
                initialValues={editing}
                onSubmit={async updatedInvoice => {
                    await updateInvoiceResponse(editing?.uniqueId, updatedInvoice);
                    getInvoices();
                    setEditDialogOpen(initialDialogState);
                }}
                loading={loading}
            />
            <AuditLoggingTableDialog
                open={auditLogDialogOpen.open}
                auditPayload={auditLog}
                type={auditLogDialogOpen.type}
                onClose={() => setAuditLogDialogOpen({open: false, type: ""})}
                loading={loading.type === loadingTypes.auditGetAuditLog}
            />
            <ConfirmationDialog
                open={deleteConfirmationOpen}
                handleClose={() => setDeleteConfirmationOpen(false)}
                handleConfirmation={async confirm => {
                    if (confirm) {
                        await deleteInvoiceRequest(selectedInvoiceRequest.uniqueId);
                        getInvoices();
                    }
                    setDeleteConfirmationOpen(false);
                }}
                promptText={"Are you sure you want to cancel the invoice request ?"}
                loading={loading.type === loadingTypes.invoicesDeleteInvoiceRequest}
            />
        </>
    );
}

const mapStateToProps = state => ({
    invoices: state.invoicesReducer.invoices,
    user: state.userReducer.currentUser,
    loading: state.loadingReducer.loading,
    projects: state.projectsReducer.projects,
    vendorsByCustomer: state.vendorsReducer.vendorsByCustomer,
    legalEntities: state.legalEntitiesReducer.legalEntities,
    permissionsByRole: state.permissionsReducer.permissionsByRole,
    nextApprovers: state.permissionsReducer.nextApprovers,
    auditLog: state.auditReducer.auditLog,
    transactionSubTypes: state.auditReducer.transactionSubTypes,
    taxOptions: state.taxReducer.taxOptions,
    currenciesByLegalEntity: state.currenciesReducer.currenciesByLegalEntity
});

const mapDispatchToProps = dispatch => ({
    getInvoices: () => dispatch(invoiceActions.getInvoices()),
    addInvoiceRequest: invoice => dispatch(invoiceActions.addInvoiceRequest(invoice)),
    updateInvoiceRequest: (id, invoice) => dispatch(invoiceActions.updateInvoiceRequest(id, invoice)),
    updateInvoiceRequestStatus: (id, invoice) => dispatch(invoiceActions.updateInvoiceRequestStatus(id, invoice)),
    addInvoiceResponse: (id, invoice) => dispatch(invoiceActions.addInvoiceResponse(id, invoice)),
    updateInvoiceResponse: (id, invoice) => dispatch(invoiceActions.updateInvoiceResponse(id, invoice)),
    updateInvoiceResponseStatus: (id, invoice) => dispatch(invoiceActions.updateInvoiceResponseStatus(id, invoice)),
    deleteInvoiceRequest: id => dispatch(invoiceActions.deleteInvoiceRequest(id)),
    getAllProjects: (type) => dispatch(getAllProjects(type)),
    getAllLegalEntities: () => dispatch(getAllLegalEntities()),
    getVendorsByCustomer: id => dispatch(getVendorsByCustomer(id)),
    getUserRoles: (roleType, roleSubType) => dispatch(getPermissionsByRole(roleType, roleSubType)),
    getCurrentUser: () => getCurrentUser()(dispatch),
    downloadFile: (id, name) => dispatch(downloadFile(id, name)),
    getAuditLog: (id, type) => dispatch(getAuditLog(id, type)),
    getTransactionSubTypes: () => dispatch(getTransactionSubTypes()),
    getTaxOptions: () => dispatch(getTaxOptions()),
    getNextApprovers: (type,id) => dispatch(getNextApprovers(type, id)),
    getCurrenciesByLegalEntity: (id) => dispatch(getCurrenciesByLegalEntity(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Requests);