import * as actionTypes from './actions'

const initialState = {
    tenders: [],
    allQuoteNumbers: [],
    allEOINumbers: []
};

const setAllTenders = (state, { tenders }) => ({ ...state, tenders });
const setAllQuoteNumbers = (state, { allQuoteNumbers }) => ({ ...state, allQuoteNumbers })
const setAllEOINumbers = (state, { allEOINumbers }) => ({ ...state, allEOINumbers })

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ALL_TENDERS:
            return setAllTenders(state, action);
        case actionTypes.SET_ALL_QUOTE_NUMBERS:
            return setAllQuoteNumbers(state, action);
        case actionTypes.SET_ALL_EOI_NUMBERS:
            return setAllEOINumbers(state, action);
        default:
            return state;
    }
}