import axiosInstance from '../../assets/helpers/axiosInstance';
import { setSuccessMessage } from "../success/actions";
import { endLoading, startLoading } from "../loading/actions";
import * as loadingTypes from "../loading/types";
import {setError} from "../error/actions";
import * as errorTypes from "../error/types";

export const SET_SUPPORT_REQUESTS = "support/SET_SUPPORT_REQUESTS";

const setSupportRequests = supportRequests => ({
    type: SET_SUPPORT_REQUESTS,
    supportRequests,
});

export const getSupportRequests = () => async dispatch => {
    dispatch(startLoading(loadingTypes.supportGetSupportRequests));
    try {
        const res = await axiosInstance.get("/ItSupport/getSupportRequestsTable");
        dispatch(setSupportRequests(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.supportGetSupportRequests, `${err.message ? err.message + " at " + errorTypes.supportGetSupportRequests : "Something went wrong at " + errorTypes.supportGetSupportRequests }`));
    } finally {
        dispatch(endLoading());
    }
};

export const addSupportRequest = (data) => async dispatch => {
    dispatch(startLoading(loadingTypes.supportAddSupportRequest));
    try {
        await axiosInstance.post(`/ItSupport/addRequest`, data)
        dispatch(setSuccessMessage("Success! Support request created."));
    } catch (err) {
        dispatch(setError(errorTypes.supportAddSupportRequest, `${err.message ? err.message + " at " + errorTypes.supportAddSupportRequest : "Something went wrong at " + errorTypes.supportAddSupportRequest }`));
    } finally {
        dispatch(endLoading());
    }
};

export const updateSupportRequest = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.supportUpdateSupportRequest));
    try {
        await axiosInstance.post(`/ItSupport/updateRequest?uniqueId=${id}`, data);
        dispatch(setSuccessMessage("Success! Support request updated."));
    } catch (err) {
        dispatch(setError(errorTypes.supportUpdateSupportRequest, `${err.message ? err.message + " at " + errorTypes.supportUpdateSupportRequest : "Something went wrong at " + errorTypes.supportUpdateSupportRequest }`));
    } finally {
        dispatch(endLoading());
    }
};

export const updateSupportRequestStatus = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.supportUpdateSupportRequestStatus));
    try {
        await axiosInstance.post(`/ItSupport/updateStatus?uniqueId=${id}`, data);
        dispatch(setSuccessMessage("Success! Support request status updated."));
    } catch (err) {
        dispatch(setError(errorTypes.supportUpdateSupportRequestStatus, `${err.message ? err.message + " at " + errorTypes.supportUpdateSupportRequestStatus : "Something went wrong at " + errorTypes.supportUpdateSupportRequestStatus }`));
    } finally {
        dispatch(endLoading());
    }
};

export const deleteSupportRequest = (id) => async (dispatch, getState) => {
    const removeSupportRequest = () => {
        const requests = getState().supportReducer.supportRequests;
        const newSupportRequests = requests.filter(
            request => request.uniqueId !== id
        );
        dispatch(setSupportRequests(newSupportRequests));
    };

    dispatch(startLoading(loadingTypes.supportDeleteSupportRequest));
    try {
        await axiosInstance.delete(`ItSupport/deleteRequests?uniqueId=${id}`);
        dispatch(setSuccessMessage("Success! Support request cancelled."));
        removeSupportRequest();
    } catch (err) {
        dispatch(setError(errorTypes.supportDeleteSupportRequest, `${err.message ? err.message + " at " + errorTypes.supportDeleteSupportRequest : "Something went wrong at " + errorTypes.supportDeleteSupportRequest }`));
    } finally {
        dispatch(endLoading());
    }
}