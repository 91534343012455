import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import CustomAction from "../../components/Table/CustomAction";
import * as supportActions from "../../store/itSupport/actions";
import RequestDetailsDialog from "./RequestDetailsDialog";
import NewRequestDialog from "./NewRequestDialog";
import { getCurrentUser } from "../../store/user/actions";
import styled, { css } from 'styled-components';
import * as loadingTypes from "../../store/loading/types";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import FilterAutocomplete from "../../components/Inputs/Filter/FilterAutocomplete";
import {Autorenew, Clear, Done, Info, Warning} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {getFilteredData} from "../../assets/helpers/globals";
import RespondOnRequestDialog from "./ResponseDialog";
import {getPermissionsByRole} from "../../store/permissions/actions";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import {useInterval} from "../../hooks/useInterval";
import AuditLoggingTableDialog from "../Audit/TableDialog";
import {getAuditLog, getTransactionSubTypes} from "../../store/audit/actions";

const ResolvedIcon = styled(Done)`
  display: none;
  ${props => props.status === "Resolved" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #388e3c;
  `}
`;

const UnresolvedIcon = styled(Warning)`
  display: none;
  ${props => props.status === "Unresolved" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #d32f2f;
  `}
`;

const QueuedIcon = styled(Autorenew)`
  display: none;
  ${props => props.status === "Queued" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #f57c00;
  `}
`;

const InProgressIcon = styled(Info)`
  display: none;
  ${props => props.status === "In Progress" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #1976d2;
  `}
`;

const P = styled.p`
  color: #f57c00;
  border: 1px solid #ff9800;
  border-radius: 16px;
  height: 24px;
  padding-left: 4px;
  padding-right: 8px;
  white-space: nowrap;
  display: flex;
  max-width: fit-content;
  ${props => props.status === "In Progress" && css`
    color: #1976d2;
    border: 1px solid #2196f3;
  `}
  ${props => props.status === "Resolved" && css`
    color: #388e3c;
    border: 1px solid #4caf50;
  `}
  ${props => props.status === "Unresolved" && css`
    color: #d32f2f;
    border: 1px solid #f44336;
  `}
`;

const FilterPaper = styled(Paper)`
  margin-bottom: 18px;
  margin-top: 14px;
`;

const FilterGrid = styled(Grid)`
  ${props => props.container && css`
    margin-left: 10px; 
    width: 98%;
  `}
  ${props => props.item && css`
    align-self: center
  `}
`;

const ITSupport = ({
                       user,
                       loading,
                       supportRequests,
                       getSupportRequests,
                       getCurrentUser,
                       addSupportRequest,
                       updateSupportRequest,
                       updateSupportRequestStatus,
                       deleteSupportRequest,
                       getUserRoles,
                       getAuditLog,
                       auditLog,
                       getTransactionSubTypes,
                       transactionSubTypes,
}) => {
    const initialFilterState = {status: null, category: null, location: null, actions: null}
    const initialDialogState = {open: false, data: {}}

    const [addSupportRequestDialogOpen, setAddSupportRequestDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteSupportRequestConfirmationOpen, setDeleteSupportRequestConfirmationOpen] = useState(false);
    const [supportRequestDetails, setSupportRequestDetails] = useState(initialDialogState);
    const [supportResponse, setSupportResponse] = useState(initialDialogState);
    const [selectedSupportRequest, setSelectedSupportRequest] = useState(undefined);
    const [auditLogDetails, setAuditLogDetails] = useState(undefined);
    const [auditLogDialogOpen, setAuditLogDialogOpen] = useState({open: false, type: ""});
    const [editing, setEditing] = useState(undefined);
    const [filter, setFilter] = useState(initialFilterState);
    const [roles, setRoles] = useState({
        is_it_support_requestor: false,
        is_it_support_approver: false,
    })

    const actions = [
        {
            icon: "visibility",
            tooltip: "View Request",
            onClick: (_event, supportRequest) => {
                setSupportRequestDetails({open: true, data: supportRequest})
            }
        },
        rowData =>
            ({
                icon: "reply",
                tooltip: "Respond to Request",
                onClick: (_event, supportRequest) =>
                    setSupportResponse({open: true, data: supportRequest}),
                hidden: !(roles.is_it_support_approver && (rowData.status === "Queued" || rowData.status === "In Progress"|| rowData.status === "Unresolved")),
            }),
        rowData =>
            ({
                icon: "edit",
                tooltip: "Edit Request",
                onClick: async (_event, supportRequest) => {
                    setEditing(supportRequest)
                    setEditDialogOpen(true);
                },
                hidden: !(rowData.requestor.userEmail === user.userEmail && rowData.status === "Queued")
            }),
        rowData =>
            ({
                icon: "delete",
                tooltip: "Cancel Request",
                onClick: async (_event, supportRequest) => {
                    setSelectedSupportRequest(supportRequest);
                    setDeleteSupportRequestConfirmationOpen(true)
                },
                hidden: !(rowData.requestor.userEmail === user.userEmail && rowData.status === "Queued")
            }),
        {
            icon: "history",
            tooltip: "View Request History",
            onClick: async (_event, supportRequest) => {
                if (!isEqual(supportRequest, auditLogDetails)) {
                    let typeId

                    if (!transactionSubTypes || !transactionSubTypes.length) {
                        let res = await getTransactionSubTypes();
                        typeId = res.data.find(transactionSubType => transactionSubType.subTypeName === "N/A" && transactionSubType.transactionType?.typeName === "It Support").uniqueId;
                    } else
                        typeId = transactionSubTypes.find(transactionSubType => transactionSubType.subTypeName === "N/A" && transactionSubType.transactionType?.typeName === "It Support").uniqueId;

                    await getAuditLog(supportRequest.uniqueId, typeId);
                    setAuditLogDetails(supportRequest)
                    setAuditLogDialogOpen({open: true, type: "It Support"})
                }
                else
                    setAuditLogDialogOpen({open: true, type: "It Support"})
            }
        },
        {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: debounce(getSupportRequests, 300, {
                'leading': false,
                'trailing': true
            }),
        },
        {
            icon: "add",
            isFreeAction: true,
            custom: true,
            component: Button,
            onClick: () => {
            },
            props: {
                disabled:  !roles.is_it_support_requestor,
                variant: "contained",
                color: "primary",
                children: "Add support request",
                onClick: () => setAddSupportRequestDialogOpen(true),
            },
        }
    ]

    const filterData = (data, query) => {
        return data.filter((item) => {
            for (let key in query) {
                switch (key) {
                    case "actions":
                        if (query[key]['value'] === "approve") {
                            if(actions[1](item).hidden)
                                return false;
                        } else if ( !(item.requestor.userEmail === user.userEmail) )
                            return false;
                        break;
                    default:
                        if (item[key] === undefined || !query[key].includes(item[key])) {
                            return false;
                        }
                        break;
                }
            }
            return true;
        });
    };

    useInterval(() => {
        getSupportRequests();
    }, 300000);

    useEffect(() => {
        (async function() {
            const res = await getUserRoles("itSupport","");
            const permissionObj = {
                is_it_support_requestor: res.some(name => name === "it_support_requestor"),
                is_it_support_approver: res.some(name => name === "it_support_approver"),
            }

            setRoles(permissionObj);

            if (Object.entries(permissionObj).some(([key, value]) => key !== "is_it_support_requestor" && value === true))
                setFilter(f => ({...f, actions: {description: "Awaiting Your Resolution", value: "approve"}}))
        })();
        if (!user.userEmail)
            getCurrentUser();
        getSupportRequests();
    }, [getCurrentUser, getUserRoles, getSupportRequests, user.userEmail])

    return (
        <>
            <FilterPaper>
                <FilterGrid container spacing={2}>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, status: newValue})}
                            value={filter.status}
                            options={supportRequests.map(supportRequest => supportRequest.status)}
                            placeholder="Status"
                            noOptionsText="No Statuses"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, category: newValue})}
                            value={filter.category}
                            options={supportRequests.map(supportRequest => supportRequest.category)}
                            placeholder="Category"
                            noOptionsText="No Categories"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, location: newValue})}
                            value={filter.location}
                            options={supportRequests.map(supportRequest => supportRequest.location)}
                            placeholder="Location"
                            noOptionsText="No Locations"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter(filter => ({...filter, actions: newValue}))}
                            value={filter.actions}
                            options={[
                                {description: "Your Support Requests", value: "own"},
                                {description: "Awaiting Your Resolution", value: "approve"},
                            ]}
                            placeholder="Actions"
                            noOptionsText="No Actions"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <Button
                            color="secondary"
                            size="small"
                            startIcon={<Clear/>}
                            disabled={isEqual(filter, initialFilterState)}
                            onClick={() => setFilter(initialFilterState)}>
                            Clear All
                        </Button>
                    </FilterGrid>
                </FilterGrid>
            </FilterPaper>

            <MaterialTable
                columns={[
                    {
                        title: "Created Date",
                        field: "createdDate",
                        type: "date",
                    },
                    {
                        title: "Modified Date",
                        field: "lastModifiedDate",
                        type: "date",
                        defaultSort: "desc",
                        customSort: (a, b) => {
                            let d1 = new Date(a.lastModifiedDate);
                            let d2 = new Date(b.lastModifiedDate);
                            return d1.getTime() - d2.getTime();
                        }
                    },
                    {
                        title: "Requestor",
                        field: "requestor.userFullName",
                    },
                    {
                        title: "Category",
                        field: "category",
                    },
                    {
                        title: "Location",
                        field: "location",
                    },
                    {
                        title: "Status",
                        field: "status",
                        render: data => (
                            <P status={data.status}>
                                <ResolvedIcon status={data.status}/>
                                <UnresolvedIcon status={data.status}/>
                                <QueuedIcon status={data.status}/>
                                <InProgressIcon status={data.status}/>{data.status}</P>
                        ),
                    }
                ]}
                actions={actions}
                components={{ Action: CustomAction }}
                data={isEqual(filter, initialFilterState) ? supportRequests : getFilteredData(filter, supportRequests, [], filterData)}
                isLoading={loading.type === loadingTypes.supportGetSupportRequests ||
                    loading.type === loadingTypes.auditGetTransactionSubTypes ||
                    loading.type === loadingTypes.auditGetAuditLog ||
                    loading.type === loadingTypes.permissionsGetPermissionsByRole}
                options={{
                    actionsColumnIndex: -1,
                    toolbarButtonAlignment: "right",
                    pageSize: 10,
                    searchFieldAlignment: "left",
                    showTitle: false,
                    sorting: true,
                    isLoading: true,
                    search: true,
                    // loadingType: "linear",
                    // thirdSortClick: false,
                }}
            />

            <RequestDetailsDialog
                key={"IT-Support-Request-Details-" + supportRequestDetails.data.uniqueId}
                details={supportRequestDetails.data}
                onClose={() => setSupportRequestDetails(initialDialogState)}
                open={supportRequestDetails.open}
                isAdmin={!actions[1](supportRequestDetails.data).hidden}
                onSubmit={async updatedSupportRequestStatus => {
                    await updateSupportRequestStatus(supportRequestDetails.data.uniqueId, updatedSupportRequestStatus);
                    getSupportRequests();
                    setSupportRequestDetails(initialDialogState);
                }}
                loading={loading}
            />
            <RespondOnRequestDialog
                key={"IT-Support-Request-Respond-" + supportResponse.data?.uniqueId}
                details={supportResponse.data}
                onClose={() => setSupportResponse(initialDialogState)}
                open={supportResponse.open}
                onSubmit={async (updatedSupportRequestStatus) => {
                    await updateSupportRequestStatus(supportResponse.data.uniqueId, updatedSupportRequestStatus);
                    getSupportRequests();
                    setSupportResponse(initialDialogState);
                }}
                loading={loading}
            />
            <NewRequestDialog
                open={addSupportRequestDialogOpen}
                onClose={() => setAddSupportRequestDialogOpen(false)}
                onSubmit={async newSupportRequest => {
                    await addSupportRequest(newSupportRequest);
                    getSupportRequests();
                    setAddSupportRequestDialogOpen(false)
                }}
                loading={loading}
            />
            <NewRequestDialog
                key={"IT-Support-Request-Edit-" + editing?.uniqueId}
                open={editDialogOpen}
                onClose={() => {
                    setEditDialogOpen(false);
                }}
                initialValues={editing}
                onSubmit={async updatedSupportRequest => {
                    await updateSupportRequest(editing?.uniqueId, updatedSupportRequest);
                    getSupportRequests();
                    setEditDialogOpen(false);
                }}
                loading={loading}
            />
            <AuditLoggingTableDialog
                open={auditLogDialogOpen.open}
                auditPayload={auditLog}
                type={auditLogDialogOpen.type}
                onClose={() => setAuditLogDialogOpen({open: false, type: ""})}
                loading={loading.type === loadingTypes.auditGetAuditLog}
            />
            <ConfirmationDialog
                open={deleteSupportRequestConfirmationOpen}
                handleClose={() => setDeleteSupportRequestConfirmationOpen(false)}
                handleConfirmation={async confirm => {
                    if (confirm) {
                        await deleteSupportRequest(selectedSupportRequest.uniqueId);
                        getSupportRequests();
                    }
                    setDeleteSupportRequestConfirmationOpen(false);
                }}
                promptText={"Are you sure you want to cancel the support request ?"}
                loading={loading.type === loadingTypes.supportDeleteSupportRequest}
            />
        </>
    );
}

const mapStateToProps = state => {
    return {
        supportRequests: state.supportReducer.supportRequests,
        user: state.userReducer.currentUser,
        loading: state.loadingReducer.loading,
        auditLog: state.auditReducer.auditLog,
        transactionSubTypes: state.auditReducer.transactionSubTypes,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getSupportRequests: () => dispatch(supportActions.getSupportRequests()),
        addSupportRequest: request => dispatch(supportActions.addSupportRequest(request)),
        updateSupportRequest: (id, request) => dispatch(supportActions.updateSupportRequest(id, request)),
        updateSupportRequestStatus: (id, request) => dispatch(supportActions.updateSupportRequestStatus(id, request)),
        deleteSupportRequest: id => dispatch(supportActions.deleteSupportRequest(id)),
        getCurrentUser: () => getCurrentUser()(dispatch),
        getUserRoles: (roleType, roleSubType) => dispatch(getPermissionsByRole(roleType, roleSubType)),
        getAuditLog: (id, type) => dispatch(getAuditLog(id, type)),
        getTransactionSubTypes: () => dispatch(getTransactionSubTypes()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ITSupport);