import React from "react";
import AdminView from "./DetailsViews/AdminView";
import RequesterView from "./DetailsViews/RequesterView";

const RequestDetailsDialog = ({
                                  details,
                                  onClose,
                                  open,
                                  isAdmin,
                                  onSubmit,
                                  loading,
                              }) => isAdmin ?
    <AdminView details={details} onClose={onClose} open={open} onSubmit={onSubmit} loading={loading} /> :
    <RequesterView details={details} onClose={onClose} open={open} />

export default RequestDetailsDialog;