import { formatDateRange } from "../../../assets/helpers/globals";
import ReactHtmlParser from "react-html-parser";

export const handleDetails = (event, state) => {
	let updatedDetails = { ...state };
	let updatedTopParagraphItems = updatedDetails.topParagraphItems
		? [...updatedDetails.topParagraphItems]
		: [];
	let updatedListItems = updatedDetails.listItems
		? [...updatedDetails.listItems]
		: [];

	updatedTopParagraphItems.forEach((item, index) => {
		let updatedTopParagraphItem = { ...item };

		if (updatedTopParagraphItem.type === "dateRange") {
			if (updatedTopParagraphItem.propertyChain) {
				let startValue = null;
				let endValue = null;
				if (
					event[updatedTopParagraphItem.property[0][0]] &&
					event[updatedTopParagraphItem.property[1][0]]
				) {
					startValue = event[updatedTopParagraphItem.property[0][0]];
					for (let i = 1; i < updatedTopParagraphItem.propertyChain; i++) {
						startValue = startValue[updatedTopParagraphItem.property[0][i]];
					}
					endValue = event[updatedTopParagraphItem.property[1][0]];
					for (let i = 1; i < updatedTopParagraphItem.propertyChain; i++) {
						endValue = endValue[updatedTopParagraphItem.property[1][i]];
					}
					if (
						startValue !== event[updatedTopParagraphItem.property[0][0]] &&
						endValue !== event[updatedTopParagraphItem.property[1][0]]
					) {
						updatedTopParagraphItem.show = true;
					}
				}
				updatedTopParagraphItem.text = formatDateRange(
					new Date(startValue),
					new Date(endValue)
				);
			} else {
				updatedTopParagraphItem.text = formatDateRange(
					new Date(event[updatedTopParagraphItem.property[0]]),
					new Date(event[updatedTopParagraphItem.property[1]])
				);
			}
		} else {
			if (event[updatedTopParagraphItem.property]) {
				if (updatedTopParagraphItem.formatMethod) {
					updatedTopParagraphItem.text = updatedTopParagraphItem.formatMethod(
						event[updatedTopParagraphItem.property]
					);
				} else {
					updatedTopParagraphItem.text =
						event[updatedTopParagraphItem.property];
				}
				updatedTopParagraphItem.show = true;
			}
		}
		updatedTopParagraphItems[index] = updatedTopParagraphItem;
	});

	updatedListItems.forEach((item, index) => {
		let updatedListItem = { ...item };
		if (updatedListItem.propertyChain) {
			let value = null;

			if (updatedListItem.property[0]) {
				value = event[updatedListItem.property[0]];
				for (let i = 1; i < updatedListItem.propertyChain; i++) {
					if (value[updatedListItem.property[i]]) {
						value = value[updatedListItem.property[i]];
					}
				}

				if (value !== event[updatedListItem.property[0]]) {
					updatedListItem.show = true;
				}
			}

			if (
				updatedListItem.itemType === "link" ||
				updatedListItem.itemType === "listItemButton"
			) {
				if (updatedListItem.hrefFormatMethod) {
					updatedListItem.href = updatedListItem.hrefFormatMethod(value);
					if (updatedListItem.href && updatedListItem.href !== "") {
						updatedListItem.show = true;
					}
				} else {
					updatedListItem.href = value;
					if (updatedListItem.href && updatedListItem.href !== "") {
						updatedListItem.show = true;
					}
				}
			} else if (updatedListItem.property === "description") {
				updatedListItem.primaryText = ReactHtmlParser(
					event[updatedListItem.property]
				);
				updatedListItem.show = true;
			} else {
				updatedListItem.primaryText = value;
			}
		} else {
			if (
				event[updatedListItem.property] ||
				event[updatedListItem.property] === 0
			) {
				if (
					updatedListItem.itemType === "link" ||
					updatedListItem.itemType === "listItemButton"
				) {
					if (updatedListItem.hrefFormatMethod) {
						updatedListItem.href = updatedListItem.hrefFormatMethod(
							event[updatedListItem.property]
						);
						if (updatedListItem.href && updatedListItem.href !== "") {
							updatedListItem.show = true;
						}
					} else {
						updatedListItem.href = event[updatedListItem.property];
						if (updatedListItem.href && updatedListItem.href !== "") {
							updatedListItem.show = true;
						}
					}
				} else if (updatedListItem.itemType === "attendeeList") {
					updatedListItem.attendees = [...event[updatedListItem.property]];
					if (
						updatedListItem.attendees.length <= updatedListItem.openOnLength
					) {
						updatedListItem.open = true;
					}
					updatedListItem.show = true;
				} else if (updatedListItem.property === "description") {
					updatedListItem.primaryText = ReactHtmlParser(
						event[updatedListItem.property]
					);
					updatedListItem.show = true;
				} else {
					updatedListItem.primaryText = event[updatedListItem.property];
					updatedListItem.show = true;
				}
			}
		}
		if (updatedListItem.secondaryTextFormatMethod) {
			updatedListItem.secondaryText = updatedListItem.secondaryTextFormatMethod(
				event[updatedListItem.property]
			);
		} else if (
			updatedListItem.secondaryTextProperty &&
			event[updatedListItem.secondaryTextProperty]
		) {
			updatedListItem.secondaryText = updatedListItem.secondaryTextLabel
				? updatedListItem.secondaryTextLabel +
				  " " +
				  event[updatedListItem.secondaryTextProperty].toString()
				: event[updatedListItem.secondaryTextProperty];
		}
		updatedListItems[index] = updatedListItem;
	});

	updatedDetails.listItems = updatedListItems;
	updatedDetails.topParagraphItems = updatedTopParagraphItems;
	updatedDetails.loaded = true;
	updatedDetails.id = event.id ? event.id : null;
	updatedDetails.creator = event.creator;
	return updatedDetails;
};

export const handleClearDetailsState = state => {
	let updatedDetails = { ...state };
	let updatedDetailsFields = [...updatedDetails.listItems];

	updatedDetails.loaded = false;
	updatedDetailsFields.forEach((field, index) => {
		let updatedDetailElement = {
			...field,
			show: false,
		};

		if (field.itemType === "attendeeList") {
			updatedDetailElement.open = false;
		}
		updatedDetailsFields[index] = updatedDetailElement;
	});
	updatedDetails.listItems = updatedDetailsFields;

	return updatedDetails;
};

export const handleAttendeesToggle = (id, state) => {
	let updatedDetails = { ...state };
	let updatedDetailsListItems = [...updatedDetails.listItems];

	const updatedDetailsListItemIndex = updatedDetailsListItems.findIndex(
		item => item.id === id
	);

	if (updatedDetailsListItemIndex !== -1) {
		let newValue = !updatedDetailsListItems[updatedDetailsListItemIndex].open;
		let updatedDetailsListItem = {
			...updatedDetailsListItems[updatedDetailsListItemIndex],
			open: newValue,
		};
		updatedDetailsListItems[
			updatedDetailsListItemIndex
		] = updatedDetailsListItem;
		updatedDetails.listItems = updatedDetailsListItems;
	}

	return updatedDetails;
};

export const generateMeetPhoneLink = (conferenceData, type) => {
	if (conferenceData && conferenceData.entryPoints) {
		const entryPoint = conferenceData.entryPoints.find(
			point => point.entryPointType === "phone"
		);
		if (entryPoint) {
			if (type === "primary") {
				return entryPoint.uri;
			} else {
				return entryPoint.label + " PIN: " + entryPoint.pin + "#";
			}
		}
	}
	return null;
};

export const generateMeetMorePhoneNumbers = conferenceData => {
	if (conferenceData && conferenceData.entryPoints) {
		const entryPoint = conferenceData.entryPoints.find(
			point => point.entryPointType === "more"
		);
		if (entryPoint) {
			return entryPoint.uri;
		}
	}
	return null;
};

export const generateAttendeesSummary = attendees => {
	let acceptedTally = 0;
	let declinedTally = 0;
	let maybeTally = 0;
	let awaitingTally = 0;

	if (attendees && attendees.length > 0) {
		attendees.forEach(attendee => {
			switch (attendee.responseStatus) {
				case "accepted":
					acceptedTally++;
					break;
				case "declined":
					declinedTally++;
					break;
				case "tentative":
					maybeTally++;
					break;
				case "needsAction":
					awaitingTally++;
					break;
				default:
					break;
			}
		});
	}
	const acceptedText = acceptedTally > 0 ? acceptedTally + " yes" : "";
	const declinedText =
		declinedTally > 0 && acceptedTally > 0
			? ", " + declinedTally + " no"
			: declinedTally > 0
			? declinedTally + " no"
			: "";
	const maybeText =
		maybeTally > 0 && (declinedTally > 0 || acceptedTally > 0)
			? ", " + maybeTally + " maybe"
			: maybeTally > 0
			? maybeTally + " maybe"
			: "";
	const awaitingText =
		awaitingTally > 0 &&
		(maybeTally > 0 || declinedTally > 0 || acceptedTally > 0)
			? ", " + awaitingTally + " awaiting"
			: awaitingTally > 0
			? awaitingTally + " awaiting"
			: "";

	return acceptedText + declinedText + maybeText + awaitingText;
};
