import axiosInstance from '../../assets/helpers/axiosInstance';
import { setSuccessMessage } from "../success/actions";
import { endLoading, startLoading } from "../loading/actions";
import * as loadingTypes from "../loading/types";
import {setError} from "../error/actions";
import * as errorTypes from "../error/types";

export const SET_INVOICES = "invoices/SET_INVOICES";

const setInvoices = invoices => ({
    type: SET_INVOICES,
    invoices,
});

// START: Invoice Requests
//region
export const getInvoices = () => async dispatch => {
    dispatch(startLoading(loadingTypes.invoicesGetInvoices));
    try {
        const res = await axiosInstance.get("/Invoices/getInvoices");
        dispatch(setInvoices(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.invoicesGetInvoices, `${err.message ? err.message + " at " + errorTypes.invoicesGetInvoices : "Something went wrong at " + errorTypes.invoicesGetInvoices }`));
    } finally {
        dispatch(endLoading());
    }
};

export const addInvoiceRequest = (data) => async dispatch => {
    dispatch(startLoading(loadingTypes.invoicesAddInvoiceRequest));
    try {
        await axiosInstance.post("/Invoices/addRequest", data);
        dispatch(setSuccessMessage("Success! Invoice request created."));
    } catch (err) {
        dispatch(setError(errorTypes.invoicesAddInvoiceRequest, `${err.message ? err.message + " at " + errorTypes.invoicesAddInvoiceRequest : "Something went wrong at " + errorTypes.invoicesAddInvoiceRequest }`));
    } finally {
        dispatch(endLoading());
    }
};

export const updateInvoiceRequest = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.invoicesUpdateInvoiceRequest));
    try {
        await axiosInstance.post(`/Invoices/updateRequest/${id}`, data);
        dispatch(setSuccessMessage("Success! Invoice request updated."));
    } catch (err) {
        dispatch(setError(errorTypes.invoicesUpdateInvoiceRequest, `${err.message ? err.message + " at " + errorTypes.invoicesUpdateInvoiceRequest : "Something went wrong at " + errorTypes.invoicesUpdateInvoiceRequest }`));
    } finally {
        dispatch(endLoading());
    }
};

export const updateInvoiceRequestStatus = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.invoicesUpdateInvoiceRequestStatus));
    try {
        await axiosInstance.post(`/Invoices/updateStatus/${id}`, data);
        dispatch(setSuccessMessage("Success! Invoice request status updated."));
    } catch (err) {
        dispatch(setError(errorTypes.invoicesUpdateInvoiceRequestStatus, `${err.message ? err.message + " at " + errorTypes.invoicesUpdateInvoiceRequestStatus : "Something went wrong at " + errorTypes.invoicesUpdateInvoiceRequestStatus }`));
    } finally {
        dispatch(endLoading());
    }
};

export const deleteInvoiceRequest = (id) => async (dispatch, getState) => {
    const removeInvoiceRequest = () => {
        const requests = getState().invoicesReducer.invoices;
        const newInvoiceRequests = requests.filter(
            request => request.uniqueId !== id
        );
        dispatch(setInvoices(newInvoiceRequests));
    };

    dispatch(startLoading(loadingTypes.invoicesDeleteInvoiceRequest));
    try {
        await axiosInstance.delete(`/Invoices/deleteRequest?uniqueId=${id}`,);
        dispatch(setSuccessMessage("Success! Invoice request cancelled."));
        removeInvoiceRequest();
    } catch (err) {
        dispatch(setError(errorTypes.invoicesDeleteInvoiceRequest, `${err.message ? err.message + " at " + errorTypes.invoicesDeleteInvoiceRequest : "Something went wrong at " + errorTypes.invoicesDeleteInvoiceRequest }`));
    } finally {
        dispatch(endLoading());
    }
}
//endregion
// END: Invoice Requests

// START: Invoice Responses
//region
export const addInvoiceResponse = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.invoicesAddInvoiceResponse));
    try {
        await axiosInstance.post(`/Invoices/addResponse?uniqueId=${id}`, data, { headers: { "Content-Type": "multipart/form-data" } })
        dispatch(setSuccessMessage("Success! Invoice response created."));
    } catch (err) {
        dispatch(setError(errorTypes.invoicesAddInvoiceResponse, `${err.message ? err.message + " at " + errorTypes.invoicesAddInvoiceResponse : "Something went wrong at " + errorTypes.invoicesAddInvoiceResponse }`));
    } finally {
        dispatch(endLoading());
    }
};

export const updateInvoiceResponse = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.invoicesUpdateInvoiceResponse));
    try {
        await axiosInstance.post(`/Invoices/updateResponse/${id}`, data, { headers: { "Content-Type": "multipart/form-data" } });
        dispatch(setSuccessMessage("Success! Invoice response updated."));
    } catch (err) {
        dispatch(setError(errorTypes.invoicesUpdateInvoiceResponse, `${err.message ? err.message + " at " + errorTypes.invoicesUpdateInvoiceResponse : "Something went wrong at " + errorTypes.invoicesUpdateInvoiceResponse }`));
    } finally {
        dispatch(endLoading());
    }
};

export const updateInvoiceResponseStatus = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.invoicesUpdateInvoiceResponseStatus));
    try {
        await axiosInstance.post(`/Invoices/updateStatus/${id}`, data);
        dispatch(setSuccessMessage("Success! Invoice response status updated."));
    } catch (err) {
        dispatch(setError(errorTypes.invoicesUpdateInvoiceResponseStatus, `${err.message ? err.message + " at " + errorTypes.invoicesUpdateInvoiceResponseStatus : "Something went wrong at " + errorTypes.invoicesUpdateInvoiceResponseStatus }`));
    } finally {
        dispatch(endLoading());
    }
};
//endregion
// END: Invoice Responses