import React from 'react';
import Popover from '@material-ui/core/Popover';
import NotificationList from '../../Lists/NotificationList/NotificationList';

const NotificationsDialog = (props) => {

    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <NotificationList data={props.data} />
        </Popover>

    );
}

export default NotificationsDialog;