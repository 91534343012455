import React from "react";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import SvgIcon from "@material-ui/core/SvgIcon";
import styles from "./InputForm.module.css";
import Input from "../../Inputs/Input/Input";

const InputForm = props => {
	const { formFields } = props;

	const getIconGrid = formElement => {
		return formElement.icon ? (
			<Grid item xs={2} className={styles.IconGrid}>
				{formElement.iconType && formElement.iconType === "custom" ? (
					<SvgIcon
						className={styles.Icon}
						component={formElement.icon}
						viewBox='0 0 48 48'
					/>
				) : typeof formElement.icon === "string" ? (
					<Icon className={styles.Icon}>{formElement.icon}</Icon>
				) : (
					<formElement.icon className={styles.Icon} />
				)}
			</Grid>
		) : null;
	};

	return formFields.map(formElement => {
		let otherMultiField = null;
		let otherMultiFieldIndex = -1;

		if (formElement.multiField && formElement.multiFieldReference) {
			otherMultiFieldIndex = formFields.findIndex(
				f => f.id === formElement.multiFieldReference
			);
			otherMultiField =
				otherMultiFieldIndex !== -1 ? formFields[otherMultiFieldIndex] : null;
		}

		return !formElement.multiField ||
			(formElement.multiField && otherMultiField) ? (
			<Grid
				key={formElement.id}
				container
				spacing={1}
				alignItems="center"
				className={styles.InputWrapper}
			>
				{getIconGrid(formElement)}
				{formElement.multiField ? (
					otherMultiField ? (
						<Grid item xs={formElement.icon ? 10 : 12}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={formElement.gridColumnSize}>
									<Input elementData={formElement} />
								</Grid>
								<Grid item xs={12} sm={formElement.gridColumnSize}>
									<Input elementData={otherMultiField} />
								</Grid>
							</Grid>
						</Grid>
					) : null
				) : (
					<Grid item xs={formElement.icon ? 10 : 12}>
						<Input elementData={formElement} />
					</Grid>
				)}
			</Grid>
		) : null;
	});
};

export default React.memo(InputForm);
