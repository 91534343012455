import React from "react";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {formatShortDate} from "../../../../assets/helpers/globals";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Loader from "../../../../components/Loader/Loader";
import * as loadingTypes from "../../../../store/loading/types";
import styled, {css} from "styled-components";
import Grid from "@material-ui/core/Grid";
import {Field, Form} from "react-final-form";
import {focusOnError} from "../../../../assets/helpers/finalFormHelpers";
import arrayMutators from 'final-form-arrays'
import MultipleProjectResponseFieldAdapter from "../../../../components/Inputs/MultipleProjectField/MultipleProjectResponseField";
import {Radios, TextField} from "mui-rff";

const LoadingDiv = styled.div`
  position: absolute;
  bottom: 11px;
  right: 36px;
`;

const H3 = styled.h3`
  margin: 0;
`;

const ActionButton = styled(Button)`
  margin: 0 5px;
`;

const FormInput = styled.form`
  width: 100%;
`;

const FormDialogContent = styled(DialogContent)`
  padding: 10px 10px;
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const StatusRadios = styled(Radios)`
  ${props => props.data[0]?.label === "Approved" && css`&.Mui-checked { color: Green }`}
  ${props => props.data[0]?.label === "Rejected" && css`&.Mui-checked { color: Red }`}
`;

// const P = styled.p`
//   margin: 0;
//   ${props => props.blue && css`color: RoyalBlue;`}
//   ${props => props.small && css`font-size: small;`}
// `;
//
// const Div = styled.div`
//   padding: 16px;
// `;
//
// const Span = styled.span`
//   color: #f57c00;
//
//   ${props => props.status === "Approved" && css`
//     color: #388e3c;
//   `}
//   ${props => props.status === "Rejected" && css`
//     color: #d32f2f;
//   `}
// `;

const ResponseDialog = ({
                        details,
                        onClose,
                        open,
                        onSubmit,
                        loading,
                        isProjectAdmin,
                        isWorkshop,
                        userEmail
                   }) => {
    const formFields = (errors, touched, values) => [
        (isProjectAdmin || isWorkshop) && details.workshopApprovalStatus !== null && details.purchase?.purchaseLevel === 1 ?
            {
                externalLabel: "Workshop Status",
                size: 12,
                field: (
                    <>
                        <StatusRadios
                            name="workshopApprovalStatus"
                            formControlProps={{margin: 'none'}}
                            radioGroupProps={{row: true}}
                            data={[
                                {label: "Approved", value: "Approved"},
                            ]}
                        />
                        <StatusRadios
                            name="workshopApprovalStatus"
                            formControlProps={{margin: 'none'}}
                            radioGroupProps={{row: true}}
                            data={[
                                {label: "Rejected", value: "Rejected"},
                            ]}
                            showError={() => false}
                        />
                    </>
                ),
            } : {},
        (isProjectAdmin || isWorkshop) && details.workshopApprovalStatus !== null && details.purchase?.purchaseLevel === 1 ?
            {
                externalLabel: "Workshop Comment",
                size: 12,
                field: (
                    <TextField
                        name="workshopRejectReason"
                        margin="none"
                        required
                        placeholder={"Workshop Comment"}
                        multiline
                    />
                ),
            } : {},
        details.purchase?.purchaseLevel === 1 ? {
            externalLabel: "Project List",
            size: 12,
            field: (
                <Field
                    component={MultipleProjectResponseFieldAdapter}
                    name="projectsDetails"
                    label="Project List"
                    errors={errors}
                    itemOneName={"status"}
                    itemTwoName={"rejectReason"}
                    touched={touched}
                    isProjectAdmin={isProjectAdmin}
                    userEmail={userEmail}
                    values={values}
                />
            )
        } :
        {
            externalLabel: "Status",
            size: 12,
            field: (
                <>
                    <StatusRadios
                        name="status"
                        formControlProps={{margin: 'none'}}
                        radioGroupProps={{row: true}}
                        data={[
                            {label: "Approved", value: "Approved"},
                        ]}
                    />
                    <StatusRadios
                        name="status"
                        formControlProps={{margin: 'none'}}
                        radioGroupProps={{row: true}}
                        data={[
                            {label: "Rejected", value: "Rejected"},
                        ]}
                        showError={() => false}
                    />
                </>
            )
        },
        {
            externalLabel: "Purchase Comment",
            size: 12,
            field: (
                <TextField
                    name="rejectReason"
                    margin="none"
                    required
                    placeholder={"Purchase Comment"}
                    multiline
                />
            )
        },
    ]

    const validate = values => {
        const errors = {}

        if (values.purchaseLevel === 1) {
            if (!values.projectsDetails ||
                !values.projectsDetails.length ||
                values.projectsDetails.indexOf(null) !== -1)
                errors.projectsDetails = "Required"
            else {
                values.projectsDetails.forEach((projectsDetailsItem, projectsDetailsItemIdx) => {
                    Object.entries(projectsDetailsItem).forEach(([key, value]) => {
                        // if (key === "status" && (isProjectAdmin || userEmail === projectsDetailsItem.project.projectManager.userEmail) && (!value || value === "Awaiting Approval" || value === "Awaiting 2nd Approval"))
                        //     errors["projectsDetails[" + projectsDetailsItemIdx + "].status"] = "Required"
                        if (key === "rejectReason" && (isProjectAdmin || userEmail === projectsDetailsItem.project.projectManager.userEmail) && !value && values.projectsDetails[projectsDetailsItemIdx].status === "Rejected")
                            errors["projectsDetails[" + projectsDetailsItemIdx + "].rejectReason"] = "Required"
                    })
                })
            }
        //
            if (details.workshop && (isProjectAdmin || isWorkshop)) {
        //         if (values.workshopApprovalStatus === "Awaiting Approval")
        //             errors.workshopApprovalStatus = "Required";
                if (values.workshopApprovalStatus === "Rejected")
                    if (!values.workshopRejectReason)
                        errors.workshopRejectReason = "Required"
            }
        } else {
            if (!values.status)
                errors.status = "Required"

            if (values.status === "Rejected")
                if (!values.rejectReason)
                    errors.rejectReason = "Required"
        }

        return errors
    }

    const onSubmitForm = async values => {
        let modifiedPayload;
        let modifiedProjectsDetailsPayload = [];

        if (values.purchaseLevel === 1) {
            let purchaseStatus;
            let purchaseComment = values.rejectReason;
            let purchaseOrderStatus = null;
            let purchaseLevel = values.purchaseLevel;

            if (values.projectsDetails.some((projectsDetailsItem) => projectsDetailsItem.status === "Rejected") || values.workshopApprovalStatus === "Rejected") {
                purchaseStatus = "Rejected";
                modifiedProjectsDetailsPayload = values.projectsDetails.slice();
            // } else if (values.projectsDetails.some((projectsDetailsItem) => projectsDetailsItem.status === "Awaiting Approval")) {
            //     purchaseStatus = "Awaiting Approval";
            //     modifiedProjectsDetailsPayload = values.projectsDetails.slice();
            } else if ((values.projectsDetails.some((projectsDetailsItem) => projectsDetailsItem.status === "Awaiting Approval")) || (details.workshop && (values.workshopApprovalStatus === "Awaiting Approval" || !values.workshopApprovalStatus))) {
                purchaseStatus = "Awaiting Approval";
                modifiedProjectsDetailsPayload = values.projectsDetails.slice();
            } else if ((details.purchase?.status === "Awaiting Approval" || details.purchase?.status === "Rejected") && purchaseLevel === 1 &&
                details.purchase?.totalCostExchange > 50000) {
                purchaseStatus = "Awaiting 2nd Approval";
                purchaseComment = "";
                purchaseLevel = 2;
                values.projectsDetails.forEach((projectsDetailsItem) => {
                    modifiedProjectsDetailsPayload.push({
                        ...projectsDetailsItem,
                        status: "Awaiting 2nd Approval",
                        rejectReason: ""
                    })
                })
            } else {
                purchaseStatus = "Approved";
                purchaseComment = "";
                purchaseOrderStatus = "PO Pending";
                values.projectsDetails.forEach((projectsDetailsItem) => {
                    modifiedProjectsDetailsPayload.push({
                        ...projectsDetailsItem,
                        status: "Approved",
                        rejectReason: ""
                    })
                })
            }

            modifiedPayload = {
                ...details,
                projectsDetails: modifiedProjectsDetailsPayload,
                purchase: {
                    ...details.purchase,
                    rejectReason: purchaseComment,
                    status: purchaseStatus,
                    purchaseLevel: purchaseLevel,
                    purchaseOrderStatus: purchaseOrderStatus,
                    lastResponseDate: formatShortDate(new Date())
                },
                workshopApprovalStatus: values.workshopApprovalStatus,
                workshopRejectReason: values.workshopRejectReason
            }
        } else {
            modifiedPayload = {
                ...details,
                purchase: {
                    ...details.purchase,
                    rejectReason: values.rejectReason,
                    status: values.status,
                    purchaseOrderStatus: values.status === "Approved" ? "PO Pending" : null,
                    lastResponseDate: formatShortDate(new Date())
                }
            }
        }

        await onSubmit(modifiedPayload);
    }

    let submit;

    return <Form
        onSubmit={onSubmitForm}
        validate={validate}
        decorators={[focusOnError]}
        mutators={{...arrayMutators}}
        initialValues={{
            projectsDetails: details.projectsDetails,
            rejectReason: details.purchase?.rejectReason,
            purchaseLevel: details.purchase?.purchaseLevel,
            workshopApprovalStatus: details.workshopApprovalStatus,
            workshopRejectReason: details.workshopRejectReason,
            status: details.purchase?.status
        }}
        render={({handleSubmit, submitting, pristine, errors, touched, values}) => {
            submit = handleSubmit
            return (
                <Dialog
                    aria-labelledby="project-purchase-request-details-dialog-title"
                    onClose={onClose}
                    fullWidth
                    maxWidth={"sm"}
                    open={open}
                >
                    <CustomDialogTitle
                        id="project-purchase-request-details-dialog-title"
                        onClose={onClose}
                        showTextContent
                    >
                        Project Purchase Response
                    </CustomDialogTitle>
                    <FormDialogContent dividers>
                        <ListItem>
                            <FormInput noValidate>
                                <Grid container alignItems="flex-start" spacing={1}>
                                    {formFields(errors, touched, values).map((item, idx) => (
                                        item.field ? <Grid item xs={item.size} key={idx}>
                                            <ListItemText>
                                                <H3>{item.externalLabel}</H3>
                                                {item.field}
                                            </ListItemText>
                                        </Grid> :
                                        <React.Fragment key={idx}/>
                                    ))}
                                </Grid>
                            </FormInput>
                        </ListItem>
                    </FormDialogContent>

                    <DialogActions>
                        <ActionButton
                            onClick={onClose}
                            type="button"
                            disabled={submitting}
                            color="primary"
                        >
                            Cancel
                        </ActionButton>
                        <ActionButton
                            onClick={event => submit(event)}
                            type="submit"
                            disabled={submitting || pristine}
                            color="default"
                        >
                            Submit
                        </ActionButton>
                        <LoadingDiv>
                            <Loader loading={loading.type === loadingTypes.purchasesUpdatePurchaseProjectRequestStatus} size="small"/>
                        </LoadingDiv>
                    </DialogActions>
                </Dialog>
            )
        }}
    />
}

export default ResponseDialog;