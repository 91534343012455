import axiosInstance from '../../assets/helpers/axiosInstance';
import { endLoading, startLoading } from "../loading/actions";
import * as loadingTypes from "../loading/types";
import {setError} from "../error/actions";
import * as errorTypes from "../error/types";

export const SET_VENDORS_BY_SUPPLIER = "vendors/SET_VENDORS_BY_SUPPLIER";
export const SET_VENDORS_BY_CUSTOMER = "vendors/SET_VENDORS_BY_CUSTOMER";

const setVendorsBySupplier = vendorsBySupplier => ({
    type: SET_VENDORS_BY_SUPPLIER,
    vendorsBySupplier,
});

const setVendorsByCustomer = vendorsByCustomer => ({
    type: SET_VENDORS_BY_CUSTOMER,
    vendorsByCustomer,
});

export const getVendorsBySupplier = (id) => async dispatch => {
    dispatch(startLoading(loadingTypes.vendorsGetVendorsBySupplier));
    try {
        const res = await axiosInstance.get(`/vendors/getVendorsBySupplier?uniqueId=${id}`);
        dispatch(setVendorsBySupplier(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.vendorsGetVendorsBySupplier, `${err.message ? err.message + " at " + errorTypes.vendorsGetVendorsBySupplier : "Something went wrong at " + errorTypes.vendorsGetVendorsBySupplier }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getVendorsByCustomer = (id) => async dispatch => {
    dispatch(startLoading(loadingTypes.vendorsGetVendorsByCustomer));
    try {
        const res = await axiosInstance.get(`/vendors/getVendorsByCustomer?uniqueId=${id}`);
        dispatch(setVendorsByCustomer(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.vendorsGetVendorsByCustomer, `${err.message ? err.message + " at " + errorTypes.vendorsGetVendorsByCustomer : "Something went wrong at " + errorTypes.vendorsGetVendorsByCustomer }`));
    } finally {
        dispatch(endLoading());
    }
};


