import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { size, toArray } from 'lodash';

import ProgressBar from '@ramonak/react-progress-bar';
import { uploadFile } from "../../store/file/actions";
import styles from './UploadProgress.module.css';

const UploadProgress = props => {
  const { fileProgress, uploadFile } = props;
  const uploadedFileAmount = size(fileProgress);

  useEffect(() => {
    const fileToUpload = toArray(fileProgress).filter(file => file.progress === 0);
    uploadFile(fileToUpload);
    // eslint-disable-next-line
  }, [uploadedFileAmount])

  return uploadedFileAmount > 0 ? (
      size(fileProgress) ? toArray(fileProgress).map((file, index) => <div key={index} className={styles.ProgressWrapper}>
            <ProgressBar bgcolor='#3c4858' completed={file.progress} />
            <label>{file.file.name}</label>
        </div>)
        : null
  ) : null
}

const mapStateToProps = state => ({
  fileProgress: state.uploadFileReducer.fileProgress,
});

const mapDispatchToProps = dispatch => ({
  uploadFile : files => dispatch(uploadFile(files)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadProgress);
